import { Component, OnInit, Inject } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AccountInfo,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InfoService } from '../../service/info.service';

@Component({
  selector: 'app-dl-header',
  templateUrl: './dl-header.component.html',
  styleUrls: ['./dl-header.component.css'],
})
export class DlHeaderComponent implements OnInit {
  dataSource: Claim[] = [];
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  departmentId: BehaviorSubject<string>;
  username: BehaviorSubject<string>;
  showMentor = false;
  showInternshipperiods = true;
  showSubjectareas = true;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private infoService: InfoService
  ) {}

  ngOnInit() {
    this.username = this.infoService.getUsername();
    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      console.log(data);
      //  if department is Soziale Arbeit or IPH
      if (
        data === '689119E5-AD45-4E84-9AB3-43C792725A35' ||
        data === 'A6AFD31C-DECE-4AAF-A4B0-0D409DA716AE'
      ) {
        this.showMentor = true;
        this.showInternshipperiods = false;
        this.showSubjectareas = false;
      }
    });

    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        this.doAccountHandling();
        console.log(result);
      });

    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.doAccountHandling();
      });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  }

  doAccountHandling() {
    let accounts = this.authService.instance.getAllAccounts();
    this.setLoginDisplay(accounts);
    this.checkAndSetActiveAccount(accounts);
    this.getClaims();
  }

  setLoginDisplay(accounts: AccountInfo[]) {
    this.loginDisplay = accounts.length > 0;
    this.infoService.refreshData();
  }

  checkAndSetActiveAccount(accounts: AccountInfo[]) {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && accounts.length > 0) {
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    console.log(
      'get active account',
      this.authService.instance.getActiveAccount()
    );
  }

  getClaims() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount?.idTokenClaims) return;
    let claims: any = activeAccount?.idTokenClaims;
    let list: Claim[] = new Array<Claim>();
    Object.keys(claims).forEach(function (k, v) {
      let c = new Claim();
      c.id = v;
      c.claim = k;
      c.value = claims ? claims[k] : null;
      list.push(c);
      if (!environment.production)
        console.log(`ID: ${c.id}; Claim  ${c.claim}; Value ${c.value}`);
    });
    this.dataSource = list;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

export class Claim {
  id: number;
  claim: string;
  value: string;
}
