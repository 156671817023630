import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subjectarea } from '../../../service/model/subjectarea';
import { SubjectareaService } from '../../../service/subjectarea.service';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { InfoService } from '../../../service/info.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-subjectarea-detail',
  templateUrl: './subjectarea-detail.component.html',
  styleUrls: ['./subjectarea-detail.component.css'],
})
export class SubjectareaDetailComponent implements OnInit {
  subjectarea = new Subjectarea();
  loading: boolean;
  isUpdate = false;
  submitted = false;
  isAdminBehaviorSubject: BehaviorSubject<boolean>;

  constructor(
    private route: ActivatedRoute,
    private subjectareaService: SubjectareaService,
    private infoService: InfoService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.isAdminBehaviorSubject = this.infoService.getIsAdmin();
    this.route.params.subscribe((params) => {
      if (params['id'] != 0 && params['id'] != null) {
        this.subjectareaService
          .getSingle(params['id'])
          .then((data) => {
            //console.log('load');
            this.subjectarea = data;
            //console.log(this.subjectarea);
            this.isUpdate = true;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Fachbereich konnte nicht geladen werden.',
              },
            });
          });
      } else {
        this.subjectarea.active = true;
      }
      this.loading = false;
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.formInvalid()) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Es gibt noch leere Pflichtfelder.',
        },
      });
      return;
    }

    this.loading = true;

    this.subjectareaService
      .saveSubjectarea(this.subjectarea)
      .then((data) => {
        this.loading = false;
        this.router.navigate(['subjectareas']);
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Der Fachbereich konnte nicht gespeichert werden.',
          },
        });
      });
  }

  formInvalid(): boolean {
    return this.subjectarea.name === undefined || this.subjectarea.name === '';
  }
}
