import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InternshiptypesService } from '../../../service/internshiptypes.service';
import { Internshiptype } from '../../../service/model/internshiptype';
import { Gradingscaleservice } from '../../../service/gradingscale.service';
import { Gradingscale } from '../../../service/model/gradingscale';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { RadioGroupEntry } from '../../../service/model/radiogroupentry';
import { InfoService } from '../../../service/info.service';
import { BehaviorSubject } from 'rxjs';
import { DeptFieldsInternshiptypeDetail } from '../internshiptype-fields';
import { UtilsService } from '../../../service/utils.service';

@Component({
  selector: 'app-internshiptype-detail',
  templateUrl: './internshiptype-detail.component.html',
  styleUrls: ['./internshiptype-detail.component.css'],
  providers: [InternshiptypesService, Gradingscaleservice],
})
export class InternshiptypeDetailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private internshiptypesService: InternshiptypesService,
    private gradingscaleservice: Gradingscaleservice,
    private dialog: MatDialog,
    private router: Router,
    private infoService: InfoService,
    private utilsService: UtilsService
  ) {}
  internshipType: Internshiptype;
  isUpdate: boolean;
  isAdminBehaviorSubject: BehaviorSubject<boolean>;

  loading: boolean;
  submitted = false;

  gradingscales: Gradingscale[];
  selectedgradingscale: Gradingscale;
  internshipTypeIdsLookup: RadioGroupEntry[];
  selectedInternshipTypeIdLookup: RadioGroupEntry;

  deptFields: DeptFieldsInternshiptypeDetail;
  departmentId: BehaviorSubject<string>;
  makeFieldsRequired = true;

  ngOnInit(): void {
    this.isAdminBehaviorSubject = this.infoService.getIsAdmin();

    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(
        data,
        'internshiptypeDetail'
      );
      // Nur für Testing alle Felder anzeigen
      // this.deptFields = deptFieldsInternshiptypeShowAll;

      // Nur S hat feedback gegeben dass keine Pflichtfelder
      /*if (data === '689119E5-AD45-4E84-9AB3-43C792725A35') {
        this.makeFieldsRequired = false;
      }*/
    });

    this.route.params.subscribe((params) => {
      this.loading = true;
      if (params['id'] != 0 && params['id'] != null) {
        this.internshiptypesService
          .getSingle(params['id'])
          .then((data) => {
            this.isUpdate = true;
            this.internshipType = data;
            Promise.all([
              this.gradingscaleservice
                .getList()
                .then((data) => {
                  this.gradingscales = data['records'];
                  this.selectedgradingscale = this.gradingscales.find(
                    (o) => o.id == this.internshipType.gradingScale.id
                  );
                })
                .catch((e) => {
                  this.loading = false;
                  console.error(e);
                  this.dialog.open(ErrorDialogComponent, {
                    data: {
                      message: 'Die Notenskalen konnten nicht geladen werden.',
                    },
                  });
                }),
              this.internshiptypesService
                .getInternshipTypesLookup()
                .then((data) => {
                  //console.log(data);
                  this.internshipTypeIdsLookup = data;
                  this.selectedInternshipTypeIdLookup =
                    this.internshipTypeIdsLookup.find(
                      (o) => o.id == this.internshipType.internshipTypeIdLookup
                    );
                  //console.log(this.selectedInternshipTypeIdLookup);
                })
                .catch((e) => {
                  this.loading = false;
                  console.error(e);
                  this.dialog.open(ErrorDialogComponent, {
                    data: {
                      message:
                        'Praktikumstypen Look-up Ids konnten nicht gelesen werden.',
                    },
                  });
                }),
            ]).then(() => (this.loading = false));
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Der Praktikumstyp konnte nicht geladen werden.',
              },
            });
          });
      } else {
        this.isUpdate = false;
        this.internshipType = new Internshiptype();
        Promise.all([
          this.gradingscaleservice
            .getList()
            .then((data) => {
              this.gradingscales = data['records'];
            })
            .catch((e) => {
              this.loading = false;
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message: 'Die Notenskalen konnten nicht geladen werden.',
                },
              });
            }),
          this.internshiptypesService
            .getInternshipTypesLookup()
            .then((data) => {
              this.internshipTypeIdsLookup = data;
            })
            .catch((e) => {
              this.loading = false;
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message:
                    'Praktikumstypen Look-up Ids konnten nicht gelesen werden.',
                },
              });
            }),
        ]).then(() => (this.loading = false));
      }

      this.loading = false;
    });
  }

  onSubmit(): void {
    this.submitted = true;

    this.internshipType.gradingScale = this.selectedgradingscale;
    this.internshipType.internshipTypeIdLookup =
      this.selectedInternshipTypeIdLookup?.id;

    if (this.formInvalid()) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Es gibt noch leere Pflichtfelder.',
        },
      });
      return;
    }

    this.loading = true;

    this.internshiptypesService
      .saveInternshiptype(this.internshipType)
      .then((data) => {
        this.loading = false;

        this.router.navigate(['internshiptypes']);
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Der Praktikumstyp konnte nicht gespeichert werden.',
          },
        });
      });
  }

  formInvalid(): boolean {
    return (
      this.internshipType.name === undefined ||
      this.internshipType.name === '' ||
      this.internshipType.gradingScale == undefined
    );
  }
}
