import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InfoService } from '../../service/info.service';

@Component({
  selector: 'app-dl-footer',
  templateUrl: './dl-footer.component.html',
  styleUrls: ['./dl-footer.component.css'],
})
export class DlFooterComponent implements OnInit {
  constructor(private infoService: InfoService) {}

  year: number;
  version: string;
  webApiVersion: BehaviorSubject<string>;
  displayname: BehaviorSubject<string>;
  lastImport: BehaviorSubject<string>;

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.version = '1.0.3';
    this.webApiVersion = this.infoService.getWebApiVersion();
    this.displayname = this.infoService.getDisplayname();
    this.lastImport = this.infoService.getLastImport();
  }
}
