import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { InternshipperiodService } from '../../../service/internshipperiod.service';
import { Internshipperiod } from '../../../service/model/internshipperiod';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { InfoService } from '../../../service/info.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-internshipperiod-detail',
  templateUrl: './internshipperiod-detail.component.html',
  styleUrls: ['./internshipperiod-detail.component.css'],
})
export class InternshipperiodDetailComponent implements OnInit {
  internshipperiod = new Internshipperiod();
  loading: boolean;
  isUpdate = false;
  submitted = false;
  isAdminBehaviorSubject: BehaviorSubject<boolean>;

  constructor(
    private route: ActivatedRoute,
    private internshipperiodService: InternshipperiodService,
    private infoService: InfoService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.isAdminBehaviorSubject = this.infoService.getIsAdmin();

    this.route.params.subscribe((params) => {
      if (params['id'] != 0 && params['id'] != null) {
        this.internshipperiodService
          .getSingle(params['id'])
          .then((data) => {
            //console.log('load');
            this.internshipperiod = data;
            //console.log(this.internshipperiod);
            this.isUpdate = true;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Die Praktikumsperiode konnte nicht geladen werden.',
              },
            });
          });
      }
      this.loading = false;
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.formInvalid()) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Es gibt noch leere Pflichtfelder.',
        },
      });
      return;
    }

    this.loading = true;

    this.internshipperiodService
      .saveInternshipperiod(this.internshipperiod)
      .then((data) => {
        this.loading = false;
        if (this.internshipperiod.id) {
          window.location.reload();
        } else {
          if (data) {
            this.router.navigate(['internshipperiod-detail', data.id]);
          }
        }
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Die Praktikumsperiode konnte nicht gespeichert werden.',
          },
        });
      });
  }

  formInvalid(): boolean {
    return (
      this.internshipperiod.identifier === undefined ||
      this.internshipperiod.identifier === '' ||
      this.internshipperiod.startCalendarWeek === undefined ||
      this.internshipperiod.durationInWeeks === undefined
    );
  }
}
