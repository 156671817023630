import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Observable } from 'rxjs';
import { Subjectarea } from '../../../service/model/subjectarea';
import { SubjectareaService } from '../../../service/subjectarea.service';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { setSubjectareasRows } from '../../../state/rows-selection.actions';

@Component({
  selector: 'app-subjectareas',
  templateUrl: './subjectareas.component.html',
  styleUrls: ['./subjectareas.component.css'],
})
export class SubjectareasComponent {
  subjectareas: Subjectarea[];
  totalRecords: number;
  loading: boolean;
  isForSelection = false;
  rowsSelectionSubjectareas$: Observable<number>;
  @ViewChild('table') table: Table;
  filterNotSet = true;

  constructor(
    private subjectareaservice: SubjectareaService,
    private store: Store<{ rowsSelectionSubjectareas: number }>,
    private dialog: MatDialog
  ) {
    this.rowsSelectionSubjectareas$ = store.select('rowsSelectionSubjectareas');
    //console.log(this.rowsSelectionsubjectareas$);
  }

  loadSubjectareas(event: TableLazyLoadEvent) {
    //console.log(event);
    this.loading = true;

    this.store.dispatch(setSubjectareasRows({ rows: event.rows }));
    this.rowsSelectionSubjectareas$ = this.store.select(
      'rowsSelectionSubjectareas'
    );

    setTimeout(() => {
      this.subjectareaservice
        .getList(event)
        .then((data) => {
          if (this.filterNotSet) {
            this.filterNotSet = false;
            this.setActiveFilter();
          } else {
            this.subjectareas = data['records'];
            this.totalRecords = data['count'];

            this.loading = false;
          }
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message: 'Fachbereiche konnten nicht geladen werden.',
            },
          });
        });
    }, 1000);
  }

  selectSubjectarea(subjectarea: Subjectarea) {}

  setActiveFilter() {
    this.table.filters['active'] = { value: true, matchMode: 'is' };
    this.loadSubjectareas(this.table.createLazyLoadMetadata());
  }
}
