<div class="row">
  <div class="col">
    <div>
      <h2 class="mb-3">
        <a
          routerLink="/subjectareas"
          routerLinkActive="active"
          ngbTooltip="zur Übersicht"
          ><i class="pi pi-angle-left bluecolor"></i></a
        >&nbsp;Fachbereich<span *ngIf="!isUpdate"> erfassen</span>
      </h2>

      <form *ngIf="subjectarea">
        <div class="form-group">
          <div class="row">
            <div class="col-sm">
              <label for="name">Name:</label>
              <input
                class="form-control"
                id="name"
                name="name"
                [(ngModel)]="subjectarea.name"
                [readonly]="false"
                [disabled]="false"
                size="40"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    (subjectarea.name === undefined || subjectarea.name === '')
                }"
              />
            </div>
            <div class="col-sm">
              <div>
                <mat-checkbox
                  id="aktiv"
                  name="aktiv"
                  [(ngModel)]="subjectarea.active"
                  [disabled]="false"
                  >Aktiv
                </mat-checkbox>
              </div>
            </div>
          </div>

          <button
            *ngIf="isAdminBehaviorSubject | async"
            class="btn btn-primary float-right"
            (click)="onSubmit()"
          >
            Speichern
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
