<div class="row">
  <div *ngIf="visibleForRefresh" class="col">
    <h2 class="mb-3">Praxisausbildende</h2>

    <div class="row">
      <div class="col">
        <label for="showInactive">inklusiv Inaktive anzeigen &nbsp;</label>
        <mat-checkbox
          id="showInactive"
          name="showInactive"
          [(ngModel)]="this.showInactive"
          (change)="checkShowInactive()"
        ></mat-checkbox>
      </div>
    </div>

    <div *ngIf="!isForSelection" class="row" style="padding-bottom: 10px">
      <div class="col">
        <button
          type="button"
          (click)="downloadExport()"
          ngbTooltip="Datensätze können für den Export ausgewählt werden."
          pButton
          pRipple
          icon="pi pi-download"
          class="btn btn-primary float-right"
          tooltipPosition="bottom"
        >
          &nbsp;&nbsp;Exportieren&nbsp;
        </button>

        <button
          *ngIf="deptFields.sozialeArbeit"
          type="button"
          ngbTooltip="Berichte und Funktionen"
          pButton
          pRipple
          icon="pi pi-ellipsis-v"
          class="btn btn-primary float-right"
          tooltipPosition="bottom"
          [matMenuTriggerFor]="menu"
        ></button>

        <mat-menu #menu="matMenu">
          <button (click)="getBriefAnerkennung()" mat-menu-item>
            <span>Brief Anerkennung</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <p-table
      #dt
      [columns]="cols"
      styleClass="p-datatable-gridlines"
      [lazy]="true"
      (onLazyLoad)="loadTrainers($event)"
      [totalRecords]="totalRecords"
      [value]="trainers"
      sortMode="single"
      sortField="name"
      [sortOrder]="1"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [paginator]="true"
      [rows]="rowsSelectionTrainers$ | async"
      [rowsPerPageOptions]="[10, 50, 100, 200, 500, 1000]"
      [scrollable]="true"
      selectionMode="multiple"
      [(selection)]="selectedTrainers"
      [selectionPageOnly]="true"
      (onFilter)="filter($event)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 30px; padding-left: 8px">
            <mat-checkbox
              *ngIf="!isForSelection"
              name="selectAll"
              id="selectAll"
              [(ngModel)]="this.selectAllChecked"
              (change)="selectAll()"
              ngbTooltip="alles markieren"
            ></mat-checkbox>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="name">
              Name
              <p-sortIcon field="name"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="name"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="organization">
              Organisation
              <p-sortIcon field="organization"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="organization"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="addressText">
              Adresse
              <p-sortIcon field="addressText"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="addressText"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="cityText">
              Ort
              <p-sortIcon field="cityText"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="cityText"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="emailText">
              Email
              <p-sortIcon field="emailText"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="emailText"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="telefon">
              Telefon
              <p-sortIcon field="telefon"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="telefon"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 60px" alignFrozen="right" pFrozenColumn>
            &nbsp;
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-trainer let-rowIndex="rowIndex">
        <tr [pSelectableRow]="trainer" [pSelectableRowIndex]="rowIndex">
          <td style="min-width: 30px">
            <p-tableCheckbox
              [value]="trainer"
              #checkboxTableP
              [index]="rowIndex"
              (click)="checkboxTable(checkboxTableP, $event)"
            ></p-tableCheckbox>
          </td>
          <td style="min-width: 220px">{{ trainer.name }}</td>
          <td style="min-width: 220px">{{ trainer.organization }}</td>
          <td style="min-width: 220px">{{ trainer.addressText }}</td>
          <td style="min-width: 220px">{{ trainer.cityText }}</td>
          <td style="min-width: 220px">{{ trainer.emailText }}</td>
          <td style="min-width: 220px">{{ trainer.telefon }}</td>
          <td
            class="functionicons"
            style="min-width: 60px"
            alignFrozen="right"
            pFrozenColumn
          >
            <div *ngIf="isForSelection">
              <button
                class="btn"
                (click)="selectTrainer(trainer)"
                ngbTooltip="Auswählen"
              >
                <i class="fas fa-solid fa-plus bluecolor"></i>
              </button>
            </div>
            <div *ngIf="!isForSelection">
              <a
                [routerLink]="['/trainer-detail', trainer.id]"
                ngbTooltip="Bearbeiten"
                ><i class="fas fa-edit bluecolor"></i
              ></a>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
