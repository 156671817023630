import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';

// ------------------------------
// if an error is thrown (mostly when thrown by the web API), use this method to extract the message.
// ------------------------------
export const getErrorMessage = (val: any, fallback?: string): string => {
  let message: string;

  if (val == null || val == undefined) {
    message = fallback;
  } else if (val instanceof HttpErrorResponse) {
    let blobError = val.headers.get('BlobErrorMessage');
    if (blobError) {
      message = decodeURIComponent(blobError);
    } else {
      message = getErrorMessage(val.error, fallback);
    }
  } else if (val.modelState != undefined) {
    // Object.values is unsupported in IE11 - https://stackoverflow.com/questions/43254982/object-doesnt-support-property-or-method-values/43255030
    var values = Object.keys(val.modelState).map(function (i) {
      return val.modelState[i];
    });
    message = values.join('\n');
  } else if (val.error_description != undefined) {
    message = val.error_description;
  } else if (val.message != undefined) {
    message = val.message;
  } else if (val instanceof String || typeof val === 'string') {
    message = val.toString();
  }

  if (message == undefined) {
    message = fallback || 'Unknown error format.';
  }

  // logging of errors / exceptions
  logDebug(val);

  return message;
};

// ------------------------------
// simple pendant of the String.Format() .NET function
// ------------------------------
export const stringFormat = (input: string, ...params: any[]): string => {
  let result = input;

  for (let i = 0; i < params.length; i++) {
    let re = new RegExp(`\\{${i}\\}`, 'g');
    result = result.replace(re, params[i]);
  }

  return result;
};

// source: https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api
export const loadFile = (
  obs: Promise<Blob>,
  mimeType: string,
  filename: string,
  errorCallback: (e: any) => void
): void => {
  obs
    .then((x) => {
      if (!x) {
        logDebug('Received null.');
        return;
      }

      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([x], { type: mimeType });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = filename;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

// ------------------------------
// static CH date formatting function
// ------------------------------
export const dateFormat = (input: Date): string => {
  var inputDate = new Date(input);
  var day = inputDate.getDate().toString().padStart(2, '0');
  var month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  var year = inputDate.getFullYear().toString().padStart(4, '0');
  var result = `${day}.${month}.${year}`;
  return result;
};

// ------------------------------
// static CH date formatting function
// ------------------------------
export const dateFormatForSaving = (input: Date): string => {
  var inputDate = new Date(input);
  var day = inputDate.getDate().toString().padStart(2, '0');
  var month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  var year = inputDate.getFullYear().toString().padStart(4, '0');
  var result = `${year}-${month}-${day}`;
  return result;
};

// ------------------------------
// simple debug log function which only logs debug messages when in dev environment
// ------------------------------
export const logDebug = (message: string): void => {
  if (!environment.production) {
    console.debug(message);
  }
};

// ------------------------------
// Download list of obkects as csv with cols as header names
// ------------------------------
export const downloadFile = (
  data: any[],
  cols: { field: string; header: string }[],
  filename: string
): void => {
  const csv = [
    cols.map((value) => value.header).join(','), // header row first
    ...data.map((row) =>
      cols
        .map((entry) => {
          var found = Object.entries(row).find(
            (attribute) => attribute[0] === entry.field
          );
          return found === undefined || found[1] === null
            ? ''
            : JSON.stringify(found[1]);
        })
        .join(',')
    ),
  ].join('\r\n');

  const a = document.createElement('a');
  const blob = new Blob(['\ufeff', csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};
