import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { getErrorMessage } from '../shared-layout/shared.tools';
import { GridOptions } from './model/grid-options';

@Injectable({
  providedIn: 'root',
})
export class Gradingscaleservice {
  readonly extendedUserEndpoint: string = `${environment.apiUrl}/api/pt/gradingscale`;
  private getGradeGradingScaleElement: string =
    this.extendedUserEndpoint + '/GetGradingScaleElement?id=';
  gridOptions: GridOptions;

  constructor(private http: HttpClient) {
    this.gridOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }

  getList() {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint, null)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Notenskalen konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  public getGradingScaleElement(id: string): Promise<any> {
    return lastValueFrom(
      this.http
        .get<any>(this.getGradeGradingScaleElement + id)
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }
}
