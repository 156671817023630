import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InternshipsComponent } from './layout/internship/internships/internships.component';
import { StudentDetailComponent } from './layout/student/student-detail/student-detail.component';
import { StudentsComponent } from './layout/student/students/students.component';
import { InternshiptypesComponent } from './layout/internshiptype/internshiptypes/internshiptypes.component';
import { InternshiptypeDetailComponent } from './layout/internshiptype/internshiptype-detail/internshiptype-detail.component';
import { PracticeorganizationComponent } from './layout/practiceorganization/practice-organization-detail/practice-organization-detail.component';
import { InternshipDetailComponent } from './layout/internship/internship-detail/internship-detail.component';
import { PracticeOrganizationsComponent } from './layout/practiceorganization/practice-organizations/practice-organizations.component';
import { TrainersComponent } from './layout/trainer/trainers/trainers.component';
import { MentorsComponent } from './layout/mentor/mentors/mentors.component';
import { TrainerDetailComponent } from './layout/trainer/trainer-detail/trainer-detail.component';
import { MentorDetailComponent } from './layout/mentor/mentor-detail/mentor-detail.component';
import { InternshipperiodDetailComponent } from './layout/internshipperiod/internshipperiod-detail/internshipperiod-detail.component';
import { InternshipperiodsComponent } from './layout/internshipperiod/internshipperiods/internshipperiods.component';
import { SubjectareasComponent } from './layout/subjectarea/subjectareas/subjectareas.component';
import { SubjectareaDetailComponent } from './layout/subjectarea/subjectarea-detail/subjectarea-detail.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: 'internships',
    component: InternshipsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'internship-detail',
    component: InternshipDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'internship-detail/:id',
    component: InternshipDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'student-detail',
    component: StudentDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'student-detail/:id',
    component: StudentDetailComponent,
    canActivate: [MsalGuard],
  },
  { path: 'students', component: StudentsComponent, canActivate: [MsalGuard] },
  {
    path: 'internshiptypes',
    component: InternshiptypesComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'internshiptype-detail',
    component: InternshiptypeDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'internshiptype-detail/:id',
    component: InternshiptypeDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'practiceorganization',
    component: PracticeorganizationComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'practiceorganization/:id',
    component: PracticeorganizationComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'practiceorganizations',
    component: PracticeOrganizationsComponent,
    canActivate: [MsalGuard],
  },
  { path: 'trainers', component: TrainersComponent, canActivate: [MsalGuard] },
  { path: 'mentors', component: MentorsComponent, canActivate: [MsalGuard] },
  {
    path: 'trainer-detail',
    component: TrainerDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'trainer-detail/:id',
    component: TrainerDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'mentor-detail',
    component: MentorDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'mentor-detail/:id',
    component: MentorDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'internshipperiods',
    component: InternshipperiodsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'internshipperiod-detail',
    component: InternshipperiodDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'internshipperiod-detail/:id',
    component: InternshipperiodDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'subjectareas',
    component: SubjectareasComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'subjectarea-detail',
    component: SubjectareaDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'subjectarea-detail/:id',
    component: SubjectareaDetailComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    redirectTo: '/internships',
    pathMatch: 'full',
  },
];

@NgModule({
  /* Azure AD
  imports: [RouterModule.forRoot(routes)],*/
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
