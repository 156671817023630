import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { PracticeOrganization } from '../../../service/model/practiceorganization';
import { PracticeOrganizationService } from '../../../service/practiceorganization.service';
import { UtilsService } from '../../../service/utils.service';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import {
  DeptFieldsPracticeOrganizationDetail,
  deptFieldsPracticeOrganizationDetailShowAll,
} from '../practiceorganisation-fields';
import { RadioGroupEntry } from '../../../service/model/radiogroupentry';
import { InfoService } from '../../../service/info.service';
import { InternshipMatrix } from '../../../service/model/internship-matrix';
import { DatePipe } from '@angular/common';
import { PersonTable } from '../../../service/model/person-table';
import { SubjectareaAssignment } from '../../../service/model/subjectarea-assignment';
import { Trainer } from '../../../service/model/trainer';
import { TrainerSelectDialogComponent } from '../../trainer/trainer-select-dialog/trainer-select-dialog.component';
import { TrainerService } from '../../../service/trainer.service';
import { ConfirmDialogComponent } from '../../../shared-layout/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-practice-organization-detail',
  templateUrl: './practice-organization-detail.component.html',
  styleUrls: ['./practice-organization-detail.component.css'],
  providers: [PracticeOrganizationService],
})
export class PracticeorganizationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private orgService: PracticeOrganizationService,
    private trainerService: TrainerService,
    private utilsService: UtilsService,
    private dialog: MatDialog,
    private router: Router,
    private infoService: InfoService,
    public datepipe: DatePipe
  ) {}
  organization: PracticeOrganization;
  loading: boolean;
  isUpdate: boolean;
  deptFields: DeptFieldsPracticeOrganizationDetail;
  hospitalTypes: RadioGroupEntry[];
  departmentId: BehaviorSubject<string>;
  subjectAreas: SubjectareaAssignment[];

  displayedColumns: string[] = [
    'internshiptype',
    'kw',
    'offered',
    'planned',
    'reserved',
    'nextStartDate',
    'nextEndDate',
    'occupiedPlaces',
    'freePlaces',
  ];

  ngOnInit(): void {
    this.loading = true;
    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(
        data,
        'practiceOrganizationDetail'
      );
      // Nur für Testing alle Felder anzeigen
      //this.deptFields = deptFieldsPracticeOrganizationDetailShowAll;
    });

    this.route.params.subscribe((params) => {
      if (params['id'] != 0 && params['id'] != null) {
        Promise.all([
          this.orgService
            .getSingle(params['id'])
            .then((data) => {
              //console.log('load');
              this.organization = data;
              this.subjectAreas = this.organization.subjectAreas;
              this.initPersons();
              this.isUpdate = true;
            })
            .catch((e) => {
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message:
                    'Die Praxisorganisation konnte nicht geladen werden.',
                },
              });
            }),
          this.orgService
            .getHospitalTypes()
            .then((data) => {
              this.hospitalTypes = data;
            })
            .catch((e) => {
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message: 'Die Spitaltypen konnten nicht geladen werden.',
                },
              });
            }),
        ]).then(() => (this.loading = false));
      } else {
        this.loading = false;
        this.router.navigate(['/practiceorganizations']);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Die Detailansicht kann nicht ohne ID einer Praxisorganisation geöffnet werden.',
          },
        });
      }
    });
  }

  onSubmit() {
    this.loading = true;

    //console.log(this.subjectAreas);
    this.organization.subjectAreas = this.subjectAreas;

    this.orgService
      .save(this.organization)
      .then((data) => {
        window.location.reload();
        if (data) {
          this.router.navigate(['practiceorganization', data.id]);
        }
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Die Praxisorganisation konnte nicht gespeichert werden.',
          },
        });
      });
  }

  initPersons(): void {
    if (!this.organization.contactPerson) {
      this.organization.contactPerson = [new PersonTable()];
    }
    if (
      !this.organization.contactMentor ||
      this.organization.contactMentor.length == 0
    ) {
      this.organization.contactMentor = [new PersonTable()];
    }
    if (!this.organization.contactErgo) {
      this.organization.contactErgo = new PersonTable();
    }
    if (!this.organization.contactEducation) {
      this.organization.contactEducation = [new PersonTable()];
    }
    if (!this.organization.contactContract) {
      this.organization.contactContract = new PersonTable();
    }
    if (!this.organization.contactHr) {
      this.organization.contactHr = new PersonTable();
    }
    if (!this.organization.contactWage) {
      this.organization.contactWage = [new PersonTable()];
    }
    if (!this.organization.contactMidwife) {
      this.organization.contactMidwife = new PersonTable();
    }
    if (!this.organization.contactWeekbed) {
      this.organization.contactWeekbed = new PersonTable();
    }
    if (!this.organization.contactNursing) {
      this.organization.contactNursing = new PersonTable();
    }
    if (!this.organization.contactPraxisTrainer) {
      this.organization.contactPraxisTrainer = new PersonTable();
    }
    if (!this.organization.contactAdministration) {
      this.organization.contactAdministration = new PersonTable();
    }
    if (!this.organization.contactDirection) {
      this.organization.contactDirection = new PersonTable();
    }
    if (!this.organization.contactHospitationOne) {
      this.organization.contactHospitationOne = [new PersonTable()];
    }
    if (!this.organization.contactHospitationTwo) {
      this.organization.contactHospitationTwo = new PersonTable();
    }
    if (!this.organization.contactBsc) {
      this.organization.contactBsc = [new PersonTable()];
    }
    if (!this.organization.contactZmc) {
      this.organization.contactZmc = [new PersonTable()];
    }
    if (!this.organization.contactPhysio) {
      this.organization.contactPhysio = new PersonTable();
    }
  }

  offeredChangeFn(event: any, element: InternshipMatrix): void {
    element.offered = event.target.value;
    if (element.offered !== undefined && element.offered !== null) {
      element.freePlaces = element.offered - element.occupiedPlaces;
    } else {
      element.freePlaces = 0 - element.occupiedPlaces;
    }
    //console.log(event);
    //console.log(element);
  }

  plannedChangeFn(event: any, element: InternshipMatrix): void {
    element.planned = event.target.value;
  }

  reservedChangeFn(event: any, element: InternshipMatrix): void {
    element.reserved = event.target.value;
  }

  openTrainers(): void {
    const dialogRef = this.dialog.open(TrainerSelectDialogComponent, {
      height: '75%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      if (result !== undefined) {
        var trainer = result as Trainer;
        this.setTrainer(trainer);
      }
    });
  }

  setTrainer(trainer: Trainer): void {
    this.trainerService
      .getSingle(trainer.id)
      .then((data) => {
        if (this.organization.contactMentor[0].id === undefined) {
          this.organization.contactMentor[0] = data;
        } else {
          this.organization.contactMentor =
            this.organization.contactMentor.concat(data);
        }
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Praxisausbildende:r konnte nicht geladen werden.',
          },
        });
      });
  }

  deleteTrainer(trainer: PersonTable): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Soll Praxisausbildende:r entfernt werden?.',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.organization.contactMentor.length === 1) {
          this.organization.contactMentor = [new PersonTable()];
        } else {
          this.organization.contactMentor =
            this.organization.contactMentor.filter(
              (element) => element.id !== trainer.id
            );
        }
      }
    });
  }
}
