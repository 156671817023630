import { PersonBase } from './person-base';

export class Person extends PersonBase {
  firstname?: string;
  lastname?: string;
  comment?: string;
  email: string;
  telefon: string;
  mobile: string;
  street?: string;
  addressAddon?: string;
  plz?: string;
  city?: string;
  country?: string;
  remarks?: string;
  internalComments?: string;
  timeofRecognition?: string;
  equivalentRecognitionInfo?: string;
  bsc: boolean;
  zmc: boolean;
  notesForStudents?: string;
  specialCase: boolean;
  specialCaseNotes?: string;
  isActive: boolean;
}
