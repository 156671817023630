// constants which may need to be used in generated exported setting constant
const params = {
  id: 'id',
  viewType: 'internal',
};

const constants = {
  new: 'new',
  internal: 'internal',
  favorite: 'favorite',
  departement: 29,
};

// exported settings
export const SharedSettings = {
  navigate: {
    authLogin: '/auth/login',
    authForgotPassword: '/auth/forgotpassword',
    authChangePassword: '/auth/changepassword',
    internshipList: '/internships',
    internshipListFavorites: `/internships/${constants.favorite}`,
    internshipListInternal: `/internships/${constants.internal}`,
    internshipDetail: '/internship/{0}',
    internshipDetailInternal: `/internship/{0}/${constants.internal}`,
    internshipMarketAvailability: '/internship/availability',
    internshipApplicationCreate: '/internship/{0}/apply',
    internshipApplicationList: '/internship/{0}/applications',
    internshipApplicationPersonalList: '/internship/my-applications',
    usermgmtCreate: '/usermgmt/create',
    usermgmtAssign: '/usermgmt/assign',
    usermgmtDeactivate: '/usermgmt/deactivate',
    usermgmtRegister: '/register',
    notAllowed: '/not-allowed',
    workExperienceList: `/workexperience`,
    workExperienceDetail: '/workexperience/{0}',
  },
  constants: constants,
  params: params,
  upload: {
    fileExtension: '.pdf',
    fileSize: 10485760, // 10MB
  },
  grid: {
    pageSize: 25,
    searchSelectDefaultName: 'Alle',
  },
};
