import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { getErrorMessage } from '../shared-layout/shared.tools';
import { lastValueFrom, throwError } from 'rxjs';

import { Student } from './model/student';
import { GridOptions } from './model/grid-options';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable()
export class StudentService {
  readonly extendedUserEndpoint: string = `${environment.apiUrl}/api/pt/student`;
  gridOptions: GridOptions;

  constructor(private http: HttpClient) {
    this.gridOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }

  getDummyData() {
    return lastValueFrom(this.http.get<any>('../../assets/students.json'))
      .then((res) => <Student[]>res.data)
      .then((data) => {
        return data;
      });
  }

  getList(event: TableLazyLoadEvent) {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint, event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Studenten konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getListWithInactive(event: TableLazyLoadEvent) {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint + '/publishedwithinactive', event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Studenten konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getSingle(id: string) {
    return lastValueFrom(
      this.http
        .get<any>(this.extendedUserEndpoint + '/student?id=' + id)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Studenten konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getInternships(id: string) {
    return lastValueFrom(
      this.http
        .get<any>(this.extendedUserEndpoint + '/internships?id=' + id)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Arbeitsverhältnisse konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  saveStudent(student: Student) {
    //console.log(JSON.stringify(student));
    if (student.id != null) {
      return lastValueFrom(
        this.http
          .post<Student>(
            this.extendedUserEndpoint + '/new',
            JSON.stringify(student),
            this.gridOptions
          )
          .pipe(
            catchError((error) =>
              throwError(
                () =>
                  new Error(
                    getErrorMessage(
                      error,
                      'Studenten konnten nicht gespeichert werden.'
                    )
                  )
              )
            )
          )
      );
    }
    return null;
  }

  getListforExport(event: TableLazyLoadEvent) {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint + '/publishedforexport', event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Studierende konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }
}
