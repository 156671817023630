export class DeptFieldsMentors {
  mentor: boolean;
  isIER: boolean;
}
export const deptFieldsMentorsS: DeptFieldsMentors = {
  mentor: true,
  isIER: false,
};
export const deptFieldsMentorsIPH: DeptFieldsMentors = {
  mentor: true,
  isIER: false,
};
export const deptFieldsMentorsIER: DeptFieldsMentors = {
  mentor: true,
  isIER: true,
};
export const deptFieldsMentorsDefault: DeptFieldsMentors = {
  mentor: false,
  isIER: false,
};

export class DeptFieldsMentorDetail {
  mentor: boolean;
}
export const deptFieldsMentorDetailS: DeptFieldsMentorDetail = {
  mentor: true,
};
export const deptFieldsMentorDetailIPH: DeptFieldsMentorDetail = {
  mentor: true,
};
export const deptFieldsMentorDetailDefault: DeptFieldsMentorDetail = {
  mentor: false,
};
