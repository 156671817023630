import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MentorService } from '../../../service/mentor.service';
import { Mentor } from '../../../service/model/mentor';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { DeptFieldsMentorDetail } from '../mentor-fields';

@Component({
  selector: 'app-mentor-detail',
  templateUrl: './mentor-detail.component.html',
  styleUrls: ['./mentor-detail.component.css'],
  providers: [MentorService],
})
export class MentorDetailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private mentorService: MentorService,
    private dialog: MatDialog,
    private router: Router
  ) {}
  mentor: Mentor;
  loading: boolean;
  isUpdate: boolean;
  deptFields: DeptFieldsMentorDetail;

  ngOnInit(): void {
    this.loading = true;

    this.route.params.subscribe((params) => {
      if (params['id'] != 0 && params['id'] != null) {
        this.mentorService
          .getSingle(params['id'])
          .then((data) => {
            this.mentor = data;
            this.isUpdate = true;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message:
                  'Studierendenbegleitende:r konnte nicht geladen werden.',
              },
            });
          });
      } else {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Die Detailansicht kann nicht ohne ID eines Studierendenbegleitenden geöffnet werden.',
          },
        });
        this.router.navigate(['/mentors']);
      }

      this.loading = false;
    });
  }

  onSubmit() {
    this.loading = true;

    this.mentorService
      .save(this.mentor)
      .then((data) => {
        this.loading = false;
        window.location.reload();
        if (data) {
          this.router.navigate(['mentor-detail', data.id]);
        }
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Studierendenbegleitende:r konnte nicht gespeichert werden.',
          },
        });
      });
  }
}
