<header>
  <div class="navbar">
    <div style="width: 100px">
      <a routerLink="/"><div class="logo"></div></a>
    </div>
    <div class="container">
      <div class="row-container">
        <div class="app-title">Praxistool</div>
      </div>
      <div class="row-container">
        <button
          type="button"
          pButton
          pRipple
          class="btn btn-primary nav-item"
          routerLink="/internships"
        >
          Ausbildungsverhältnisse
        </button>

        <button
          type="button"
          pButton
          pRipple
          class="btn btn-primary nav-item"
          [matMenuTriggerFor]="menuMitwirkende"
        >
          Mitwirkende
        </button>
        <mat-menu #menuMitwirkende="matMenu">
          <button
            mat-menu-item
            routerLink="/students"
            routerLinkActive="active"
          >
            Studierende
          </button>
          <button
            *ngIf="showMentor"
            mat-menu-item
            routerLink="/mentors"
            routerLinkActive="active"
          >
            Studierendenbegleitende
          </button>
          <button
            mat-menu-item
            routerLink="/trainers"
            routerLinkActive="active"
          >
            Praxisausbildende
          </button>
          <button
            mat-menu-item
            routerLink="/practiceorganizations"
            routerLinkActive="active"
          >
            Praxisorganisationen
          </button>
        </mat-menu>

        <button
          type="button"
          pButton
          pRipple
          class="btn btn-primary nav-item"
          [matMenuTriggerFor]="menuAdministration"
        >
          Administration
        </button>
        <mat-menu #menuAdministration="matMenu">
          <button
            mat-menu-item
            routerLink="/internshiptypes"
            routerLinkActive="active"
          >
            Praktikumstypen
          </button>
          <button
            *ngIf="showInternshipperiods"
            mat-menu-item
            routerLink="/internshipperiods"
            routerLinkActive="active"
          >
            Praktikumsperioden
          </button>
          <button
            *ngIf="showSubjectareas"
            mat-menu-item
            routerLink="/subjectareas"
            routerLinkActive="active"
          >
            Fachbereiche
          </button>
        </mat-menu>

        <button
          type="button"
          pButton
          pRipple
          class="btn btn-primary nav-item"
          *ngIf="!loginDisplay"
        >
          <a (click)="login()">Login</a>
        </button>
        <button
          type="button"
          pButton
          pRipple
          class="btn btn-primary nav-item"
          *ngIf="loginDisplay"
          [ngbTooltip]="username | async"
          placement="bottom-end"
        >
          <a (click)="logout()">Logout</a>
        </button>
      </div>
    </div>
  </div>
</header>
