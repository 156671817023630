import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RadioGroupEntry } from '../../../service/model/radiogroupentry';
import { Trainer } from '../../../service/model/trainer';
import { TrainerService } from '../../../service/trainer.service';
import { UtilsService } from '../../../service/utils.service';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import {
  DeptFieldsTrainerDetail,
  deptFieldsTrainerDetailShowAll,
} from '../trainer-fields';
import { InfoService } from '../../../service/info.service';

@Component({
  selector: 'app-trainer-detail',
  templateUrl: './trainer-detail.component.html',
  styleUrls: ['./trainer-detail.component.css'],
  providers: [TrainerService],
})
export class TrainerDetailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private trainerService: TrainerService,
    private utilsService: UtilsService,
    private dialog: MatDialog,
    private router: Router,
    private infoService: InfoService
  ) {}
  trainer: Trainer;
  loading: boolean;
  isUpdate: boolean;
  recognitionStates: RadioGroupEntry[];
  selectedRecognitionState: number;
  deptFields: DeptFieldsTrainerDetail;
  departmentId: BehaviorSubject<string>;

  ngOnInit(): void {
    this.loading = true;

    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(data, 'trainerDetail');
      // Nur für Testing alle Felder anzeigen
      //this.deptFields = deptFieldsTrainerDetailShowAll;
    });

    this.route.params.subscribe((params) => {
      if (params['id'] != 0 && params['id'] != null) {
        Promise.all([
          this.trainerService
            .getSingle(params['id'])
            .then((data) => {
              //console.log('load');
              this.trainer = data;
              //console.log(this.trainer);
              this.isUpdate = true;
              this.selectedRecognitionState = this.trainer.recognitionState;
            })
            .catch((e) => {
              this.loading = false;
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message: 'Praxisausbildende:r konnte nicht geladen werden.',
                },
              });
            }),
          this.trainerService
            .getRecognitionStates()
            .then((data) => {
              this.recognitionStates = data;
            })
            .catch((e) => {
              this.loading = false;
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message:
                    'Annerkennunsstatusliste konnte nicht geladen werden.',
                },
              });
            }),
        ]).then(() => (this.loading = false));
      } else {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Die Detailansicht kann nicht ohne ID eines Praxisausbildenden geöffnet werden.',
          },
        });
        this.router.navigate(['/trainers']);
      }
    });
  }

  onSubmit() {
    this.loading = true;

    this.trainer.recognitionState = this.selectedRecognitionState;
    //console.log(this.trainer);

    this.trainerService
      .save(this.trainer)
      .catch((e) => {
        this.loading = false;
        window.location.reload();
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Praxisausbildende:r konnte nicht gespeichert werden.',
          },
        });
      })
      .then((data) => {
        if (data) {
          this.router.navigate(['trainer-detail', data.id]);
        }
        this.loading = false;
      });
  }
}
