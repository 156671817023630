<div class="row">
  <div class="col">
    <div>
      <h2 class="mb-3">
        <a
          routerLink="/mentors"
          routerLinkActive="active"
          ngbTooltip="zur Übersicht"
          ><i class="pi pi-angle-left bluecolor"></i></a
        >&nbsp;Studierendenbegleitende:r&nbsp;
        <span *ngIf="mentor && !mentor.isActive">[Inaktiv]</span>
      </h2>

      <form *ngIf="mentor">
        <div class="form-group">
          <div class="row">
            <div class="col-sm">
              <label for="firstname">Vorname: </label>
              <input
                class="form-control"
                id="firstname"
                name="firstname"
                [(ngModel)]="this.mentor.firstname"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="Surname">Nachname: </label>
              <input
                class="form-control"
                id="lastname"
                name="lastname"
                [(ngModel)]="this.mentor.lastname"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="Email">Email: </label>
              <a href="mailto:{{ this.mentor.emailText }}"
                ><input
                  class="form-control"
                  id="emailText"
                  name="emailText"
                  [(ngModel)]="this.mentor.emailText"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
              /></a>
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label for="Phone1">Telefon 1: </label>
              <input
                class="form-control"
                id="telefon"
                name="telefon"
                [(ngModel)]="this.mentor.telefon"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="Phone2">Telefon 2: </label>
              <input
                class="form-control"
                id="telefon2"
                name="telefon2"
                [(ngModel)]="this.mentor.telefon2"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="Mobile">Mobile: </label>
              <input
                class="form-control"
                id="mobile"
                name="mobile"
                [(ngModel)]="this.mentor.mobile"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>

          <div class="row">
            <h4 class="mb-2">Adresse</h4>
            <div class="col-sm">
              <label for="Street">Strasse: </label>
              <input
                class="form-control"
                id="street"
                name="street"
                [(ngModel)]="this.mentor.street"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="addressAddon">Adresszusatz: </label>
              <input
                class="form-control"
                id="addressAddon"
                name="addressAddon"
                [(ngModel)]="this.mentor.addressAddon"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label for="ZIP">PLZ: </label>
              <input
                class="form-control"
                id="plz"
                name="plz"
                [(ngModel)]="this.mentor.plz"
                [readonly]="true"
                [disabled]="true"
                id="ZIP"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="City">Ort: </label>
              <input
                class="form-control"
                id="city"
                name="city"
                [(ngModel)]="this.mentor.city"
                [readonly]="true"
                [disabled]="true"
                id="City"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="Country">Land: </label>
              <input
                class="form-control"
                id="country"
                name="country"
                [(ngModel)]="this.mentor.country"
                [readonly]="true"
                [disabled]="true"
                id="Country"
                size="40"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label for="remarks">Bemerkungen: </label>
              <p-editor
                [style]="{
                  height: '200px',
                  'border-radius': '0 0 0.375rem 0.375rem'
                }"
                id="remarks"
                name="remarks"
                [(ngModel)]="this.mentor.remarks"
                [readonly]="false"
                [disabled]="false"
              ></p-editor>
            </div>
          </div>

          <button class="btn btn-primary float-right" (click)="onSubmit()">
            Speichern
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
