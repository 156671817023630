import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DlHeaderComponent } from './shared-layout/dl-header/dl-header.component';
import { DlFooterComponent } from './shared-layout/dl-footer/dl-footer.component';
import { InternshipsComponent } from './layout/internship/internships/internships.component';
import { StudentsComponent } from './layout/student/students/students.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { StudentDetailComponent } from './layout/student/student-detail/student-detail.component';
import { InternshiptypesComponent } from './layout/internshiptype/internshiptypes/internshiptypes.component';
import { InternshiptypeDetailComponent } from './layout/internshiptype/internshiptype-detail/internshiptype-detail.component';
import { PracticeorganizationComponent } from './layout/practiceorganization/practice-organization-detail/practice-organization-detail.component';
import { InternshipDetailComponent } from './layout/internship/internship-detail/internship-detail.component';
import { ProcessingComponent } from './shared-layout/processing/processing.component';
import { NotFoundComponent } from './shared-layout/not-found/not-found.component';
import { NotAllowedComponent } from './shared-layout/not-allowed/not-allowed.component';
import { TabViewModule } from 'primeng/tabview';
import { PracticeOrganizationsComponent } from './layout/practiceorganization/practice-organizations/practice-organizations.component';
import { MentorsComponent } from './layout/mentor/mentors/mentors.component';
import { TrainersComponent } from './layout/trainer/trainers/trainers.component';
import { TrainerDetailComponent } from './layout/trainer/trainer-detail/trainer-detail.component';
import { MentorDetailComponent } from './layout/mentor/mentor-detail/mentor-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileSaverModule } from 'ngx-filesaver';
import { DialogModule } from 'primeng/dialog';
// Azure AD
// Import MSAL and MSAL browser libraries.
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
// Import the Azure AD B2C configuration
import { EditorModule } from 'primeng/editor';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MatDialogModule } from '@angular/material/dialog';
import { InternshiptypeSelectDialogComponent } from './layout/internshiptype/internshiptype-select-dialog/internshiptype-select-dialog.component';
import { MentorSelectDialogComponent } from './layout/mentor/mentor-select-dialog/mentor-select-dialog.component';
import { PracticeOrganizationsSelectComponent } from './layout/practiceorganization/practiceorganization-select-dialog/practiceorganization-select-dialog.component';
import { TrainerSelectDialogComponent } from './layout/trainer/trainer-select-dialog/trainer-select-dialog.component';
import { StudentSelectDialogComponent } from './layout/student/student-select-dialog/student-select-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { StoreModule } from '@ngrx/store';
import {
  rowsSelectionInternshipsReducer,
  rowsSelectionInternshiptypesReducer,
  rowsSelectionMentorsReducer,
  rowsSelectionPracticeOrganizationsReducer,
  rowsSelectionStudentsReducer,
  rowsSelectionTrainersReducer,
  rowsSelectionInternshipperiodsReducer,
  rowsSelectionSubjectareasReducer,
} from './state/rows-selection.reducer';
import { DatePipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { InternshipperiodsComponent } from './layout/internshipperiod/internshipperiods/internshipperiods.component';
import { InternshipperiodDetailComponent } from './layout/internshipperiod/internshipperiod-detail/internshipperiod-detail.component';
import { InternshipperiodSelectDialogComponent } from './layout/internshipperiod/internshipperiod-select-dialog/internshipperiod-select-dialog.component';
import { SubjectareasComponent } from './layout/subjectarea/subjectareas/subjectareas.component';
import { SubjectareaDetailComponent } from './layout/subjectarea/subjectarea-detail/subjectarea-detail.component';
import { msalConfig, protectedResources } from './auth-config';
import { ErrorDialogComponent } from './shared-layout/error-dialog/error-dialog.component';
import { ConfirmDialogComponent } from './shared-layout/confirm-dialog/confirm-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({ declarations: [
        AppComponent,
        DlHeaderComponent,
        DlFooterComponent,
        InternshipsComponent,
        StudentDetailComponent,
        StudentsComponent,
        InternshiptypesComponent,
        InternshiptypeDetailComponent,
        PracticeorganizationComponent,
        InternshipDetailComponent,
        ProcessingComponent,
        NotFoundComponent,
        NotAllowedComponent,
        PracticeOrganizationsComponent,
        MentorsComponent,
        TrainersComponent,
        TrainerDetailComponent,
        MentorDetailComponent,
        InternshiptypeSelectDialogComponent,
        MentorSelectDialogComponent,
        PracticeOrganizationsSelectComponent,
        TrainerSelectDialogComponent,
        StudentSelectDialogComponent,
        InternshipperiodsComponent,
        InternshipperiodDetailComponent,
        InternshipperiodSelectDialogComponent,
        SubjectareasComponent,
        SubjectareaDetailComponent,
        ErrorDialogComponent,
        ConfirmDialogComponent,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        AppRoutingModule,
        NgbModule,
        BrowserAnimationsModule,
        TableModule,
        ButtonModule,
        TabViewModule,
        EditorModule,
        DropdownModule,
        CalendarModule,
        FormsModule,
        ReactiveFormsModule,
        FileSaverModule,
        DialogModule,
        MatDialogModule,
        MatRadioModule,
        MatTabsModule,
        MatMenuModule,
        MatIconModule,
        MatExpansionModule,
        MatTableModule,
        StoreModule.forRoot({
            rowsSelectionStudents: rowsSelectionStudentsReducer,
            rowsSelectionMentors: rowsSelectionMentorsReducer,
            rowsSelectionTrainers: rowsSelectionTrainersReducer,
            rowsSelectionPracticeOrganizations: rowsSelectionPracticeOrganizationsReducer,
            rowsSelectionInternships: rowsSelectionInternshipsReducer,
            rowsSelectionInternshiptypes: rowsSelectionInternshiptypesReducer,
            rowsSelectionInternshipperiods: rowsSelectionInternshipperiodsReducer,
            rowsSelectionSubjectareas: rowsSelectionSubjectareasReducer,
        }),
        MatCheckboxModule,
        MatGridListModule,
        // Initiate the MSAL library with the MSAL configuration object
        MsalModule.forRoot(new PublicClientApplication(msalConfig), {
            // The routing guard configuration.
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: protectedResources.praxistoolApi.scopes,
            },
        }, {
            // MSAL interceptor configuration.
            // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map(protectedResources.praxistoolApi.endpoints.map((e) => [
                e,
                protectedResources.praxistoolApi.scopes,
            ])),
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalGuard,
        DatePipe,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
