import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { setMentorsRows } from '../../../state/rows-selection.actions';
import { MentorService } from '../../../service/mentor.service';
import { Mentor } from '../../../service/model/mentor';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeptFieldsMentors } from '../mentor-fields';
import { InfoService } from '../../../service/info.service';
import { UtilsService } from '../../../service/utils.service';
import { Table, TableCheckbox, TableLazyLoadEvent } from 'primeng/table';
import { downloadFile } from '../../../shared-layout/shared.tools';
import { ConfirmDialogComponent } from '../../../shared-layout/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-mentors',
  templateUrl: './mentors.component.html',
  styleUrls: ['./mentors.component.css'],
  providers: [MentorService],
})
export class MentorsComponent implements OnInit {
  mentors: Mentor[];
  selectedMentors: Mentor[];
  totalRecords: number;
  loading: boolean;
  isForSelection = false;
  rowsSelectionMentors$: Observable<number>;
  departmentId: BehaviorSubject<string>;
  deptFields: DeptFieldsMentors;
  @ViewChild('dt') table: Table;
  selectAllChecked = false;
  cols: any[];
  filename = 'Studierendenbegleitende';
  visibleForRefresh: boolean = true;
  showInactive = false;

  constructor(
    private mentorService: MentorService,
    private store: Store<{
      rowsSelectionMentors: number;
      dataSourceObjectId: string;
    }>,
    private dialog: MatDialog,
    private infoService: InfoService,
    private utilsService: UtilsService
  ) {
    this.rowsSelectionMentors$ = store.select('rowsSelectionMentors');
    //console.log(this.rowsSelectionMentors$);
  }

  ngOnInit(): void {
    this.loading = true;
    this.departmentId = this.infoService.getDepartmentId();

    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(data, 'mentors');
    });

    //cols for export
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'addressText', header: 'Adresse' },
      { field: 'cityText', header: 'Stadt' },
      { field: 'emailText', header: 'E-Mail' },
      { field: 'telefon', header: 'Telefon' },
      { field: 'id', header: 'Id' },
    ];
  }

  filter(event: TableLazyLoadEvent) {
    //If search critera changes undo selection
    this.undoSelection();
  }

  loadMentors(event: TableLazyLoadEvent) {
    this.loading = true;

    //console.log(event.rows)
    this.store.dispatch(setMentorsRows({ rows: event.rows }));
    this.rowsSelectionMentors$ = this.store.select('rowsSelectionMentors');

    //console.log(event);
    setTimeout(() => {
      if (this.showInactive) {
        this.mentorService
          .getListWithInactive(event)
          .then((data) => {
            this.mentors = data['records'];
            this.totalRecords = data['count'];

            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message:
                  'Studierendenbegleitende konnten nicht geladen werden.',
              },
            });
          });
      } else {
        this.mentorService
          .getList(event)
          .then((data) => {
            this.mentors = data['records'];
            this.totalRecords = data['count'];

            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message:
                  'Studierendenbegleitende konnten nicht geladen werden.',
              },
            });
          });
      }
    }, 1000);
  }

  selectMentor(mentor: Mentor) {}

  checkboxTable(tableCheckBox: TableCheckbox, event: any): void {
    //console.log(event);
    const isChecked = !!event.target.ariaChecked;
    if (event.button === 0 && event.shiftKey) {
      if (isChecked) {
        this.table.selectRange(event, tableCheckBox.index);
      } else {
        this.table.rangeRowIndex = tableCheckBox.index;
        this.table.clearSelectionRange(event);
        this.table.tableService.onSelectionChange();
      }
    }
    this.table.anchorRowIndex = tableCheckBox.index;
  }

  selectAll() {
    if (!this.selectAllChecked) {
      this.selectedMentors = [];
    } else {
      if (this.totalRecords > 1500) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            message:
              'Sollen alle Studierendenbegleitenden ausgewählt werden? Das kann eventuell mehrere Minuten dauern.',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectAllRequest();
          } else {
            this.selectAllChecked = false;
          }
        });
      } else {
        this.selectAllRequest();
      }
    }
  }

  selectAllRequest(): void {
    this.loading = true;
    const event = this.table.createLazyLoadMetadata() as TableLazyLoadEvent;
    this.mentorService
      .getListforExport(event)
      .then((data) => {
        this.selectedMentors = data['records'];
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Die Studierendenbegleitenden für den Export konnten nicht geladen werden.',
          },
        });
      });
  }

  downloadExport(): void {
    downloadFile(this.selectedMentors, this.cols, this.filename);
  }

  undoSelection(): void {
    this.selectAllChecked = false;
    this.selectedMentors = [];
  }

  checkShowInactive() {
    this.selectAllChecked = false;
    this.selectAll();
    this.visibleForRefresh = false;
    setTimeout(() => (this.visibleForRefresh = true), 0);
  }
}
