<div class="row">
  <div class="col">
    <div>
      <h2>
        <a
          routerLink="/internshiptypes"
          routerLinkActive="active"
          ngbTooltip="zur Übersicht"
          ><i class="pi pi-angle-left bluecolor"></i></a
        >&nbsp;Praktikumstyp<span *ngIf="!isUpdate"> erfassen</span>
      </h2>
      <form *ngIf="internshipType" (submit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-sm">
              <label for="name">Bezeichnung: </label>
              <input
                class="form-control"
                name="name"
                [(ngModel)]="internshipType.name"
                [readonly]="false"
                [disabled]="false"
                size="40"
                id="name"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    makeFieldsRequired &&
                    (internshipType.name === undefined ||
                      internshipType.name === '')
                }"
              />
            </div>
            <div *ngIf="deptFields.startcalendarweek" class="col-sm">
              <label for="CalendarWeek">Start in KW:</label
              ><input
                name="startcalendarweek"
                type="number"
                class="form-control"
                [(ngModel)]="internshipType.startcalendarweek"
                [readonly]="false"
                [disabled]="false"
                size="40"
              />
            </div>
            <div *ngIf="deptFields.durationInWeeks" class="col-sm">
              <label for="Duration">Dauer in Wochen:</label
              ><input
                class="form-control"
                type="number"
                name="durationInWeeks"
                [(ngModel)]="internshipType.durationInWeeks"
                [readonly]="false"
                [disabled]="false"
                size="40"
              />
            </div>
            <div *ngIf="deptFields.workload" class="col-sm">
              <label for="workload">Arbeitspensum:</label>
              <input
                class="form-control"
                name="workload"
                type="number"
                [(ngModel)]="internshipType.workload"
                id="workload"
                size="40"
                [readonly]="false"
                [disabled]="false"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label
                >Notenskala:<span
                  *ngIf="
                    submitted &&
                    makeFieldsRequired &&
                    selectedgradingscale == undefined
                  "
                  class="alert"
                  >*</span
                ></label
              ><br />
              <p-dropdown
                #dd2
                name="selectedgradingscale"
                filterBy="name"
                scrollHeight="400px"
                [style]="{ width: '100%' }"
                [options]="gradingscales"
                [(ngModel)]="selectedgradingscale"
                [filter]="!true"
                optionLabel="name"
                [readonly]="false"
                [editable]="!false"
                [ngClass]="{
                  'ng-invalid ng-dirty':
                    submitted &&
                    makeFieldsRequired &&
                    selectedgradingscale == undefined
                }"
              >
                <ng-template let-item pTemplate="gradingscales">
                  <span>{{ item.name }}</span>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-sm">
              <label for="deadlineForSubmissionOfTargetInterviewInWeeks"
                >Frist für Abgabe Zielvereinbarung in Wochen:</label
              ><input
                class="form-control"
                type="number"
                name="deadlineForSubmissionOfTargetInterviewInWeeks"
                [(ngModel)]="
                  internshipType.deadlineForSubmissionOfTargetInterviewInWeeks
                "
                [readonly]="false"
                [disabled]="false"
                size="40"
              />
            </div>

            <div
              *ngIf="deptFields.deadlineForAssessmentFormDeliveryShouldInWeeks"
              class="col-sm"
            >
              <label for="deadlineForAssessmentFormDeliveryShouldInWeeks"
                >Frist für Abgabe Beurteilungsbogen (Soll) in Wochen:</label
              ><input
                class="form-control"
                type="number"
                name="deadlineForAssessmentFormDeliveryShouldInWeeks"
                [(ngModel)]="
                  internshipType.deadlineForAssessmentFormDeliveryShouldInWeeks
                "
                [readonly]="false"
                [disabled]="false"
                size="40"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label
                >Verbindung zu den Praktikumstypen aus der
                Praxisbeurteilung:</label
              ><br />
              <p-dropdown
                #dd2
                name="selectedInternshipTypeIdLookup"
                filterBy="name"
                scrollHeight="400px"
                [style]="{ width: '100%' }"
                [options]="internshipTypeIdsLookup"
                [(ngModel)]="selectedInternshipTypeIdLookup"
                [filter]="!true"
                optionLabel="name"
                [readonly]="false"
                [editable]="!false"
              >
                <ng-template
                  let-item
                  pTemplate="selectedInternshipTypeIdLookup"
                >
                  <span>{{ item.name }}</span>
                </ng-template>
              </p-dropdown>
            </div>

            <div *ngIf="deptFields.ausbildungsjahr" class="col-sm">
              <label for="trainingYear">Ausbildungsjahr:</label
              ><input
                class="form-control"
                type="number"
                name="trainingYear"
                [(ngModel)]="internshipType.trainingYear"
                [readonly]="false"
                [disabled]="false"
                size="40"
              />
            </div>
          </div>
        </div>
        <button
          *ngIf="isAdminBehaviorSubject | async"
          class="btn btn-primary float-right"
        >
          Speichern
        </button>
      </form>
    </div>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
