import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../shared-layout/error-dialog/error-dialog.component';
import { InternshipService } from './internship.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  departmentId = new BehaviorSubject('');
  webApiVersion = new BehaviorSubject('');
  displayname = new BehaviorSubject('');
  username = new BehaviorSubject('');
  lastImport = new BehaviorSubject('');
  canWrite = new BehaviorSubject(false);
  isAdmin = new BehaviorSubject(false);

  constructor(
    private internshipService: InternshipService,
    private dialog: MatDialog
  ) {
    this.refreshData();
  }

  refreshData() {
    this.internshipService
      .getDeptInfo()
      .then((data) => {
        //console.log(data);
        this.departmentId.next(data.dataSourceObjectId);
        this.webApiVersion.next(data.webApiVersion);
        this.displayname.next(data.displayname);
        this.username.next(data.username);
        this.lastImport.next(data.lastImport);
        this.canWrite.next(data.canWrite);
        this.isAdmin.next(data.isAdmin);
      })
      .catch((e) => {
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Das Department und Versionsinfo konnten nicht geladen werden.',
          },
        });
      });
  }

  getDepartmentId(): BehaviorSubject<string> {
    return this.departmentId;
  }

  getDisplayname(): BehaviorSubject<string> {
    return this.displayname;
  }

  getWebApiVersion(): BehaviorSubject<string> {
    return this.webApiVersion;
  }

  getUsername(): BehaviorSubject<string> {
    return this.username;
  }

  getLastImport(): BehaviorSubject<string> {
    return this.lastImport;
  }

  getCanWrite(): BehaviorSubject<boolean> {
    return this.canWrite;
  }

  getIsAdmin(): BehaviorSubject<boolean> {
    return this.isAdmin;
  }
}
