import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { InternshipService } from '../../../service/internship.service';
import { Internship } from '../../../service/model/internship';
import { MatDialog } from '@angular/material/dialog';
import { setInternshipsRows } from '../../../state/rows-selection.actions';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { ConfirmDialogComponent } from '../../../shared-layout/confirm-dialog/confirm-dialog.component';
import { Table, TableCheckbox, TableLazyLoadEvent } from 'primeng/table';
import { DeptFieldsInternships } from '../internship-fields';
import { UtilsService } from '../../../service/utils.service';
import { InfoService } from '../../../service/info.service';
import { ReportService } from '../../../service/report.service';
import { WorkflowStepExecute } from '../../../service/model/workflow-step-execute';
import { Mentor } from '../../../service/model/mentor';
import { PracticeOrganization } from '../../../service/model/practiceorganization';
import { Student } from '../../../service/model/student';
import { Trainer } from '../../../service/model/trainer';

@Component({
  selector: 'app-internships',
  templateUrl: './internships.component.html',
  styleUrls: ['./internships.component.css'],
  providers: [InternshipService],
})
export class InternshipsComponent implements OnInit, AfterViewInit {
  internships: Internship[];
  selectedInternships: Internship[];
  internshipsForExport: Internship[];
  loading: boolean;
  totalRecords: number;
  showArchive = false;
  exportColumns: any[];
  isForSelection = false;
  visibleForRefresh: boolean = true;
  rowsSelectionInternships$: Observable<number>;
  departmentId: BehaviorSubject<string>;
  deptFields: DeptFieldsInternships;
  abgabeQualiAusstehend: boolean;
  selectAllChecked = false;
  @ViewChild('dt') table: Table;
  filename = 'Ausbildungsverhältnisse';
  internshipCols: { field: string; header: string }[];
  mentorCols: { field: string; header: string }[];
  trainerCols: { field: string; header: string }[];
  practiceOrganizationCols: { field: string; header: string }[];
  studentCols: { field: string; header: string }[];
  mAiAVersandLAQG1vornehmen: boolean;
  mAiAVersandLAQG2vornehmen: boolean;
  offeneLehrauftraegeZG1: boolean;
  offeneLehrauftraegeZG2: boolean;
  praktikumVersandLAQG1vornehmen: boolean;
  praktikumVersandLAQG2vornehmen: boolean;

  constructor(
    private internshipService: InternshipService,
    private utilsService: UtilsService,
    public dialog: MatDialog,
    private store: Store<{
      rowsSelectionInternships: number;
    }>,
    public datepipe: DatePipe,
    private infoService: InfoService,
    private reportService: ReportService
  ) {
    this.rowsSelectionInternships$ = store.select('rowsSelectionInternships');
    //console.log(this.rowsSelectionInternships$);
  }

  ngAfterViewInit(): void {
    this.departmentId.subscribe((data) => {
      if (data === '689119E5-AD45-4E84-9AB3-43C792725A35') {
        this.setStudyStateFilter('Aktiv');
      }
    });
  }

  ngOnInit() {
    this.loading = true;
    this.departmentId = this.infoService.getDepartmentId();

    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(data, 'internships');
      // Nur für Testing alle Felder anzeigen
      //this.deptFields = deptFieldsInternshipsShowAll;
    });

    this.studentCols = [
      { field: 'id', header: 'Studierende:r-Id' },
      { field: 'firstname', header: 'Studierende:r Vorname' },
      { field: 'lastname', header: 'Studierende:r Nachname' },
      { field: 'street', header: 'Studierende:r Strasse' },
      { field: 'addressAddon', header: 'Studierende:r Adresszusatz' },
      { field: 'plz', header: 'Studierende:r PLZ' },
      { field: 'city', header: 'Studierende:r Ort' },
      { field: 'country', header: 'Studierende:r Land' },
      { field: 'class', header: 'Studierende:r Studienjahrgang' },
      { field: 'emailText', header: 'Studierende:r E-Mail' },
      { field: 'telefon', header: 'Studierende:r Telefon' },
    ];
    this.practiceOrganizationCols = [
      { field: 'id', header: 'Praxisorganisation-Id' },
      {
        field: 'organisationname',
        header: 'Praxisorganisation Name',
      },
      {
        field: 'addon',
        header: 'Praxisorganisation Firmenname 2',
      },
      {
        field: 'street',
        header: 'Praxisorganisation Strasse',
      },
      { field: 'addressAddon', header: 'Praxisorganisation Adresszusatz' },
      {
        field: 'plz',
        header: 'Praxisorganisation PLZ',
      },
      {
        field: 'city',
        header: 'Praxisorganisation Ort',
      },
      {
        field: 'country',
        header: 'Praxisorganisation Land',
      },
    ];
    this.trainerCols = [
      { field: 'id', header: 'Praxisausbildende:r-Id' },
      {
        field: 'firstname',
        header: 'Praxisausbildende:r Vorname',
      },
      {
        field: 'lastname',
        header: 'Praxisausbildende:r Nachname',
      },
      {
        field: 'emailText',
        header: 'Praxisausbildende:r E-Mail',
      },
      {
        field: 'telefon',
        header: 'Praxisausbildende:r Telefon',
      },
    ];
    this.mentorCols = [
      { field: 'id', header: 'Studierendenbelgeitende-Id' },
      { field: 'firstname', header: 'Studierendenbelgeitende Vorname' },
      { field: 'lastname', header: 'Studierendenbelgeitende Nachname' },
      { field: 'street', header: 'Studierendenbelgeitende Strasse' },
      { field: 'addressAddon', header: 'Studierendenbelgeitende Adresszusatz' },
      { field: 'plz', header: 'Studierendenbelgeitende PLZ' },
      { field: 'city', header: 'Studierendenbelgeitende Ort' },
      { field: 'country', header: 'Studierendenbelgeitende Land' },
      { field: 'emailText', header: 'Studierendenbelgeitende E-Mail' },
      { field: 'telefon', header: 'Studierendenbelgeitende Telefon' },
    ];
    this.internshipCols = [
      { field: 'startdate', header: 'Praktikumsbeginn' },
      { field: 'enddate', header: 'Praktikumsende' },
      { field: 'workingDays', header: 'Anzahl Arbeitstage (Soll)' },
      { field: 'workload', header: 'Arbeitspensum' },
      { field: 'comment', header: 'Bewertung: Bemerkungen' },
      {
        field: 'shouldDateAssessmentForm',
        header: 'Abgabe Quali (Soll)',
      },
      {
        field: 'isDateAssessmentForm',
        header: 'Abgabe Quali (Ist)',
      },
      {
        field: 'shouldDateContract',
        header: 'Abgabe Zielvereinbarung (Soll)',
      },
      { field: 'isDateContract', header: 'Abgabe Zielvereinbarung (Ist)' },
      { field: 'grade', header: 'Note' },
      { field: 'gradeDate', header: 'Note gesetzt' },
      { field: 'gradeFixed', header: 'Note fix' },
      { field: 'subjectAreasNameComp', header: 'Fachbereiche' },
      { field: 'planningYear', header: 'Planungsjahr' },
      { field: 'specialCase', header: 'Spezialfall' },
      { field: 'specialCaseNotes', header: 'Spezialfall Bemerkung' },
      { field: 'workingHours', header: 'Anzahl Stunden' },
      { field: 'conditionNotes', header: 'Auflagen' },
      { field: 'assessmentSheetAssignmentOk', header: 'Lehrauftrag Quali OK' },
      { field: 'voucherDate', header: 'Gutschein Datum' },
      { field: 'workflowstateName', header: 'Workflowstatus' },
      { field: 'registrationDate', header: 'Einschreibedatum' },
      { field: 'chir', header: 'Chir' },
      { field: 'im', header: 'IM' },
      { field: 'rh', header: 'Rh' },
      { field: 'rhAmbi', header: 'RhAmbi' },
      { field: 'n', header: 'N' },
      { field: 'g', header: 'G' },
      { field: 'gyn', header: 'Gyn' },
      { field: 'uro', header: 'Uro' },
      { field: 'ips', header: 'IPS' },
      { field: 'internshipCreated', header: 'Verhältnis erfasst' },
      { field: 'contractShortened', header: 'Vertrag verkürzt' },
      { field: 'contractExtended', header: 'Vertrag verlängert' },
      { field: 'invoiceAmount', header: 'Rechnungsbetrag' },
      { field: 'directEmployment', header: 'Direktanstellung' },
      { field: 'dateContractOk', header: 'Lehrauftrag Zielvereinbarung OK' },
      {
        field: 'assessmentSheetShippingShould',
        header: 'Versand Quali (Soll)',
      },
      { field: 'assessmentSheetShippingIs', header: 'Versand Quali (Ist)' },
      { field: 'assessmentSheetDeliveryOk', header: 'Abgabe Quali (Ok)' },
      {
        field: 'assessmentFormReceiptShould',
        header: 'Erhalt Beurteilungsbogen (Soll)',
      },
      {
        field: 'assessmentFormReceiptIs',
        header: 'Erhalt Beurteilungsbogen (Ist)',
      },
      { field: 'contractShould', header: 'Vertrag (Soll)' },
      { field: 'contractIs', header: 'Vertrag (Ist)' },
      {
        field: 'internshipConfirmationAndCompulsoryAttendanceShould',
        header: 'Praktikumsbestätigung und Anwesenheitspflicht (Soll)',
      },
      {
        field: 'internshipConfirmationAndCompulsoryAttendanceIs',
        header: 'Praktikumsbestätigung und Anwesenheitspflicht (Ist)',
      },
      { field: 'shippmentConfirmation', header: 'Versand Bestätigung' },
      { field: 'documentsIncomplete', header: 'Unterlagen nicht vollständig' },
      { field: 'timePlanningNote', header: 'Zeitplanung: Bemerkungen' },
      { field: 'absences', header: 'Absenzen (in Tagen)' },
      { field: 'netWorkingHours', header: 'Anzahl Stunden effektiv' },
      { field: 'formativeFinalAssessment', header: 'Formative Endbeurteilung' },
      { field: 'registered', header: 'Eingetragen (mit Datum)' },
      { field: 'workNote', header: 'Zeitplanung: Bemerkungen' },
      { field: 'selectedStudyStateText', header: 'Studiumstatus' },
      {
        field: 'targetAgreementConceptReceiptIs',
        header: 'Erhalt Entwurf Zielvereinbarung (Ist)',
      },
      {
        field: 'targetAgreementConceptReceiptShould',
        header: 'Erhalt Entwurf Zielvereinbarung (Soll)',
      },
      {
        field: 'targetAgreementFinalReceiptIs',
        header: 'Erhalt definitive Zielvereinbarung (Ist)',
      },
      {
        field: 'targetAgreementFinalReceiptShould',
        header: 'Erhalt definitive Zielvereinbarung (Soll)',
      },
      {
        field: 'targetSettingMeetingProposalsReceiptIs',
        header: 'Erhalt Vorschläge Zielvereinbarungsgespräch (Ist)',
      },
      {
        field: 'targetSettingMeetingProposalsReceiptShould',
        header: 'Erhalt Vorschläge Zielvereinbarungsgespräch (Soll)',
      },
      { field: 'interrupted', header: 'Unterbrochen' },
      { field: 'cancelled', header: 'Abgebrochen' },
    ];
  }

  filter(event: TableLazyLoadEvent) {
    //If search critera changes undo selection
    this.undoSelection();
  }

  loadInternships(event: TableLazyLoadEvent) {
    this.loading = true;

    this.store.dispatch(setInternshipsRows({ rows: event.rows }));
    this.rowsSelectionInternships$ = this.store.select(
      'rowsSelectionInternships'
    );

    setTimeout(() => {
      if (this.showArchive) {
        this.internshipService
          .getListWithArchive(event)
          .then((data) => {
            this.setInternshipsData(data);
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message:
                  'Die Ausbildungsverhältnisse konnten nicht geladen werden.',
              },
            });
          });
      } else {
        if (this.abgabeQualiAusstehend) {
          this.internshipService
            .getQualiNotSetList(event)
            .then((data) => {
              this.setInternshipsData(data);
              this.loading = false;
            })
            .catch((e) => {
              this.loading = false;
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message:
                    'Die Ausbildungsverhältnisse konnten nicht geladen werden.',
                },
              });
            });
        } else {
          this.internshipService
            .getList(event)
            .then((data) => {
              this.setInternshipsData(data);
              this.loading = false;
            })
            .catch((e) => {
              this.loading = false;
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message:
                    'Die Ausbildungsverhältnisse konnten nicht geladen werden.',
                },
              });
            });
        }
      }
    }, 1000);
  }

  setInternshipsData(data: any) {
    this.internships = data['records'];
    this.totalRecords = data['count'];
    this.internships = this.transformDates(this.internships);
  }

  transformDates(internships: Internship[]): Internship[] {
    return internships.map((internship) => {
      const registrationDate = this.datepipe.transform(
        internship.registrationDate,
        'dd.MM.yyyy'
      );
      const enddate = this.datepipe.transform(internship.enddate, 'dd.MM.yyyy');
      const startdate = this.datepipe.transform(
        internship.startdate,
        'dd.MM.yyyy'
      );
      const shouldDateAssessmentForm = this.datepipe.transform(
        internship.shouldDateAssessmentForm,
        'dd.MM.yyyy'
      );
      return {
        ...internship,
        registrationDate: registrationDate,
        enddate: enddate,
        startdate: startdate,
        shouldDateAssessmentForm: shouldDateAssessmentForm,
      };
    });
  }

  archive(id: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Soll dieses Arbeitsverhältnis archiviert werden?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      if (result) {
        this.internshipService.DeleteInternship(id).catch((e) => {
          this.loading = false;
          console.error(e);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message: 'Das Arbeitsverhältnis konnte nicht archiviert werden.',
            },
          });
        });
        this.visibleForRefresh = false;
        setTimeout(() => (this.visibleForRefresh = true), 2);
      }
    });
  }

  checkShowArchive() {
    this.selectAllChecked = false;
    this.selectAll();
    this.visibleForRefresh = false;
    setTimeout(() => (this.visibleForRefresh = true), 0);
  }

  selectInternship(internship: Internship) {}

  setZielgespraecheOk() {
    const ids = this.selectedInternships.map((internship) => internship.id);
    this.loading = true;
    this.internshipService
      .setMultipleTargetAgreementAssignment(ids)
      .then((data) => {
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Zielgespräche OK konnten nicht gesetzt werden.',
          },
        });
      });
  }

  selectAll() {
    if (!this.selectAllChecked) {
      this.selectedInternships = [];
    } else {
      if (this.totalRecords > 1500) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            message:
              'Sollen wirklich alle (mehr als 1500) Arbeitsverhältnisse ausgewählt werden? Das kann einige Minuten dauern.',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectAllRequest();
          } else {
            this.selectAllChecked = false;
          }
        });
      } else {
        this.selectAllRequest();
      }
    }
  }

  selectAllRequest(): void {
    this.loading = true;
    const event = this.table.createLazyLoadMetadata() as TableLazyLoadEvent;
    if (this.showArchive) {
      this.internshipService
        .getAllWithArchive(event)
        .then((data) => {
          this.setSelctedInternships(data);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message:
                'Es konnten nicht alle Ausbildungsverhältnisse inklusive den Archivierten ausgewählt werden.',
            },
          });
        });
    } else {
      this.internshipService
        .getAll(event)
        .then((data) => {
          this.setSelctedInternships(data);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message:
                'Es konnten nicht alle Ausbildungsverhältnisse ausgewählt werden.',
            },
          });
        });
    }
  }

  setSelctedInternships(data: any) {
    this.selectedInternships = data['records'];

    this.selectedInternships = this.transformDates(this.selectedInternships);
  }

  checkboxTable(tableCheckBox: TableCheckbox, event: any): void {
    //console.log(event);
    const isChecked = !!event.target.ariaChecked;
    if (event.button === 0 && event.shiftKey) {
      if (isChecked) {
        this.table.selectRange(event, tableCheckBox.index);
      } else {
        this.table.rangeRowIndex = tableCheckBox.index;
        this.table.clearSelectionRange(event);
        this.table.tableService.onSelectionChange();
      }
    }
    this.table.anchorRowIndex = tableCheckBox.index;
  }

  filterAbgabeQualiAusstehend() {
    if (this.abgabeQualiAusstehend) {
      this.showArchive = false;
    }
    this.checkShowArchive();
  }

  getInternshipReport(reportGuid: string, filename: string): void {
    const ids = this.selectedInternships.map((internship) =>
      Number(internship.id)
    );

    if (ids.length == 1) {
      if (filename == 'Personendatenblatt_') {
        filename =
          this.selectedInternships[0].organizationNameComp +
          '_' +
          this.selectedInternships[0].studentNameComp +
          '_' +
          this.selectedInternships[0].internshipType;
      } else {
        filename = filename + '_' + this.selectedInternships[0].studentNameComp;
      }
      //console.log(filename);
    }
    this.reportService.getInternshipReport(reportGuid, filename, ids);
  }

  getPersonendatenblatt(): void {
    this.getInternshipReport(
      '0291246f-8c03-4a1d-80ff-46aedde42761',
      'Personendatenblatt_'
    );
  }

  getEinschreibungPraktikum(): void {
    this.getInternshipReport(
      'f86ad95f-e41f-43c8-8e63-ab26abd8191f',
      'Einschreibung Praktikum_'
    );
  }

  getVorlageArbeitsundAusbildungsverhaeltnis(): void {
    this.getInternshipReport(
      '64a37459-e8e6-4f3f-8c86-56d7fe90c39f',
      'Vorlage Arbeits- und Ausbildungsverhältnis_'
    );
  }

  publizieren(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Sollen diese Arbeitsverhältnisse publiziert werden?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const workflowStepExecute: WorkflowStepExecute = {
          internships: this.selectedInternships,
          dataSourceObjectIdStep: 'C8912365-720D-41A9-8E41-FCFE195D60F9',
          reverse: false,
        };
        this.internshipService
          .executeWorkflowStep(workflowStepExecute)
          .then((data) => {
            window.location.reload();
            this.selectedInternships = data;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message:
                  'Das Arbeitsverhältnis konnte nicht publiziert werden.',
              },
            });
          });
      }
    });
  }

  inPlanungSetzten(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message:
          'Sollen diese Arbeitsverhältnisse zurück In Planung gesetzt werden?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const workflowStepExecute: WorkflowStepExecute = {
          internships: this.selectedInternships,
          dataSourceObjectIdStep: 'C8912365-720D-41A9-8E41-FCFE195D60F9',
          reverse: true,
        };
        this.internshipService
          .executeWorkflowStep(workflowStepExecute)
          .then((data) => {
            this.selectedInternships = data;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message:
                  'Das Arbeitsverhältnis konnte nicht zurück In Planung gesetzt werden.',
              },
            });
          });
      }
    });
  }

  async getInternships(idsForExport: string[], batchSize: number) {
    for (let i: number = 0; i < idsForExport.length; i = i + batchSize) {
      const start = i;
      let end;
      if (i >= idsForExport.length) {
        end = idsForExport.length - 1;
      } else {
        end = i + batchSize;
      }

      const concurrentReq = new Array(idsForExport.length % batchSize);

      const nrOfItemsInRequest = 100;
      for (let i: number = start; i < end; i = i + nrOfItemsInRequest) {
        const startlocal = i;
        let endLocal;
        if (i >= end) {
          endLocal = end - 1;
        } else {
          endLocal = i + nrOfItemsInRequest;
        }

        const idsForExportPage = idsForExport.slice(startlocal, endLocal);

        concurrentReq.push(
          this.internshipService
            .getInternshipsForExport(idsForExportPage)
            .then((data) => {
              this.internshipsForExport = this.internshipsForExport.concat(
                this.transformDates(data)
              );
            })
        );
      }

      await Promise.all(concurrentReq);
      //console.log(this.internshipsForExport);
    }
  }

  downloadInternships(): void {
    const showDialogTime = 1300;
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        message: 'Export gestartet',
      },
    });
    dialogRef.afterOpened().subscribe(() => {
      setTimeout(() => {
        dialogRef.close();
      }, showDialogTime);
    });
    this.internshipsForExport = [];

    const batchSize = 300;
    const idsForExport = this.selectedInternships.map((row) => row.id);

    this.getInternships(idsForExport, batchSize).then(
      (value) => {
        const csv = [
          this.studentCols
            .map((value) => value.header)
            .concat(this.practiceOrganizationCols.map((value) => value.header))
            .concat(this.trainerCols.map((value) => value.header))
            .concat(this.mentorCols.map((value) => value.header))
            .concat(this.internshipCols.map((value) => value.header))
            .concat([
              'Praktikumstyp-Id',
              'Praktikumstyp-Name',
              'Praktikumsperiode-Id',
              'Praktikumsperiode-Name',
              'Fachbereiche',
            ])
            .join(','),
          ...this.internshipsForExport.map((row) =>
            this.studentCols
              .map((entry) => {
                if (!row.student) {
                  row.student = new Student();
                }
                var found = Object.entries(row.student).find(
                  (attribute) => attribute[0] === entry.field
                );
                return found === undefined || found[1] === null
                  ? ''
                  : JSON.stringify(found[1]);
              })

              .concat(
                this.practiceOrganizationCols.map((entry) => {
                  if (!row.practiceOrganization) {
                    row.practiceOrganization = new PracticeOrganization();
                  }
                  var found = Object.entries(row.practiceOrganization).find(
                    (attribute) => attribute[0] === entry.field
                  );
                  return found === undefined || found[1] === null
                    ? ''
                    : JSON.stringify(found[1]);
                })
              )
              .concat(
                this.trainerCols.map((entry) => {
                  if (!row.trainer) {
                    row.trainer = new Trainer();
                  }
                  var found = Object.entries(row.trainer).find(
                    (attribute) => attribute[0] === entry.field
                  );
                  return found === undefined || found[1] === null
                    ? ''
                    : JSON.stringify(found[1]);
                })
              )
              .concat(
                this.mentorCols.map((entry) => {
                  if (!row.mentor) {
                    row.mentor = new Mentor();
                  }
                  var found = Object.entries(row.mentor).find(
                    (attribute) => attribute[0] === entry.field
                  );
                  return found === undefined || found[1] === null
                    ? ''
                    : JSON.stringify(found[1]);
                })
              )
              .concat(
                this.internshipCols.map((entry) => {
                  var found = Object.entries(row).find(
                    (attribute) => attribute[0] === entry.field
                  );
                  //console.log(found);
                  return found === undefined || found[1] === null
                    ? ''
                    : JSON.stringify(found[1]);
                })
              )
              .concat([
                row.type === null ? '' : row.type.id.toString(),
                row.type === null ? '' : row.type.name,
                row.internshipPeriod === null ? '' : row.internshipPeriod.id,
                row.internshipPeriod === null
                  ? ''
                  : row.internshipPeriod.identifier,
                row.subjectAreasNameComp === null
                  ? ''
                  : row.subjectAreasNameComp,
              ])
              .join(',')
          ),
        ].join('\r\n');

        const a = document.createElement('a');
        const blob = new Blob(['\ufeff', csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = 'Ausbildunsverhaeltnisse.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        const dialogRef2 = this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Export fertig',
          },
        });
        dialogRef2.afterOpened().subscribe(() => {
          setTimeout(() => {
            dialogRef2.close();
          }, showDialogTime);
        });
      },
      (reason) => {
        this.loading = false;
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Die Ausbildungsverhältnisse für den Export konnten nicht geladen werden.',
          },
        });
      }
    );
  }

  filterMAiAVersandLAQG1vornehmen(): void {
    this.filterMAiAVersandLAQGvornehmen(
      'MAiA, 1',
      this.mAiAVersandLAQG1vornehmen
    );
  }
  filterMAiAVersandLAQG2vornehmen(): void {
    this.filterMAiAVersandLAQGvornehmen(
      'MAiA, 2',
      this.mAiAVersandLAQG2vornehmen
    );
  }
  filterOffeneLehrauftraegeZG1(): void {
    this.filterOffeneLehrauftraegeZG('1', this.offeneLehrauftraegeZG1);
  }
  filterOffeneLehrauftraegeZG2(): void {
    this.filterOffeneLehrauftraegeZG('2', this.offeneLehrauftraegeZG2);
  }
  filterPraktikumVersandLAQG1vornehmen(): void {
    this.filterMAiAVersandLAQGvornehmen(
      '1. Praktikum',
      this.praktikumVersandLAQG1vornehmen
    );
  }
  filterPraktikumVersandLAQG2vornehmen(): void {
    this.filterMAiAVersandLAQGvornehmen(
      '2. Praktikum',
      this.praktikumVersandLAQG2vornehmen
    );
  }

  filterMAiAVersandLAQGvornehmen(type: string, flag: boolean): void {
    const today = new Date();
    const tomorrowDay = new Date(today.getTime() + 86400000); // + 1 day in ms

    if (!flag) {
      this.table.filters['selectedStudyState'] = {
        value: null,
        matchMode: 'contains',
        operator: 'and',
      };
      this.table.filters['assessmentSheetShippingShould'] = {
        value: null,
        matchMode: 'contains',
        operator: 'and',
      };
      this.table.filters['assessmentSheetAssignmentOk'] = {
        value: null,
        matchMode: 'contains',
        operator: 'or',
      };
      this.table.filters['internshipType'] = {
        value: null,
        matchMode: 'contains',
        operator: 'and',
      };
      const event = this.table.createLazyLoadMetadata() as TableLazyLoadEvent;
      this.loadInternships(event);
    } else {
      //Status = 1
      this.table.filters['selectedStudyState'] = {
        value: 'Aktiv', // '48B8804D-09B0-47BB-A9F2-2A0D49BC6817',
        matchMode: 'equals',
        operator: 'and',
      };
      //Versand Quali (Soll) <= jetzt
      this.table.filters['assessmentSheetShippingShould'] = {
        value: tomorrowDay.toUTCString(),
        matchMode: 'before',
        operator: 'and',
      };
      //Lehrauftrag Quali OK existiert nicht
      this.table.filters['assessmentSheetAssignmentOk'] = {
        value: null,
        matchMode: 'isNull',
        operator: 'and',
      };
      //Bezeichnung ähnlich wie %MAiA, 1%
      this.table.filters['internshipType'] = {
        value: type,
        matchMode: 'contains',
        operator: 'and',
      };
      const event = this.table.createLazyLoadMetadata() as TableLazyLoadEvent;
      this.loadInternships(event);
    }
  }

  filterOffeneLehrauftraegeZG(number: string, flag: boolean): void {
    const today = new Date();
    const tomorrowDay = new Date(today.getTime() + 86400000); // + 1 day in ms

    if (!flag) {
      this.table.filters['selectedStudyState'] = {
        value: null,
        matchMode: 'contains',
        operator: 'and',
      };
      this.table.filters['registrationDate'] = {
        value: null,
        matchMode: 'contains',
        operator: 'and',
      };
      this.table.filters['dateContractOk'] = {
        value: null,
        matchMode: 'contains',
        operator: 'or',
      };
      this.table.filters['internshipType'] = {
        value: null,
        matchMode: 'contains',
        operator: 'and',
      };
      const event = this.table.createLazyLoadMetadata() as TableLazyLoadEvent;
      this.loadInternships(event);
    } else {
      //Status = 1
      this.table.filters['selectedStudyState'] = {
        value: 'Aktiv', // '48B8804D-09B0-47BB-A9F2-2A0D49BC6817',
        matchMode: 'equals',
        operator: 'and',
      };
      //Einschreibedatum <= jetzt
      this.table.filters['registrationDate'] = {
        value: tomorrowDay.toUTCString(),
        matchMode: 'before',
        operator: 'and',
      };
      //Lehrauftrag Zielgespräch OK existiert nicht
      this.table.filters['dateContractOk'] = {
        value: null,
        matchMode: 'isNull',
        operator: 'and',
      };
      //Bezeichnung ähnlich wie %MAiA, 1%
      this.table.filters['internshipType'] = {
        value: number,
        matchMode: 'contains',
        operator: 'and',
      };
      const event = this.table.createLazyLoadMetadata() as TableLazyLoadEvent;
      this.loadInternships(event);
    }
  }

  setStudyStateFilter(state: string): void {
    this.table.filters['selectedStudyState'] = {
      value: state,
      matchMode: 'contains',
    };

    //console.log(this.table.filters);

    this.undoSelection();

    const event = this.table.createLazyLoadMetadata() as TableLazyLoadEvent;
    this.loadInternships(event);
  }

  undoSelection(): void {
    this.selectAllChecked = false;
    this.selectedInternships = [];
  }
}
