import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { InternshipperiodService } from '../../../service/internshipperiod.service';
import { Internshipperiod } from '../../../service/model/internshipperiod';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { setInternshipperiodsRows } from '../../../state/rows-selection.actions';
import { TableLazyLoadEvent } from 'primeng/table';

@Component({
  selector: 'app-internshipperiods',
  templateUrl: './internshipperiods.component.html',
  styleUrls: ['./internshipperiods.component.css'],
})
export class InternshipperiodsComponent implements OnInit {
  internshipperiods: Internshipperiod[];
  totalRecords: number;
  loading: boolean;
  isForSelection = false;
  rowsSelectionInternshipperiods$: Observable<number>;

  constructor(
    private internshipperiodService: InternshipperiodService,
    private store: Store<{ rowsSelectionInternshipperiods: number }>,
    private dialog: MatDialog
  ) {
    this.rowsSelectionInternshipperiods$ = store.select(
      'rowsSelectionInternshipperiods'
    );
    //console.log(this.rowsSelectionInternshipperiods$);
  }

  ngOnInit(): void {}

  loadInternshipperiods(event: TableLazyLoadEvent) {
    //console.log(event);
    this.loading = true;

    this.store.dispatch(setInternshipperiodsRows({ rows: event.rows }));
    this.rowsSelectionInternshipperiods$ = this.store.select(
      'rowsSelectionInternshipperiods'
    );

    setTimeout(() => {
      this.internshipperiodService
        .getList(event)
        .then((data) => {
          this.internshipperiods = data['records'];
          this.totalRecords = data['count'];

          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message: 'Die Praktikumsperioden konnten nicht geladen werden.',
            },
          });
        });
    }, 1000);
  }

  selectInternshipperiod(internshipperiod: Internshipperiod) {}
}
