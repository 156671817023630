import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Internship } from './model/internship';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { getErrorMessage } from '../shared-layout/shared.tools';
import { GridOptions } from './model/grid-options';
import { WorkflowStepExecute } from './model/workflow-step-execute';
import { Department } from './model/department';
import { RadioGroupEntry } from './model/radiogroupentry';
import { TableLazyLoadEvent } from 'primeng/table';

type InternshipIds = {
  ids: string[];
};

@Injectable({
  providedIn: 'root',
})
export class InternshipService {
  gridOptions: GridOptions;
  apiURL = environment.apiUrl;
  readonly extendedUserEndpoint: string = `${environment.apiUrl}/api/pt/internship`;

  constructor(private http: HttpClient) {
    this.gridOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }

  getDummyData() {
    return lastValueFrom(this.http.get<any>('../../assets/internships.json'))
      .then((res) => <Internship[]>res.data)
      .then((data) => {
        return data;
      });
  }

  getDeptInfo() {
    //console.log(event);
    return lastValueFrom(
      this.http
        .get<Department>(this.extendedUserEndpoint + '/getdeptinfo')
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Department und Infos konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getList(event: TableLazyLoadEvent) {
    //console.log(event);
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint, event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnisse konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getAll(event: TableLazyLoadEvent) {
    //console.log(event);
    return lastValueFrom(
      this.http
        .post<Internship[]>(this.extendedUserEndpoint + '/allpublished', event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnisse für Export konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getInternshipsForExport(ids: string[]) {
    const idsObject: InternshipIds = { ids: ids };
    //console.log(event);
    return lastValueFrom(
      this.http
        .post<Internship[]>(
          this.extendedUserEndpoint + '/listforexport',
          idsObject
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnisse für Export konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getListWithArchive(event: TableLazyLoadEvent) {
    //console.log(event);
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint + '/publishedwitharchive', event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnisse konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getQualiNotSetList(event: TableLazyLoadEvent) {
    //console.log(event);
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint + '/GetQualiNotSetList', event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnisse konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getAllWithArchive(event: TableLazyLoadEvent) {
    //console.log(event);
    return lastValueFrom(
      this.http
        .post<any>(
          this.extendedUserEndpoint + '/allpublishedwitharchive',
          event
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnisse mit Archivierten für Export konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getSingle(id: string) {
    return lastValueFrom(
      this.http
        .get<any>(this.extendedUserEndpoint + '/internship?id=' + id)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnis konnte nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  saveInternship(internship: Internship) {
    //console.log(JSON.stringify(internship));
    return lastValueFrom(
      this.http
        .post<Internship>(
          this.extendedUserEndpoint + '/new',
          JSON.stringify(internship),
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnisse konnten nicht gespeichert werden.'
                  )
                )
            )
          )
        )
    );
  }

  DeleteInternship(id: number) {
    //console.log(id);
    return lastValueFrom(
      this.http
        .delete<any>(
          this.extendedUserEndpoint + '/delete?id=' + id,
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnisse konnten nicht archiviert werden.'
                  )
                )
            )
          )
        )
    );
  }

  setMultipleTargetAgreementAssignment(ids: string[]) {
    //console.log(JSON.stringify(ids));
    return lastValueFrom(
      this.http
        .post<Internship[]>(
          this.extendedUserEndpoint + '/multipletargetagreementassignment',
          JSON.stringify(ids),
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Zielgespräche OK konnten nicht gespeichert werden.'
                  )
                )
            )
          )
        )
    );
  }

  calcStartEndDate(internship: Internship) {
    //console.log(JSON.stringify(internship));
    return lastValueFrom(
      this.http
        .post<Internship>(
          this.extendedUserEndpoint + '/calcstartenddate',
          JSON.stringify(internship),
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Start- und Enddatum konnten nicht berechnet werden.'
                  )
                )
            )
          )
        )
    );
  }

  executeWorkflowStep(workflowStepExecute: WorkflowStepExecute) {
    //console.log(JSON.stringify(internship));
    return lastValueFrom(
      this.http
        .post<Internship[]>(
          this.extendedUserEndpoint + '/executeworkflowstep',
          JSON.stringify(workflowStepExecute),
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Workflow-Schritt konnte nicht ausgeführt werden.'
                  )
                )
            )
          )
        )
    );
  }

  encodeParams(params: any): string {
    let body: string = '';

    for (let key in params) {
      if (body.length) {
        body += '&';
      }
      body += key + '=';
      body += encodeURIComponent(params[key]);
    }

    return body;
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  getGoalSetting(internshipId: string): Observable<Blob> {
    //console.log(internshipId);
    return this.http
      .get(this.extendedUserEndpoint + '/' + internshipId + '/goalsetting', {
        responseType: 'blob',
      })
      .pipe(
        catchError((error) =>
          throwError(
            () =>
              new Error(
                getErrorMessage(
                  error,
                  `Die Zielsetzung konnte nicht heruntergeladen werden.`
                )
              )
          )
        )
      );
  }

  getEvaluation(internshipId: string): Observable<Blob> {
    //console.log(internshipId);
    return this.http
      .get(this.extendedUserEndpoint + '/' + internshipId + '/evaluation', {
        responseType: 'blob',
      })
      .pipe(
        catchError((error) =>
          throwError(
            () =>
              new Error(
                getErrorMessage(
                  error,
                  `Die Bewertung konnte nicht heruntergeladen werden.`
                )
              )
          )
        )
      );
  }

  getPassedStates() {
    return lastValueFrom(
      this.http
        .get<RadioGroupEntry[]>(
          this.extendedUserEndpoint + '/getpassedstates',
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Passed states konnten nicht geladen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getCalcTimePlanning(internship: Internship) {
    return lastValueFrom(
      this.http
        .post<Internship>(
          this.extendedUserEndpoint + '/calctimeplanning',
          JSON.stringify(internship),
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Zeitplanung konnte nicht berechnet werden.'
                  )
                )
            )
          )
        )
    );
  }

  getStudyStates() {
    //console.log(event);
    return lastValueFrom(
      this.http
        .get<RadioGroupEntry[]>(this.extendedUserEndpoint + '/getstudystates')
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Liste Studiumstatus konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }
}
