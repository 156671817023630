import { Person } from './person';

export class Student extends Person {
  gender?: string;
  birthdate?: Date;
  hometown?: string;
  residencePermit?: string;
  matriculationNumber?: string;
  class?: string;
  ahv?: string;
  graduation?: string;
  professionalQualification?: string;
  activityBeforeStudy?: string;
  educationYear?: string;
  nationality?: string;
  civilStatus?: string;
  withCondition: boolean;
  conditionNotes?: string;
  mentoring?: string;
  isClassSpeaker: boolean;
}
