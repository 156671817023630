import { Person } from './person';

export class Trainer extends Person {
  organisation?: string;
  addon?: string;
  recognitionState: number;
  wbmVisited: boolean;
  formSentOn?: string;

  hospitation: boolean;
  launchDay?: string;
  wbkPracticalTraining?: string;
  trainingHealthcareSector?: string;
  otherTraining?: string;

  specialCase: boolean;
  specialCaseNotes?: string;

  oneTimeRecognitionDate?: string;
}
