import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Student } from '../../../service/model/student';
import { StudentService } from '../../../service/student.service';
import { UtilsService } from '../../../service/utils.service';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import {
  DeptFieldsStudentDetail,
  deptFieldsStudentDetailShowAll,
} from '../student-fields';
import { DatePipe } from '@angular/common';
import { InfoService } from '../../../service/info.service';
import { Internship } from '../../../service/model/internship';
import { DeptFieldsInternships } from '../../internship/internship-fields';

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.css'],
  providers: [StudentService],
})
export class StudentDetailComponent implements OnInit {
  notify: string;
  student: Student;
  loading: boolean;
  isUpdate: boolean;
  genders: string[] = ['m', 'w', 'd', 'unbekannt'];
  internships: Internship[];
  deptFields: DeptFieldsStudentDetail;
  deptFieldsInternship: DeptFieldsInternships;
  departmentId: BehaviorSubject<string>;

  constructor(
    private route: ActivatedRoute,
    private studentService: StudentService,
    private utilsService: UtilsService,
    private dialog: MatDialog,
    private router: Router,
    private infoService: InfoService,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(data, 'studentDetail');
      this.deptFieldsInternship = this.utilsService.getDeptFields(
        data,
        'internships'
      );
      // Nur für Testing alle Felder anzeigen
      //this.deptFields = deptFieldsStudentDetailShowAll;
    });

    this.route.params.subscribe((params) => {
      if (params['id'] != 0 && params['id'] != null) {
        Promise.all([
          this.studentService
            .getSingle(params['id'])
            .then((data) => {
              this.student = data;
              this.studentService
                .getInternships(params['id'])
                .then((data) => {
                  this.setInternshipsData(data);
                  this.isUpdate = true;
                  this.loading = false;
                })
                .catch((e) => {
                  this.loading = false;
                  console.error(e);
                  this.dialog.open(ErrorDialogComponent, {
                    data: {
                      message:
                        'Arbeitsverhältnisse konnten nicht geladen werden.',
                    },
                  });
                });
            })
            .catch((e) => {
              this.loading = false;
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message: 'Studierende:r konnte nicht geladen werden.',
                },
              });
            }),
        ]).then(() => (this.loading = false));
      } else {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Die Detailansicht kann nicht ohne ID eines Studierenden geöffnet werden.',
          },
        });
        this.router.navigate(['/students']);
      }
      this.loading = false;
    });
  }

  setInternshipsData(data: any) {
    this.internships = data;
    this.internships = this.internships.map((internship) => {
      const registrationDate = this.datepipe.transform(
        internship.registrationDate,
        'dd.MM.yyyy'
      );
      const enddate = this.datepipe.transform(internship.enddate, 'dd.MM.yyyy');
      const startdate = this.datepipe.transform(
        internship.startdate,
        'dd.MM.yyyy'
      );
      return {
        ...internship,
        registrationDate: registrationDate,
        enddate: enddate,
        startdate: startdate,
      };
    });
  }

  onSubmit() {
    this.loading = true;

    this.studentService
      .saveStudent(this.student)
      .then((data) => {
        this.loading = false;
        window.location.reload();
        if (data) {
          this.router.navigate(['student-detail', data.id]);
        }
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Studierende:r konnte nicht gespeichert werden.',
          },
        });
      });
  }
}
