<div class="row">
  <div class="col">
    <h2 class="mb-3">Praktikumsperioden</h2>

    <div *ngIf="!isForSelection" class="row" style="padding-bottom: 10px">
      <div class="col">
        <button
          type="button"
          [routerLink]="['/internshipperiod-detail']"
          ngbTooltip="Neue Ausbildungsverhältnis-Periode erstellen."
          pButton
          pRipple
          icon="pi pi-plus-circle mr-2"
          class="btn btn-primary float-right"
          tooltipPosition="bottom"
        >
          &nbsp;&nbsp;Neue Praktikumsperiode&nbsp;
        </button>
      </div>
    </div>

    <p-table
      styleClass="p-datatable-gridlines"
      [lazy]="true"
      (onLazyLoad)="loadInternshipperiods($event)"
      [totalRecords]="totalRecords"
      [value]="internshipperiods"
      sortMode="single"
      sortField="identifier"
      [sortOrder]="1"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [paginator]="true"
      [rows]="rowsSelectionInternshipperiods$ | async"
      [rowsPerPageOptions]="[10, 50, 100, 200, 500, 1000]"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 220px">
            <div pSortableColumn="identifier">
              Identifier
              <p-sortIcon field="identifier"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="identifier"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="startCalendarWeek">
              Start in KW
              <p-sortIcon field="startCalendarWeek"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="startCalendarWeek"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="durationInWeeks">
              Dauer in Wochen
              <p-sortIcon field="durationInWeeks"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="durationInWeeks"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 60px" alignFrozen="right" pFrozenColumn>
            &nbsp;
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-internshipperiod>
        <tr>
          <td style="min-width: 220px">{{ internshipperiod.identifier }}</td>
          <td style="min-width: 220px">
            {{ internshipperiod.startCalendarWeek }}
          </td>
          <td style="min-width: 220px">
            {{ internshipperiod.durationInWeeks }}
          </td>
          <td
            class="functionicons"
            style="min-width: 60px"
            alignFrozen="right"
            pFrozenColumn
          >
            <div *ngIf="isForSelection">
              <button
                class="btn"
                (click)="selectInternshipperiod(internshipperiod)"
                ngbTooltip="Auswählen"
              >
                <i class="fas fa-solid fa-plus bluecolor"></i>
              </button>
            </div>
            <div *ngIf="!isForSelection">
              <a
                [routerLink]="['/internshipperiod-detail', internshipperiod.id]"
                ngbTooltip="Bearbeiten"
                ><i class="fas fa-edit bluecolor"></i
              ></a>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
