import { Injectable } from '@angular/core';
import {
  deptFieldsInternshipDetailDefault,
  deptFieldsInternshipDetailIER,
  deptFieldsInternshipDetailIHB,
  deptFieldsInternshipDetailIPF,
  deptFieldsInternshipDetailIPH,
  deptFieldsInternshipDetailIPT,
  deptFieldsInternshipDetailN,
  deptFieldsInternshipDetailS,
  deptFieldsInternshipsDefault,
  deptFieldsInternshipsIER,
  deptFieldsInternshipsIHB,
  deptFieldsInternshipsIPF,
  deptFieldsInternshipsIPH,
  deptFieldsInternshipsIPT,
  deptFieldsInternshipsN,
  deptFieldsInternshipsS,
} from '../layout/internship/internship-fields';
import {
  deptFieldsStudentsS,
  deptFieldsStudentDetailS,
  deptFieldsStudentDetailDefault,
  deptFieldsStudentDetailIER,
  deptFieldsStudentDetailIHB,
  deptFieldsStudentDetailIPF,
  deptFieldsStudentDetailIPH,
  deptFieldsStudentDetailIPT,
  deptFieldsStudentDetailN,
  deptFieldsStudentsDefault,
  deptFieldsStudentsIER,
  deptFieldsStudentsIHB,
  deptFieldsStudentsIPF,
  deptFieldsStudentsIPH,
  deptFieldsStudentsIPT,
  deptFieldsStudentsN,
} from '../layout/student/student-fields';
import {
  deptFieldsMentorDetailDefault,
  deptFieldsMentorDetailIPH,
  deptFieldsMentorDetailS,
  deptFieldsMentorsDefault,
  deptFieldsMentorsIER,
  deptFieldsMentorsIPH,
  deptFieldsMentorsS,
} from '../layout/mentor/mentor-fields';
import {
  deptFieldsTrainerDetailDefault,
  deptFieldsTrainerDetailIER,
  deptFieldsTrainerDetailIPH,
  deptFieldsTrainerDetailIPT,
  deptFieldsTrainerDetailS,
  deptFieldsTrainersDefault,
  deptFieldsTrainersIER,
  deptFieldsTrainersIPH,
  deptFieldsTrainersIPT,
  deptFieldsTrainersS,
} from '../layout/trainer/trainer-fields';
import {
  deptFieldsPracticeOrganizationDetailDefault,
  deptFieldsPracticeOrganizationDetailIER,
  deptFieldsPracticeOrganizationDetailIHB,
  deptFieldsPracticeOrganizationDetailIPF,
  deptFieldsPracticeOrganizationDetailIPH,
  deptFieldsPracticeOrganizationDetailIPT,
  deptFieldsPracticeOrganizationDetailS,
  deptFieldsPracticeOrganizationsDefault,
  deptFieldsPracticeOrganizationsIER,
  deptFieldsPracticeOrganizationsS,
} from '../layout/practiceorganization/practiceorganisation-fields';
import {
  deptFieldsInternshiptypeDetailShowAll,
  deptFieldsInternshiptypeDetailIHB,
  deptFieldsInternshiptypeDetailIPH,
  deptFieldsInternshiptypeDetailIPT,
  deptFieldsInternshiptypeDetailS,
  deptFieldsInternshiptypesDefault,
  deptFieldsInternshiptypesS,
  deptFieldsInternshiptypeDetailDefault,
} from '../layout/internshiptype/internshiptype-fields';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}
  getDeptFields(id: string, type: string): any {
    switch (id) {
      // Dept S
      case '689119E5-AD45-4E84-9AB3-43C792725A35':
        return this.getDeptS(type);
      // Dept G Ergo
      case 'DA35C0A6-D72C-492C-8C20-EF74405F73B4':
        return this.getDeptGErgo(type);
      // Dept N
      case 'F3E4D669-F989-4397-B86C-C672F2FEABC8':
        return this.getDeptN(type);
      // Dept G Physio
      case '20F0BE6D-E03F-49AC-8DC6-9BBE2C0F80D3':
        return this.getDeptGPhysio(type);
      //Dept G Hebammen
      case '939118A4-C04B-4A97-98B7-D0369393AD62':
        return this.getDeptGHebammen(type);
      //Dept G Pflege
      case 'B6A70B41-3EFC-47A4-9373-5FC066A006A5':
        return this.getDeptGPflege(type);
      //Dept G IPH
      case 'A6AFD31C-DECE-4AAF-A4B0-0D409DA716AE':
        return this.getDeptGIPH(type);
      default:
        //console.log('default');
        return this.getDeptDefault(type);
    }
  }

  getDeptS(type: string): any {
    switch (type) {
      case 'internships':
        return deptFieldsInternshipsS;
      case 'internshipDetail':
        return deptFieldsInternshipDetailS;
      case 'mentors':
        return deptFieldsMentorsS;
      case 'mentorDetail':
        return deptFieldsMentorDetailS;
      case 'practiceOrganizations':
        return deptFieldsPracticeOrganizationsS;
      case 'practiceOrganizationDetail':
        return deptFieldsPracticeOrganizationDetailS;
      case 'students':
        return deptFieldsStudentsS;
      case 'studentDetail':
        return deptFieldsStudentDetailS;
      case 'trainers':
        return deptFieldsTrainersS;
      case 'trainerDetail':
        return deptFieldsTrainerDetailS;
      case 'internshiptypes':
        return deptFieldsInternshiptypesS;
      case 'internshiptypeDetail':
        return deptFieldsInternshiptypeDetailS;
    }
  }

  getDeptGErgo(type: string): any {
    switch (type) {
      case 'internships':
        return deptFieldsInternshipsIER;
      case 'internshipDetail':
        return deptFieldsInternshipDetailIER;
      case 'mentors':
        return deptFieldsMentorsIER;
      case 'mentorDetail':
        return deptFieldsMentorDetailDefault;
      case 'practiceOrganizations':
        return deptFieldsPracticeOrganizationsIER;
      case 'practiceOrganizationDetail':
        return deptFieldsPracticeOrganizationDetailIER;
      case 'students':
        return deptFieldsStudentsIER;
      case 'studentDetail':
        return deptFieldsStudentDetailIER;
      case 'trainers':
        return deptFieldsTrainersIER;
      case 'trainerDetail':
        return deptFieldsTrainerDetailIER;
      case 'internshiptypes':
        return deptFieldsInternshiptypesDefault;
      case 'internshiptypeDetail':
        return deptFieldsInternshiptypeDetailDefault;
    }
  }

  getDeptN(type: string): any {
    switch (type) {
      case 'internships':
        return deptFieldsInternshipsN;
      case 'internshipDetail':
        return deptFieldsInternshipDetailN;
      case 'mentors':
        return deptFieldsMentorsDefault;
      case 'mentorDetail':
        return deptFieldsMentorDetailDefault;
      case 'practiceOrganizations':
        return deptFieldsPracticeOrganizationsDefault;
      case 'practiceOrganizationDetail':
        return deptFieldsPracticeOrganizationDetailDefault;
      case 'students':
        return deptFieldsStudentsN;
      case 'studentDetail':
        return deptFieldsStudentDetailN;
      case 'trainers':
        return deptFieldsTrainersDefault;
      case 'trainerDetail':
        return deptFieldsTrainerDetailDefault;
      case 'internshiptypes':
        return deptFieldsInternshiptypesDefault;
      case 'internshiptypeDetail':
        return deptFieldsInternshiptypeDetailDefault;
    }
  }

  getDeptGPhysio(type: string): any {
    switch (type) {
      case 'internships':
        return deptFieldsInternshipsIPT;
      case 'internshipDetail':
        return deptFieldsInternshipDetailIPT;
      case 'mentors':
        return deptFieldsMentorsDefault;
      case 'mentorDetail':
        return deptFieldsMentorDetailDefault;
      case 'practiceOrganizations':
        return deptFieldsPracticeOrganizationsDefault;
      case 'practiceOrganizationDetail':
        return deptFieldsPracticeOrganizationDetailIPT;
      case 'students':
        return deptFieldsStudentsIPT;
      case 'studentDetail':
        return deptFieldsStudentDetailIPT;
      case 'trainers':
        return deptFieldsTrainersIPT;
      case 'trainerDetail':
        return deptFieldsTrainerDetailIPT;
      case 'internshiptypes':
        return deptFieldsInternshiptypesDefault;
      case 'internshiptypeDetail':
        return deptFieldsInternshiptypeDetailIPT;
    }
  }

  getDeptGHebammen(type: string): any {
    switch (type) {
      case 'internships':
        return deptFieldsInternshipsIHB;
      case 'internshipDetail':
        return deptFieldsInternshipDetailIHB;
      case 'mentors':
        return deptFieldsMentorsDefault;
      case 'mentorDetail':
        return deptFieldsMentorDetailDefault;
      case 'practiceOrganizations':
        return deptFieldsPracticeOrganizationsDefault;
      case 'practiceOrganizationDetail':
        return deptFieldsPracticeOrganizationDetailIHB;
      case 'students':
        return deptFieldsStudentsIHB;
      case 'studentDetail':
        return deptFieldsStudentDetailIHB;
      case 'trainers':
        return deptFieldsTrainersDefault;
      case 'trainerDetail':
        return deptFieldsTrainerDetailDefault;
      case 'internshiptypes':
        return deptFieldsInternshiptypesDefault;
      case 'internshiptypeDetail':
        return deptFieldsInternshiptypeDetailIHB;
    }
  }

  getDeptGPflege(type: string): any {
    switch (type) {
      case 'internships':
        return deptFieldsInternshipsIPF;
      case 'internshipDetail':
        return deptFieldsInternshipDetailIPF;
      case 'mentors':
        return deptFieldsMentorsDefault;
      case 'mentorDetail':
        return deptFieldsMentorDetailDefault;
      case 'practiceOrganizations':
        return deptFieldsPracticeOrganizationsDefault;
      case 'practiceOrganizationDetail':
        return deptFieldsPracticeOrganizationDetailIPF;
      case 'students':
        return deptFieldsStudentsIPF;
      case 'studentDetail':
        return deptFieldsStudentDetailIPF;
      case 'trainers':
        return deptFieldsTrainersDefault;
      case 'trainerDetail':
        return deptFieldsTrainerDetailDefault;
      case 'internshiptypes':
        return deptFieldsInternshiptypesDefault;
      case 'internshiptypeDetail':
        return deptFieldsInternshiptypeDetailDefault;
    }
  }

  getDeptGIPH(type: string): any {
    switch (type) {
      case 'internships':
        return deptFieldsInternshipsIPH;
      case 'internshipDetail':
        return deptFieldsInternshipDetailIPH;
      case 'mentors':
        return deptFieldsMentorsIPH;
      case 'mentorDetail':
        return deptFieldsMentorDetailIPH;
      case 'practiceOrganizations':
        return deptFieldsPracticeOrganizationsDefault;
      case 'practiceOrganizationDetail':
        return deptFieldsPracticeOrganizationDetailIPH;
      case 'students':
        return deptFieldsStudentsIPH;
      case 'studentDetail':
        return deptFieldsStudentDetailIPH;
      case 'trainers':
        return deptFieldsTrainersIPH;
      case 'trainerDetail':
        return deptFieldsTrainerDetailIPH;
      case 'internshiptypes':
        return deptFieldsInternshiptypesDefault;
      case 'internshiptypeDetail':
        return deptFieldsInternshiptypeDetailIPH;
    }
  }

  getDeptDefault(type: string): any {
    switch (type) {
      case 'internships':
        return deptFieldsInternshipsDefault;
      case 'internshipDetail':
        return deptFieldsInternshipDetailDefault;
      case 'mentors':
        return deptFieldsMentorsDefault;
      case 'mentorDetail':
        return deptFieldsMentorDetailDefault;
      case 'practiceOrganizations':
        return deptFieldsPracticeOrganizationsDefault;
      case 'practiceOrganizationDetail':
        return deptFieldsPracticeOrganizationDetailDefault;
      case 'students':
        return deptFieldsStudentsDefault;
      case 'studentDetail':
        return deptFieldsStudentDetailDefault;
      case 'trainers':
        return deptFieldsTrainersDefault;
      case 'trainerDetail':
        return deptFieldsTrainerDetailDefault;
      case 'internshiptypes':
        return deptFieldsInternshiptypesDefault;
      case 'internshiptypeDetail':
        return deptFieldsInternshiptypeDetailDefault;
    }
  }
}
