<div class="row">
  <div class="col">
    <div>
      <h2 class="mb-3">
        <a
          routerLink="/internshipperiods"
          routerLinkActive="active"
          ngbTooltip="zur Übersicht"
          ><i class="pi pi-angle-left bluecolor"></i></a
        >&nbsp;Praktikumsperiode<span *ngIf="!isUpdate"> erfassen</span>
      </h2>

      <form *ngIf="internshipperiod">
        <div class="form-group">
          <div class="row">
            <div class="col-sm">
              <label for="identifier">Identifikator:</label>
              <input
                class="form-control"
                id="identifier"
                name="identifier"
                [(ngModel)]="this.internshipperiod.identifier"
                [readonly]="false"
                [disabled]="false"
                size="40"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    (internshipperiod.identifier === undefined ||
                      internshipperiod.identifier === '')
                }"
              />
            </div>
            <div class="col-sm">
              <label for="startCalendarWeek">Start in KW:</label>
              <input
                class="form-control"
                id="startCalendarWeek"
                name="startCalendarWeek"
                [(ngModel)]="internshipperiod.startCalendarWeek"
                [readonly]="false"
                [disabled]="false"
                type="number"
                size="40"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    internshipperiod.startCalendarWeek === undefined
                }"
              />
            </div>
            <div class="col-sm">
              <label for="durationInWeeks">Dauer in Wochen:</label>
              <input
                class="form-control"
                id="durationInWeeks"
                name="durationInWeeks"
                type="number"
                [(ngModel)]="internshipperiod.durationInWeeks"
                [readonly]="false"
                [disabled]="false"
                size="40"
                [ngClass]="{
                  'is-invalid':
                    submitted && internshipperiod.durationInWeeks === undefined
                }"
              />
            </div>
          </div>

          <button
            *ngIf="isAdminBehaviorSubject | async"
            class="btn btn-primary float-right"
            (click)="onSubmit()"
          >
            Speichern
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
