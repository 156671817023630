import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentService } from '../../../service/student.service';
import { Student, Student as Students } from '../../../service/model/student';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { setStudentsRows } from '../../../state/rows-selection.actions';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { Table, TableCheckbox, TableLazyLoadEvent } from 'primeng/table';
import { DeptFieldsStudents } from '../student-fields';
import { InfoService } from '../../../service/info.service';
import { UtilsService } from '../../../service/utils.service';

@Component({
  selector: 'app-students',
  templateUrl: '../students/students.component.html',
  styleUrls: ['../students/students.component.css'],
  providers: [StudentService],
})
export class StudentSelectDialogComponent implements OnInit {
  students: Students[];
  loading: boolean;
  totalRecords: number;
  isForSelection = true;
  rowsSelectionStudents$: Observable<number>;
  selectedStudents: Students[];
  @ViewChild('dt') table: Table;
  selectAllChecked = false;
  cols: any[];
  deptFields: DeptFieldsStudents;
  departmentId: BehaviorSubject<string>;
  visibleForRefresh: boolean = true;
  showInactive = false;

  constructor(
    private studentService: StudentService,
    private dialogRef: MatDialogRef<StudentSelectDialogComponent>,
    private store: Store<{ rowsSelectionStudents: number }>,
    private dialog: MatDialog,
    private infoService: InfoService,
    private utilsService: UtilsService
  ) {
    this.rowsSelectionStudents$ = store.select('rowsSelectionStudents');
    //console.log(this.rowsSelectionStudents$);
  }

  ngOnInit() {
    this.loading = true;

    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(data, 'students');
      // Nur für Testing alle Felder anzeigen
      //this.deptFields = deptFieldsStudentsShowAll;
    });
  }

  filter(event: TableLazyLoadEvent) {}

  loadStudents(event: TableLazyLoadEvent) {
    this.loading = true;

    this.store.dispatch(setStudentsRows({ rows: event.rows }));
    this.rowsSelectionStudents$ = this.store.select('rowsSelectionStudents');

    //console.log(event);

    setTimeout(() => {
      if (this.showInactive) {
        this.studentService
          .getListWithInactive(event)
          .then((data) => {
            this.students = data['records'];
            this.totalRecords = data['count'];

            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Studierende konnten nicht geladen werden.',
              },
            });
          });
      } else {
        this.studentService
          .getList(event)
          .then((data) => {
            this.students = data['records'];
            this.totalRecords = data['count'];

            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Studierende konnten nicht geladen werden.',
              },
            });
          });
      }
    }, 1000);
  }

  selectStudent(student: Student) {
    this.dialogRef.close(student);
  }

  checkboxTable(tableCheckBox: TableCheckbox, event: any): void {}

  selectAll(): void {}

  downloadExport(): void {}

  checkShowInactive() {
    this.selectAllChecked = false;
    this.selectAll();
    this.visibleForRefresh = false;
    setTimeout(() => (this.visibleForRefresh = true), 0);
  }
}
