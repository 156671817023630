export class DeptFieldsPracticeOrganizations {
  plz: boolean;
  telefon: boolean;
}
export const deptFieldsPracticeOrganizationsS: DeptFieldsPracticeOrganizations =
  {
    plz: true,
    telefon: false,
  };

export const deptFieldsPracticeOrganizationsIER: DeptFieldsPracticeOrganizations =
  {
    plz: true,
    telefon: true,
  };

export const deptFieldsPracticeOrganizationsDefault: DeptFieldsPracticeOrganizations =
  {
    plz: true,
    telefon: true,
  };

export class DeptFieldsPracticeOrganizationDetail {
  sozialeArbeitFields: boolean;
  iphFields: boolean;
  direktanstellung: boolean;
  rückmeldungenZuStelle: boolean;
  aquivalenteAnerkennung: boolean;
  physioFields: boolean;
  hebammeFields: boolean;
  ergotherapieFields: boolean;
  pflegeFields: boolean;
  kontaktperson: boolean;
  leitungErgo: boolean;
  ausbildungsverantwortliche: boolean;
  praxisausbildnerTabs: boolean;
  zustandigFurVertrag: boolean;
  zustandigFurPersonalienblatt: boolean;
  zustandigFurLohnfragen: boolean;
  leitendeHebamme: boolean;
  leitungWochenbett: boolean;
  pflegedienstleitung: boolean;
  fachverantwortlicheFH: boolean;
  kontaktpersonAdministration: boolean;
  pflegedirektion: boolean;
  praktikumsplatzeGeriatrie: boolean;
  praktikumsplatzeHandOrtho: boolean;
  praktikumsplatzeWochenbettabteilung: boolean;
  bemerkungen: boolean;
  internebemerkungen: boolean;
  equivalentRecognitionInfo: boolean;
  dateTrainingConcept: boolean;
  timeofRecognition: boolean;
}
export const deptFieldsPracticeOrganizationDetailIER: DeptFieldsPracticeOrganizationDetail =
  {
    sozialeArbeitFields: false,
    iphFields: false,
    direktanstellung: true,
    rückmeldungenZuStelle: true,
    aquivalenteAnerkennung: false,
    physioFields: false,
    hebammeFields: false,
    ergotherapieFields: true,
    pflegeFields: false,
    kontaktperson: true,
    leitungErgo: true,
    ausbildungsverantwortliche: true,
    praxisausbildnerTabs: true,
    zustandigFurVertrag: true,
    zustandigFurPersonalienblatt: true,
    zustandigFurLohnfragen: true,
    leitendeHebamme: false,
    leitungWochenbett: false,
    pflegedienstleitung: false,
    fachverantwortlicheFH: false,
    kontaktpersonAdministration: false,
    pflegedirektion: false,
    praktikumsplatzeGeriatrie: false,
    praktikumsplatzeHandOrtho: false,
    praktikumsplatzeWochenbettabteilung: false,
    bemerkungen: true,
    internebemerkungen: false,
    equivalentRecognitionInfo: false,
    dateTrainingConcept: false,
    timeofRecognition: false,
  };
export const deptFieldsPracticeOrganizationDetailIHB: DeptFieldsPracticeOrganizationDetail =
  {
    sozialeArbeitFields: false,
    iphFields: false,
    direktanstellung: false,
    rückmeldungenZuStelle: false,
    aquivalenteAnerkennung: false,
    physioFields: false,
    hebammeFields: true,
    ergotherapieFields: false,
    pflegeFields: false,
    kontaktperson: false,
    leitungErgo: false,
    ausbildungsverantwortliche: true,
    praxisausbildnerTabs: true,
    zustandigFurVertrag: false,
    zustandigFurPersonalienblatt: false,
    zustandigFurLohnfragen: false,
    leitendeHebamme: false,
    leitungWochenbett: false,
    pflegedienstleitung: false,
    fachverantwortlicheFH: false,
    kontaktpersonAdministration: false,
    pflegedirektion: false,
    praktikumsplatzeGeriatrie: false,
    praktikumsplatzeHandOrtho: false,
    praktikumsplatzeWochenbettabteilung: false,
    bemerkungen: true,
    internebemerkungen: false,
    equivalentRecognitionInfo: false,
    dateTrainingConcept: false,
    timeofRecognition: false,
  };
export const deptFieldsPracticeOrganizationDetailIPF: DeptFieldsPracticeOrganizationDetail =
  {
    sozialeArbeitFields: false,
    iphFields: false,
    direktanstellung: false,
    rückmeldungenZuStelle: false,
    aquivalenteAnerkennung: false,
    physioFields: false,
    hebammeFields: false,
    ergotherapieFields: false,
    pflegeFields: true,
    kontaktperson: false,
    leitungErgo: false,
    ausbildungsverantwortliche: true,
    praxisausbildnerTabs: false,
    zustandigFurVertrag: false,
    zustandigFurPersonalienblatt: false,
    zustandigFurLohnfragen: false,
    leitendeHebamme: false,
    leitungWochenbett: false,
    pflegedienstleitung: false,
    fachverantwortlicheFH: true,
    kontaktpersonAdministration: false,
    pflegedirektion: true,
    praktikumsplatzeGeriatrie: false,
    praktikumsplatzeHandOrtho: false,
    praktikumsplatzeWochenbettabteilung: false,
    bemerkungen: true,
    internebemerkungen: false,
    equivalentRecognitionInfo: false,
    dateTrainingConcept: false,
    timeofRecognition: false,
  };
export const deptFieldsPracticeOrganizationDetailIPT: DeptFieldsPracticeOrganizationDetail =
  {
    sozialeArbeitFields: false,
    iphFields: false,
    direktanstellung: false,
    rückmeldungenZuStelle: false,
    aquivalenteAnerkennung: false,
    physioFields: true,
    hebammeFields: false,
    ergotherapieFields: false,
    pflegeFields: false,
    kontaktperson: false,
    leitungErgo: false,
    ausbildungsverantwortliche: false,
    praxisausbildnerTabs: false,
    zustandigFurVertrag: false,
    zustandigFurPersonalienblatt: false,
    zustandigFurLohnfragen: false,
    leitendeHebamme: false,
    leitungWochenbett: false,
    pflegedienstleitung: false,
    fachverantwortlicheFH: false,
    kontaktpersonAdministration: false,
    pflegedirektion: false,
    praktikumsplatzeGeriatrie: false,
    praktikumsplatzeHandOrtho: false,
    praktikumsplatzeWochenbettabteilung: false,
    bemerkungen: true,
    internebemerkungen: false,
    equivalentRecognitionInfo: false,
    dateTrainingConcept: false,
    timeofRecognition: false,
  };
export const deptFieldsPracticeOrganizationDetailS: DeptFieldsPracticeOrganizationDetail =
  {
    sozialeArbeitFields: true,
    iphFields: false,
    direktanstellung: false,
    rückmeldungenZuStelle: false,
    aquivalenteAnerkennung: true,
    physioFields: false,
    hebammeFields: false,
    ergotherapieFields: false,
    pflegeFields: false,
    kontaktperson: false,
    leitungErgo: false,
    ausbildungsverantwortliche: false,
    praxisausbildnerTabs: true,
    zustandigFurVertrag: false,
    zustandigFurPersonalienblatt: false,
    zustandigFurLohnfragen: false,
    leitendeHebamme: false,
    leitungWochenbett: false,
    pflegedienstleitung: false,
    fachverantwortlicheFH: false,
    kontaktpersonAdministration: false,
    pflegedirektion: false,
    praktikumsplatzeGeriatrie: false,
    praktikumsplatzeHandOrtho: false,
    praktikumsplatzeWochenbettabteilung: false,
    bemerkungen: false,
    internebemerkungen: true,
    equivalentRecognitionInfo: true,
    dateTrainingConcept: true,
    timeofRecognition: true,
  };
export const deptFieldsPracticeOrganizationDetailIPH: DeptFieldsPracticeOrganizationDetail =
  {
    sozialeArbeitFields: false,
    iphFields: true,
    direktanstellung: false,
    rückmeldungenZuStelle: false,
    aquivalenteAnerkennung: false,
    physioFields: false,
    hebammeFields: false,
    ergotherapieFields: false,
    pflegeFields: false,
    kontaktperson: true,
    leitungErgo: false,
    ausbildungsverantwortliche: false,
    praxisausbildnerTabs: true,
    zustandigFurVertrag: false,
    zustandigFurPersonalienblatt: false,
    zustandigFurLohnfragen: false,
    leitendeHebamme: false,
    leitungWochenbett: false,
    pflegedienstleitung: false,
    fachverantwortlicheFH: false,
    kontaktpersonAdministration: false,
    pflegedirektion: false,
    praktikumsplatzeGeriatrie: false,
    praktikumsplatzeHandOrtho: false,
    praktikumsplatzeWochenbettabteilung: false,
    bemerkungen: true,
    internebemerkungen: false,
    equivalentRecognitionInfo: false,
    dateTrainingConcept: false,
    timeofRecognition: false,
  };
export const deptFieldsPracticeOrganizationDetailDefault: DeptFieldsPracticeOrganizationDetail =
  {
    sozialeArbeitFields: false,
    iphFields: false,
    direktanstellung: false,
    rückmeldungenZuStelle: false,
    aquivalenteAnerkennung: false,
    physioFields: false,
    hebammeFields: false,
    ergotherapieFields: false,
    pflegeFields: false,
    kontaktperson: false,
    leitungErgo: false,
    ausbildungsverantwortliche: false,
    praxisausbildnerTabs: false,
    zustandigFurVertrag: false,
    zustandigFurPersonalienblatt: false,
    zustandigFurLohnfragen: false,
    leitendeHebamme: false,
    leitungWochenbett: false,
    pflegedienstleitung: false,
    fachverantwortlicheFH: false,
    kontaktpersonAdministration: false,
    pflegedirektion: false,
    praktikumsplatzeGeriatrie: false,
    praktikumsplatzeHandOrtho: false,
    praktikumsplatzeWochenbettabteilung: false,
    bemerkungen: false,
    internebemerkungen: false,
    equivalentRecognitionInfo: false,
    dateTrainingConcept: false,
    timeofRecognition: false,
  };
export const deptFieldsPracticeOrganizationDetailShowAll: DeptFieldsPracticeOrganizationDetail =
  {
    sozialeArbeitFields: true,
    iphFields: true,
    direktanstellung: true,
    rückmeldungenZuStelle: true,
    aquivalenteAnerkennung: true,
    physioFields: true,
    hebammeFields: true,
    ergotherapieFields: true,
    pflegeFields: true,
    kontaktperson: true,
    leitungErgo: true,
    ausbildungsverantwortliche: true,
    praxisausbildnerTabs: true,
    zustandigFurVertrag: true,
    zustandigFurPersonalienblatt: true,
    zustandigFurLohnfragen: true,
    leitendeHebamme: true,
    leitungWochenbett: true,
    pflegedienstleitung: true,
    fachverantwortlicheFH: true,
    kontaktpersonAdministration: true,
    pflegedirektion: true,
    praktikumsplatzeGeriatrie: true,
    praktikumsplatzeHandOrtho: true,
    praktikumsplatzeWochenbettabteilung: true,
    bemerkungen: true,
    internebemerkungen: true,
    equivalentRecognitionInfo: true,
    dateTrainingConcept: false,
    timeofRecognition: false,
  };
