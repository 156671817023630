import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { setPracticeOrganizationsRows } from '../../../state/rows-selection.actions';
import { PracticeOrganization } from '../../../service/model/practiceorganization';
import { PracticeOrganizationService } from '../../../service/practiceorganization.service';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { Table, TableCheckbox, TableLazyLoadEvent } from 'primeng/table';
import { InfoService } from '../../../service/info.service';
import { UtilsService } from '../../../service/utils.service';
import { DeptFieldsPracticeOrganizations } from '../practiceorganisation-fields';

@Component({
  selector: 'app-practice-organizations',
  templateUrl:
    '../practice-organizations/practice-organizations.component.html',
  styleUrls: ['../practice-organizations/practice-organizations.component.css'],
  providers: [PracticeOrganizationService],
})
export class PracticeOrganizationsSelectComponent implements OnInit {
  PracticeOrganizations: PracticeOrganization[];
  selectedPracticeOrganizations: PracticeOrganization[];
  totalRecords: number;
  loading: boolean;
  isForSelection = true;
  rowsSelectionPracticeOrganizations$: Observable<number>;
  @ViewChild('dt') table: Table;
  selectAllChecked = false;
  cols: any[];
  filename: string;
  departmentId: BehaviorSubject<string>;
  deptFields: DeptFieldsPracticeOrganizations;
  visibleForRefresh: boolean = true;
  showInactive = false;

  constructor(
    private practiceOrganizationService: PracticeOrganizationService,
    private dialogRef: MatDialogRef<PracticeOrganizationsSelectComponent>,
    private store: Store<{ rowsSelectionPracticeOrganizations: number }>,
    private dialog: MatDialog,
    private utilsService: UtilsService,
    private infoService: InfoService
  ) {
    this.rowsSelectionPracticeOrganizations$ = store.select(
      'rowsSelectionPracticeOrganizations'
    );
    //console.log(this.rowsSelectionPracticeOrganizations$);
  }

  ngOnInit(): void {
    this.loading = true;

    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(
        data,
        'practiceOrganizations'
      );
      // Nur für Testing alle Felder anzeigen
      //this.deptFields = deptFieldsPracticeOrganizationsShowAll;
    });
  }

  filter(event: TableLazyLoadEvent) {}

  loadOrganizations(event: TableLazyLoadEvent) {
    this.loading = true;

    //console.log(event.rows)
    this.store.dispatch(setPracticeOrganizationsRows({ rows: event.rows }));
    this.rowsSelectionPracticeOrganizations$ = this.store.select(
      'rowsSelectionPracticeOrganizations'
    );

    //console.log(event);
    setTimeout(() => {
      if (this.showInactive) {
        this.practiceOrganizationService
          .getListWithInactive(event)
          .then((data) => {
            this.PracticeOrganizations = data['records'];
            this.totalRecords = data['count'];

            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Praxisorganisationen konnten nicht geladen werden.',
              },
            });
          });
      } else {
        this.practiceOrganizationService
          .getList(event)
          .then((data) => {
            this.PracticeOrganizations = data['records'];
            this.totalRecords = data['count'];

            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Praxisorganisationen konnten nicht geladen werden.',
              },
            });
          });
      }
    }, 1000);
  }

  selectPracticeOrganization(practiceOrganization: PracticeOrganization) {
    this.dialogRef.close(practiceOrganization);
  }

  checkboxTable(tableCheckBox: TableCheckbox, event: any): void {}

  selectAll(): void {}

  downloadExport(): void {}

  checkShowInactive() {
    this.selectAllChecked = false;
    this.selectAll();
    this.visibleForRefresh = false;
    setTimeout(() => (this.visibleForRefresh = true), 0);
  }
}
