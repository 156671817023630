import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { getErrorMessage } from '../shared-layout/shared.tools';
import { GridOptions } from './model/grid-options';
import { Subjectarea } from './model/subjectarea';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable({
  providedIn: 'root',
})
export class SubjectareaService {
  gridOptions: GridOptions;
  apiURL = environment.apiUrl;
  readonly extendedUserEndpoint: string = `${environment.apiUrl}/api/pt/subjectarea`;

  constructor(private http: HttpClient) {
    this.gridOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }

  getList(event: TableLazyLoadEvent) {
    //console.log(event);
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint, event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Fachbereiche konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getSingle(id: string) {
    return lastValueFrom(
      this.http
        .get<any>(this.extendedUserEndpoint + '/' + id)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Fachbereich konnte nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  saveSubjectarea(subjectarea: Subjectarea) {
    //console.log(JSON.stringify(subjectarea));
    return lastValueFrom(
      this.http
        .post<Subjectarea>(
          this.extendedUserEndpoint + '/new',
          JSON.stringify(subjectarea),
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Fachbereich konnte nicht gespeichert werden.'
                  )
                )
            )
          )
        )
    );
  }
}
