export class DeptFieldsTrainers {
  plz: boolean;
  sozialeArbeit: boolean;
}
export const deptFieldsTrainersDefault: DeptFieldsTrainers = {
  plz: false,
  sozialeArbeit: false,
};
export const deptFieldsTrainersShowAll: DeptFieldsTrainers = {
  plz: true,
  sozialeArbeit: true,
};
export const deptFieldsTrainersS: DeptFieldsTrainers = {
  plz: false,
  sozialeArbeit: true,
};
export const deptFieldsTrainersIPH: DeptFieldsTrainers = {
  plz: false,
  sozialeArbeit: true,
};
export const deptFieldsTrainersIER: DeptFieldsTrainers = {
  plz: true,
  sozialeArbeit: false,
};
export const deptFieldsTrainersIPT: DeptFieldsTrainers = {
  plz: false,
  sozialeArbeit: false,
};

export class DeptFieldsTrainerDetail {
  WbmBesucht: boolean;
  sozialeArbeitFields: boolean;
  physioFields: boolean;
  recognitionStates: boolean;
}

export const deptFieldsTrainerDetailDefault: DeptFieldsTrainerDetail = {
  WbmBesucht: false,
  sozialeArbeitFields: false,
  physioFields: false,
  recognitionStates: false,
};

export const deptFieldsTrainerDetailShowAll: DeptFieldsTrainerDetail = {
  WbmBesucht: true,
  sozialeArbeitFields: true,
  physioFields: true,
  recognitionStates: true,
};

export const deptFieldsTrainerDetailS: DeptFieldsTrainerDetail = {
  WbmBesucht: false,
  sozialeArbeitFields: true,
  physioFields: false,
  recognitionStates: true,
};

export const deptFieldsTrainerDetailIPH: DeptFieldsTrainerDetail = {
  WbmBesucht: false,
  sozialeArbeitFields: true,
  physioFields: false,
  recognitionStates: false,
};

export const deptFieldsTrainerDetailIER: DeptFieldsTrainerDetail = {
  WbmBesucht: true,
  sozialeArbeitFields: false,
  physioFields: false,
  recognitionStates: false,
};

export const deptFieldsTrainerDetailIPT: DeptFieldsTrainerDetail = {
  WbmBesucht: false,
  sozialeArbeitFields: false,
  physioFields: true,
  recognitionStates: false,
};
