import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { getErrorMessage } from '../shared-layout/shared.tools';
import { GridOptions } from './model/grid-options';
import { Internshipperiod } from './model/internshipperiod';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable({
  providedIn: 'root',
})
export class InternshipperiodService {
  gridOptions: GridOptions;
  apiURL = environment.apiUrl;
  readonly extendedUserEndpoint: string = `${environment.apiUrl}/api/pt/internshipperiod`;

  constructor(private http: HttpClient) {
    this.gridOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }

  getList(event: TableLazyLoadEvent) {
    //console.log(event);
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint, event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnis-Perioden konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getSingle(id: string) {
    return lastValueFrom(
      this.http
        .get<any>(this.extendedUserEndpoint + '/' + id)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Ausbildungsverhältnis-Periode konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  saveInternshipperiod(internshipperiod: Internshipperiod) {
    return lastValueFrom(
      this.http
        .post<Internshipperiod>(
          this.extendedUserEndpoint + '/new',
          JSON.stringify(internshipperiod),
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praktikumsperiode konnte nicht gespeichert werden.'
                  )
                )
            )
          )
        )
    );
  }
}
