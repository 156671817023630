import { SubjectareaAssignment } from './subjectarea-assignment';
import { Communication } from './communication';
import { InternshipMatrixSubjectArea } from './internship-matrix';
import { Person } from './person';
import { PersonTable } from './person-table';

export class PracticeOrganization extends Person {
  organisationname?: string;
  addon?: string;

  web?: string;
  Address?: Communication;

  organisationnumber?: string;
  contactPersonFirstname?: string;
  contactPersonLastname?: string;
  contactPersonTelefon?: Communication;

  collectiveRecognition: boolean;
  agreementAvailable: boolean;
  recognitionAvailable: boolean;
  trainingConceptAvailable: boolean;
  dateTrainingConcept?: string;
  timeofRecognition?: string;

  equivalentRecognition?: boolean;
  remarks?: string;

  hospitalTypeNote?: string;
  hospitalType?: string;

  directEmployment: boolean;
  feedbackOnJob?: string;
  specialContract: boolean;
  cancellations?: string;
  generalInformation?: string;
  specialistForum?: string;

  contactPerson?: PersonTable[];
  contactMentor?: PersonTable[];
  contactErgo?: PersonTable;
  contactEducation?: PersonTable[];
  contactContract?: PersonTable;
  contactHr?: PersonTable;
  contactWage?: PersonTable[];
  contactMidwife?: PersonTable;
  contactWeekbed?: PersonTable;
  contactNursing?: PersonTable;
  contactPraxisTrainer?: PersonTable;
  contactAdministration?: PersonTable;
  contactDirection?: PersonTable;
  contactHospitationOne?: PersonTable[];
  contactHospitationTwo?: PersonTable;
  contactBsc?: PersonTable[];
  contactZmc?: PersonTable[];
  contactPhysio?: PersonTable;

  subjectAreas: SubjectareaAssignment[];
  internshipMatrix?: InternshipMatrixSubjectArea[];

  provisionallyRecognition: boolean;
  oneTimeExecution: boolean;

  cooperationAgreementAvailable: boolean;
  annualAgreementAvailable: boolean;
}
