<div class="row">
  <div class="col">
    <div *ngIf="notify" class="alert alert-success">
      {{ notify }}
    </div>
    <div *ngIf="student">
      <h2>
        <a
          routerLink="/students"
          routerLinkActive="active"
          ngbTooltip="zur Übersicht"
          ><i class="pi pi-angle-left bluecolor"></i></a
        >&nbsp;Studierende:r &nbsp;
        <span *ngIf="!student.isActive">[Inaktiv]</span>
      </h2>
      <div class="form-group">
        <div class="row">
          <div class="col-sm">
            <label for="Prename">Vorname: </label>
            <input
              class="form-control"
              id="firstname"
              name="firstname"
              [(ngModel)]="student.firstname"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="Surname">Nachname: </label
            ><input
              class="form-control"
              id="lastname"
              name="lastname"
              [(ngModel)]="student.lastname"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-sm">
            <label for="EMail">E-Mail: </label
            ><a href="mailto:{{ student.emailText }}"
              ><input
                class="form-control"
                id="emailText"
                name="emailText"
                [(ngModel)]="student.emailText"
                [readonly]="true"
                [disabled]="true"
                size="40"
            /></a>
          </div>
          <div class="col-sm">
            <label for="Mobil">Mobil: </label
            ><input
              class="form-control"
              id="mobile"
              name="mobile"
              [(ngModel)]="student.mobile"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="Phone">Telefon: </label
            ><input
              class="form-control"
              id="telefon"
              name="telefon"
              [(ngModel)]="student.telefon"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="RegistrationNumber">Matrikelnummer: </label
            ><input
              class="form-control"
              rows="4"
              cols="43"
              id="matriculationNumber"
              name="matriculationNumber"
              [(ngModel)]="student.matriculationNumber"
              [readonly]="true"
              [disabled]="true"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-sm">
            <label for="Street">Strasse: </label
            ><input
              class="form-control"
              id="street"
              name="street"
              [(ngModel)]="student.street"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="Additon">Adresszusatz: </label
            ><input
              class="form-control"
              id="addressAddon"
              name="addressAddon"
              [(ngModel)]="student.addressAddon"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="ZIP">PLZ: </label
            ><input
              class="form-control"
              id="plz"
              name="plz"
              [(ngModel)]="student.plz"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="City">Ort: </label
            ><input
              class="form-control"
              id="city"
              name="city"
              [(ngModel)]="student.city"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="Country">Land: </label
            ><input
              class="form-control"
              id="country"
              name="country"
              [(ngModel)]="student.country"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
        </div>

        <div class="row">
          <div *ngIf="deptFields.zivilstand" class="col-sm">
            <label for="civilStatus">Zivilstand: </label
            ><input
              class="form-control"
              id="civilStatus"
              name="civilStatus"
              [(ngModel)]="student.civilStatus"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div *ngIf="deptFields.AHVSozialversicherungsNr" class="col-sm">
            <label for="AHV">AHV-/Sozialversicherungsnummer: </label
            ><input
              class="form-control"
              rows="4"
              cols="43"
              id="ahv"
              name="ahv"
              [(ngModel)]="student.ahv"
              [readonly]="true"
              [disabled]="true"
            />
          </div>
          <!-- <div *ngIf="deptFields.ausbildungsjahr" class="col-sm">
            <label for="educationYear">Ausbildungsjahr: </label
            ><input
              class="form-control"
              id="educationYear"
              name="educationYear"
              [(ngModel)]="student.educationYear"
              [readonly]="false"
              [disabled]="false"
              size="40"
            />
          </div> -->
          <div *ngIf="deptFields.aufenthaltsbewilligung" class="col-sm">
            <label for="ResidencePermit">Aufenthaltsbewilligung: </label
            ><input
              class="form-control"
              id="residencePermit"
              name="residencePermit"
              [(ngModel)]="student.residencePermit"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
        </div>

        <div class="row">
          <div *ngIf="deptFields.geschlecht" class="col-sm">
            <label for="gender">Geschlecht: &nbsp; &nbsp;</label>
            <mat-radio-group [(ngModel)]="student.gender">
              <mat-radio-button
                [disabled]="true"
                [checked]="gender === student.gender"
                *ngFor="let gender of genders"
                [value]="gender"
              >
                {{ gender }}&nbsp; &nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="deptFields.studiengangKlasse" class="col-sm">
            <label for="Course">Studienjahrgang: </label
            ><input
              class="form-control"
              rows="4"
              cols="43"
              id="class"
              name="class"
              [(ngModel)]="student.class"
              [readonly]="true"
              [disabled]="true"
            />
          </div>
          <div *ngIf="deptFields.geburtstag" class="col-sm">
            <label for="birthdate">Geburtsdatum:</label>
            <input
              type="date"
              id="birthdate"
              name="birthdate"
              [(ngModel)]="student.birthdate"
              [readonly]="true"
              [disabled]="true"
              class="form-control"
            />
          </div>
          <div *ngIf="deptFields.heimatortStaatsangehorigkeit" class="col-sm">
            <label for="Hometown">Heimatort: </label
            ><input
              class="form-control"
              id="hometown"
              name="hometown"
              [(ngModel)]="student.hometown"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
        </div>

        <div class="row">
          <div *ngIf="deptFields.schulabschluss" class="col-sm">
            <label for="Graduation">Schulabschluss: </label
            ><textarea
              rows="2"
              class="form-control"
              cols="43"
              id="graduation"
              name="graduation"
              [(ngModel)]="student.graduation"
              [readonly]="false"
              [disabled]="false"
            ></textarea>
          </div>
          <div *ngIf="deptFields.berufsabschluss" class="col-sm">
            <label for="ProfessionalQualification">Berufsabschluss: </label
            ><textarea
              rows="2"
              class="form-control"
              cols="43"
              id="professionalQualification"
              name="professionalQualification"
              [(ngModel)]="student.professionalQualification"
              [readonly]="false"
              [disabled]="false"
            ></textarea>
          </div>
          <div *ngIf="deptFields.activityBeforeStudy" class="col-sm">
            <label for="activityBeforeStudy"
              >Tätigkeit im GW vor Studium: </label
            ><textarea
              rows="2"
              class="form-control"
              cols="43"
              id="activityBeforeStudy"
              name="activityBeforeStudy"
              [(ngModel)]="student.activityBeforeStudy"
              [readonly]="false"
              [disabled]="false"
            ></textarea>
          </div>
        </div>

        <div *ngIf="deptFields.auflagen" class="row">
          <div class="col-sm">
            <mat-checkbox
              id="withCondition"
              name="withCondition"
              [(ngModel)]="student.withCondition"
              >Auflagen ja</mat-checkbox
            >
          </div>
          <div class="col-sm">
            <label for="conditionNotes">Auflagen: </label>
            <textarea
              rows="2"
              class="form-control"
              cols="43"
              id="conditionNotes"
              name="conditionNotes"
              [(ngModel)]="student.conditionNotes"
              [readonly]="false"
              [disabled]="false"
            ></textarea>
          </div>
        </div>

        <div class="row">
          <div *ngIf="deptFields.bemerkungen" class="col-sm">
            <label for="remarks">Bemerkung: </label
            ><p-editor
              [style]="{
                height: '150px',
                'border-radius': '0 0 0.375rem 0.375rem'
              }"
              id="remarks"
              name="remarks"
              [(ngModel)]="student.remarks"
              [readonly]="false"
              [disabled]="false"
            ></p-editor>
          </div>
          <div *ngIf="deptFields.internebemerkungen" class="col-sm">
            <label for="remarks">Bemerkungen intern: </label
            ><p-editor
              [style]="{
                height: '150px',
                'border-radius': '0 0 0.375rem 0.375rem'
              }"
              id="remarks"
              name="remarks"
              [(ngModel)]="student.internalComments"
              [readonly]="false"
              [disabled]="false"
            ></p-editor>
          </div>
        </div>

        <div class="row">
          <div *ngIf="deptFields.mentoring" class="col-sm">
            <label for="mentoring">Mentoring: </label
            ><input
              class="form-control"
              id="mentoring"
              name="mentoring"
              [(ngModel)]="student.mentoring"
              [readonly]="false"
              [disabled]="false"
              size="40"
            />
          </div>
          <div *ngIf="deptFields.klassensprechnerin" class="col-sm">
            <mat-checkbox
              id="isClassSpeaker"
              name="isClassSpeaker"
              [(ngModel)]="student.isClassSpeaker"
              >Klassensprechner:in</mat-checkbox
            >
          </div>
          <div *ngIf="deptFields.spezialfall" class="col-sm">
            <mat-checkbox
              id="specialCase"
              name="specialCase"
              [(ngModel)]="student.specialCase"
              >Spezialfall</mat-checkbox
            >
          </div>
          <div *ngIf="deptFields.spezialfall" class="col-sm">
            <label for="specialCaseNotes">Spezialfall: Bemerkung: </label
            ><input
              class="form-control"
              id="specialCaseNotes"
              name="specialCaseNotes"
              [(ngModel)]="student.specialCaseNotes"
              [readonly]="false"
              [disabled]="false"
              size="40"
            />
          </div>
        </div>

        <button class="btn btn-primary float-right" (click)="onSubmit()">
          Speichern
        </button>
      </div>
    </div>

    <h4 *ngIf="student" class="mb-2" style="margin-top: 30px">
      Ausbildungsverhältnisse
      <button
        type="button"
        [routerLink]="['/internship-detail']"
        [queryParams]="{ studentId: student.id }"
        ngbTooltip="Neues Ausbildungsverhältnis erstellen."
        pButton
        pRipple
        class="btn"
        tooltipPosition="bottom"
      >
        <i
          class="pi pi-plus-circle mr-2 bluecolor"
          style="font-size: 20px; vertical-align: baseline"
        ></i>
      </button>
    </h4>
    <p-table
      #dt
      [value]="internships"
      sortMode="single"
      sortField="internshipType"
      [sortOrder]="1"
      [scrollable]="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="min-width: 160px">
            <div pSortableColumn="internshipType">
              Praktikumstyp
              <p-sortIcon field="internshipType"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="internshipType"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th
            *ngIf="deptFieldsInternship.mentorNameComp"
            style="min-width: 250px"
          >
            <div pSortableColumn="mentorNameComp">
              Studierendenbegleitende:r
              <p-sortIcon field="mentorNameComp"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="mentorNameComp"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th
            *ngIf="deptFieldsInternship.trainerNameComp"
            style="min-width: 220px"
          >
            <div pSortableColumn="trainerNameComp">
              Praxisausbildende:r
              <p-sortIcon field="trainerNameComp"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="trainerNameComp"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 250px">
            <div pSortableColumn="organizationNameComp">
              Praxisorganisation
              <p-sortIcon field="organizationNameComp"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="organizationNameComp"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th
            *ngIf="deptFieldsInternship.subjectAreaName"
            style="min-width: 160px"
          >
            <div pSortableColumn="subjectAreasNameComp">
              Fachbereiche
              <p-sortIcon field="subjectAreasNameComp"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="subjectAreasNameComp"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th
            *ngIf="deptFieldsInternship.registrationDate"
            style="min-width: 180px"
          >
            <div pSortableColumn="registrationDate">
              Einschreibedatum
              <p-sortIcon field="registrationDate"></p-sortIcon>
            </div>
            <p-columnFilter
              type="date"
              field="registrationDate"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 130px">
            <div pSortableColumn="startdate">
              Startdatum
              <p-sortIcon field="startdate"></p-sortIcon>
            </div>
            <p-columnFilter
              type="date"
              field="startdate"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 130px">
            <div pSortableColumn="enddate">
              Enddatum
              <p-sortIcon field="enddate"></p-sortIcon>
            </div>
            <p-columnFilter
              type="date"
              field="enddate"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 60px" alignFrozen="right" pFrozenColumn>
            &nbsp;
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-internship>
        <tr>
          <td style="min-width: 160px">
            <a
              [routerLink]="[
                '/internshiptype-detail',
                internship.internshipTypeId
              ]"
              >{{ internship.internshipType }}</a
            >
          </td>
          <td
            *ngIf="deptFieldsInternship.mentorNameComp"
            style="min-width: 250px"
          >
            <a [routerLink]="['/mentor-detail', internship.studbegId]">{{
              internship.mentorNameComp
            }}</a>
          </td>
          <td
            *ngIf="deptFieldsInternship.trainerNameComp"
            style="min-width: 220px"
          >
            <a [routerLink]="['/trainer-detail', internship.praxisTrainerId]">{{
              internship.trainerNameComp
            }}</a>
          </td>
          <td style="min-width: 250px">
            <a [routerLink]="['/practiceorganization', internship.orgId]">{{
              internship.organizationNameComp
            }}</a>
          </td>
          <td
            *ngIf="deptFieldsInternship.subjectAreaName"
            style="min-width: 160px"
          >
            {{ internship.subjectAreasNameComp }}
          </td>
          <td
            *ngIf="deptFieldsInternship.registrationDate"
            style="min-width: 160px"
          >
            {{ internship.registrationDate }}
          </td>
          <td style="min-width: 130px">{{ internship.startdate }}</td>
          <td style="min-width: 130px">{{ internship.enddate }}</td>
          <td
            style="min-width: 60px"
            class="functionicons"
            alignFrozen="right"
            pFrozenColumn
          >
            <div>
              <a
                [routerLink]="['/internship-detail', internship.id]"
                ngbTooltip="Bearbeiten"
                ><i class="fas fa-edit bluecolor"></i
              ></a>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
