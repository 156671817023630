// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  enableDebug: false,
  apiUrl: 'https://praxisapi.zhaw.ch',
  tenantName: 'zhaw',
  webApiAppId: '2c87e60d-67ac-4127-a9e6-8d9645b796d3',
  angularAppId: '778c4cfc-6231-474c-83e2-f871369d0226'
};

/*export const environment = {
  production: false,
  enableDebug: true,
  apiUrl: 'http://localhost:8020',
  tenantName: 'daylightsandbox',
  webApiAppId: 'e1b7f3d8-2f32-44ab-9580-01d462e98605',
  angularAppId: '4be1518c-b5d3-42d6-956e-5c2fbf3b4eaa'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
