<div class="row">
  <div class="col">
    <h2 class="mb-3">Fachbereiche</h2>

    <div *ngIf="!isForSelection" class="row" style="padding-bottom: 10px">
      <div class="col">
        <button
          type="button"
          [routerLink]="['/subjectarea-detail']"
          ngbTooltip="Neuer Fachbereich erstellen."
          pButton
          pRipple
          icon="pi pi-plus-circle mr-2"
          class="btn btn-primary float-right"
          tooltipPosition="bottom"
        >
          &nbsp;&nbsp;Neuer Fachbereich&nbsp;
        </button>
      </div>
    </div>

    <p-table
      #table
      styleClass="p-datatable-gridlines"
      [lazy]="true"
      (onLazyLoad)="loadSubjectareas($event)"
      [totalRecords]="totalRecords"
      [value]="subjectareas"
      sortMode="single"
      sortField="name"
      [sortOrder]="1"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [paginator]="true"
      [rows]="rowsSelectionSubjectareas$ | async"
      [rowsPerPageOptions]="[10, 50, 100, 200, 500, 1000]"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 220px">
            <div pSortableColumn="name">
              Name
              <p-sortIcon field="name"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="name"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div>
              Aktiv
              <button
                type="button"
                id="showArchive"
                name="showArchive"
                class="btn btn-primary"
                style="height: 25px; line-height: 0; border-color: white"
                (click)="setActiveFilter()"
              >
                Nur Aktive anzeigen
              </button>
              <p-columnFilter type="boolean" field="active"></p-columnFilter>
            </div>
          </th>
          <th style="min-width: 60px" alignFrozen="right" pFrozenColumn>
            &nbsp;
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-subjectarea>
        <tr>
          <td style="min-width: 220px">{{ subjectarea.name }}</td>
          <td style="min-width: 220px">
            <mat-checkbox
              [(ngModel)]="subjectarea.active"
              disabled="true"
            ></mat-checkbox>
          </td>
          <td
            class="functionicons"
            style="min-width: 60px"
            alignFrozen="right"
            pFrozenColumn
          >
            <div *ngIf="isForSelection">
              <button
                class="btn"
                (click)="selectSubjectarea(subjectarea)"
                ngbTooltip="Auswählen"
              >
                <i class="fas fa-solid fa-plus bluecolor"></i>
              </button>
            </div>
            <div *ngIf="!isForSelection">
              <a
                [routerLink]="['/subjectarea-detail', subjectarea.id]"
                ngbTooltip="Bearbeiten"
                ><i class="fas fa-edit bluecolor"></i
              ></a>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
