import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { getErrorMessage } from '../shared-layout/shared.tools';
import { lastValueFrom, throwError } from 'rxjs';

import { PracticeOrganization } from './model/practiceorganization';
import { RadioGroupEntry } from './model/radiogroupentry';
import { GridOptions } from './model/grid-options';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable()
export class PracticeOrganizationService {
  readonly extendedUserEndpoint: string = `${environment.apiUrl}/api/pt/practiceorganization`;
  gridOptions: GridOptions;

  constructor(private http: HttpClient) {
    this.gridOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }

  getDummyData() {
    return lastValueFrom(
      this.http.get<any>('../../assets/practiceorganizations.json')
    )
      .then((res) => <PracticeOrganization[]>res.data)
      .then((data) => {
        return data;
      });
  }

  getList(event: TableLazyLoadEvent) {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint, event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisorganisationen konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getListWithInactive(event: TableLazyLoadEvent) {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint + '/publishedwithinactive', event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisorganisationen konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getSingle(id: string) {
    return lastValueFrom(
      this.http
        .get<any>(this.extendedUserEndpoint + '/org?id=' + id)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisorganisationen konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getHospitalTypes() {
    return lastValueFrom(
      this.http
        .get<RadioGroupEntry[]>(this.extendedUserEndpoint + '/hospitaltypes')
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Spitaltypen konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  save(practiceorg: PracticeOrganization) {
    //console.log('save');
    //console.log(JSON.stringify(practiceorg));
    return lastValueFrom(
      this.http
        .post<PracticeOrganization>(
          this.extendedUserEndpoint + '/new',
          JSON.stringify(practiceorg),
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisorganisationen konnten nicht gespeichert werden.'
                  )
                )
            )
          )
        )
    );
  }

  getListforExport(event: TableLazyLoadEvent) {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint + '/publishedforexport', event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisorganisationen konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  encodeParams(params: any): string {
    let body: string = '';

    for (let key in params) {
      if (body.length) {
        body += '&';
      }
      body += key + '=';
      body += encodeURIComponent(params[key]);
    }

    return body;
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
