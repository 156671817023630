export class DeptFieldsStudents {
  studentclass: boolean;
}

export const deptFieldsStudentsS: DeptFieldsStudents = {
  studentclass: false,
};
export const deptFieldsStudentsIER: DeptFieldsStudents = {
  studentclass: true,
};
export const deptFieldsStudentsN: DeptFieldsStudents = {
  studentclass: true,
};
export const deptFieldsStudentsIPT: DeptFieldsStudents = {
  studentclass: true,
};
export const deptFieldsStudentsIHB: DeptFieldsStudents = {
  studentclass: true,
};
export const deptFieldsStudentsIPF: DeptFieldsStudents = {
  studentclass: true,
};
export const deptFieldsStudentsIPH: DeptFieldsStudents = {
  studentclass: true,
};
export const deptFieldsStudentsDefault: DeptFieldsStudents = {
  studentclass: false,
};
export const deptFieldsStudentsShowAll: DeptFieldsStudents = {
  studentclass: true,
};

export class DeptFieldsStudentDetail {
  geschlecht: boolean;
  studiengangKlasse: boolean;
  geburtstag: boolean;
  heimatortStaatsangehorigkeit: boolean;
  zivilstand: boolean;
  AHVSozialversicherungsNr: boolean;
  aufenthaltsbewilligung: boolean;
  schulabschluss: boolean;
  berufsabschluss: boolean;
  activityBeforeStudy: boolean;
  auflagen: boolean;
  mentoring: boolean;
  ausbildungsjahr: boolean;
  bemerkungen: boolean;
  internebemerkungen: boolean;
  klassensprechnerin: boolean;
  spezialfall: boolean;
  studienbegleiterin: boolean;
}

export const deptFieldsStudentDetailS: DeptFieldsStudentDetail = {
  geschlecht: false,
  studiengangKlasse: false,
  geburtstag: false,
  heimatortStaatsangehorigkeit: false,
  zivilstand: false,
  AHVSozialversicherungsNr: false,
  aufenthaltsbewilligung: false,
  schulabschluss: false,
  berufsabschluss: false,
  activityBeforeStudy: false,
  auflagen: false,
  mentoring: false,
  ausbildungsjahr: false,
  bemerkungen: false,
  internebemerkungen: true,
  klassensprechnerin: false,
  spezialfall: false,
  studienbegleiterin: false,
};
export const deptFieldsStudentDetailIER: DeptFieldsStudentDetail = {
  geschlecht: true,
  studiengangKlasse: true,
  geburtstag: true,
  heimatortStaatsangehorigkeit: true,
  zivilstand: false,
  AHVSozialversicherungsNr: false,
  aufenthaltsbewilligung: false,
  schulabschluss: false,
  berufsabschluss: false,
  activityBeforeStudy: true,
  auflagen: false,
  mentoring: false,
  ausbildungsjahr: false,
  bemerkungen: true,
  internebemerkungen: false,
  klassensprechnerin: false,
  spezialfall: false,
  studienbegleiterin: true,
};
export const deptFieldsStudentDetailN: DeptFieldsStudentDetail = {
  geschlecht: false,
  studiengangKlasse: false,
  geburtstag: false,
  heimatortStaatsangehorigkeit: false,
  zivilstand: false,
  AHVSozialversicherungsNr: false,
  aufenthaltsbewilligung: false,
  schulabschluss: false,
  berufsabschluss: false,
  activityBeforeStudy: true,
  auflagen: false,
  mentoring: false,
  ausbildungsjahr: false,
  bemerkungen: false,
  internebemerkungen: false,
  klassensprechnerin: false,
  spezialfall: false,
  studienbegleiterin: false,
};
export const deptFieldsStudentDetailIPT: DeptFieldsStudentDetail = {
  geschlecht: true,
  studiengangKlasse: true,
  geburtstag: true,
  heimatortStaatsangehorigkeit: true,
  zivilstand: true,
  AHVSozialversicherungsNr: true,
  aufenthaltsbewilligung: false,
  schulabschluss: false,
  berufsabschluss: false,
  activityBeforeStudy: false,
  auflagen: false,
  mentoring: true,
  ausbildungsjahr: false,
  bemerkungen: true,
  internebemerkungen: false,
  klassensprechnerin: true,
  spezialfall: true,
  studienbegleiterin: false,
};
export const deptFieldsStudentDetailIHB: DeptFieldsStudentDetail = {
  geschlecht: true,
  studiengangKlasse: true,
  geburtstag: true,
  heimatortStaatsangehorigkeit: true,
  zivilstand: true,
  AHVSozialversicherungsNr: true,
  aufenthaltsbewilligung: true,
  schulabschluss: false,
  berufsabschluss: false,
  activityBeforeStudy: true,
  auflagen: true,
  mentoring: false,
  ausbildungsjahr: false,
  bemerkungen: true,
  internebemerkungen: false,
  klassensprechnerin: false,
  spezialfall: false,
  studienbegleiterin: false,
};
export const deptFieldsStudentDetailIPF: DeptFieldsStudentDetail = {
  geschlecht: true,
  studiengangKlasse: true,
  geburtstag: true,
  heimatortStaatsangehorigkeit: true,
  zivilstand: false,
  AHVSozialversicherungsNr: true,
  aufenthaltsbewilligung: true,
  schulabschluss: true,
  berufsabschluss: true,
  activityBeforeStudy: true,
  auflagen: false,
  mentoring: false,
  ausbildungsjahr: false,
  bemerkungen: false,
  internebemerkungen: false,
  klassensprechnerin: false,
  spezialfall: false,
  studienbegleiterin: false,
};
export const deptFieldsStudentDetailIPH: DeptFieldsStudentDetail = {
  geschlecht: false,
  studiengangKlasse: false,
  geburtstag: false,
  heimatortStaatsangehorigkeit: false,
  zivilstand: false,
  AHVSozialversicherungsNr: false,
  aufenthaltsbewilligung: false,
  schulabschluss: false,
  berufsabschluss: false,
  activityBeforeStudy: false,
  auflagen: false,
  mentoring: false,
  ausbildungsjahr: false,
  bemerkungen: false,
  internebemerkungen: false,
  klassensprechnerin: false,
  spezialfall: false,
  studienbegleiterin: false,
};
export const deptFieldsStudentDetailDefault: DeptFieldsStudentDetail = {
  geschlecht: false,
  studiengangKlasse: false,
  geburtstag: false,
  heimatortStaatsangehorigkeit: false,
  zivilstand: false,
  AHVSozialversicherungsNr: false,
  aufenthaltsbewilligung: false,
  schulabschluss: false,
  berufsabschluss: false,
  activityBeforeStudy: true,
  auflagen: false,
  mentoring: false,
  ausbildungsjahr: false,
  bemerkungen: false,
  internebemerkungen: false,
  klassensprechnerin: false,
  spezialfall: false,
  studienbegleiterin: false,
};
export const deptFieldsStudentDetailShowAll: DeptFieldsStudentDetail = {
  geschlecht: true,
  studiengangKlasse: true,
  geburtstag: true,
  heimatortStaatsangehorigkeit: true,
  zivilstand: true,
  AHVSozialversicherungsNr: true,
  aufenthaltsbewilligung: true,
  schulabschluss: true,
  berufsabschluss: true,
  activityBeforeStudy: true,
  auflagen: true,
  mentoring: true,
  ausbildungsjahr: true,
  bemerkungen: true,
  internebemerkungen: true,
  klassensprechnerin: true,
  spezialfall: true,
  studienbegleiterin: true,
};
