<div class="row">
  <div class="col">
    <div>
      <h2 class="mb-3">
        <a
          routerLink="/practiceorganizations"
          routerLinkActive="active"
          ngbTooltip="zur Übersicht"
          ><i class="pi pi-angle-left bluecolor"></i></a
        >&nbsp;Praxisorganisation&nbsp;
        <span *ngIf="organization && !organization.isActive">[Inaktiv]</span>
      </h2>

      <form *ngIf="organization">
        <div class="form-group">
          <div class="row">
            <div class="col-sm">
              <label for="Organization">Organisation: </label>
              <input
                class="form-control"
                id="organisationname"
                name="organisationname"
                [(ngModel)]="organization.organisationname"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="Additon">Firmenname 2: </label>
              <input
                class="form-control"
                id="addon"
                name="addon"
                [(ngModel)]="organization.addon"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label for="Email">Email: </label>
              <a href="mailto:{{ organization.emailText }}"
                ><input
                  class="form-control"
                  id="emailText"
                  name="emailText"
                  [(ngModel)]="organization.emailText"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
              /></a>
            </div>
            <div class="col-sm">
              <label for="Web">Web: </label>
              <input
                class="form-control"
                id="web"
                name="web"
                [(ngModel)]="organization.web"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="Phone">Telefon: </label>
              <input
                class="form-control"
                id="telefon"
                name="telefon"
                [(ngModel)]="organization.telefon"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>

          <div class="row">
            <h4 class="mb-2">Adresse</h4>
            <div class="col-sm">
              <label for="Street">Strasse: </label>
              <input
                class="form-control"
                id="street"
                name="street"
                [(ngModel)]="organization.street"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="AddressAddition">Adresszusatz: </label>
              <div class="col-sm">
                <input
                  class="form-control"
                  id="addressAddon"
                  name="addressAddon"
                  [(ngModel)]="organization.addressAddon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label for="ZIP">PLZ: </label>
              <input
                class="form-control"
                id="plz"
                name="plz"
                [(ngModel)]="organization.plz"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="City">Ort: </label>
              <input
                class="form-control"
                id="city"
                name="city"
                [(ngModel)]="organization.city"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="Country">Land: </label>
              <input
                class="form-control"
                id="country"
                name="country"
                [(ngModel)]="organization.country"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>

          <div *ngIf="deptFields.iphFields" class="row">
            <div class="col-sm">
              <mat-checkbox
                id="cooperationAgreementAvailable"
                name="cooperationAgreementAvailable"
                [(ngModel)]="organization.cooperationAgreementAvailable"
                >Kooperationsvereinbarung vorhanden</mat-checkbox
              >
            </div>
            <div class="col-sm">
              <mat-checkbox
                id="annualAgreementAvailable"
                name="annualAgreementAvailable"
                [(ngModel)]="organization.annualAgreementAvailable"
                >Jahresvereinbarung vorhanden</mat-checkbox
              >
            </div>
          </div>

          <div class="row">
            <div *ngIf="deptFields.sozialeArbeitFields" class="col-sm">
              <div class="row">
                <mat-checkbox
                  id="trainingConceptAvailable"
                  name="trainingConceptAvailable"
                  [(ngModel)]="organization.trainingConceptAvailable"
                  >Ausbildungskonzept vorhanden</mat-checkbox
                >
              </div>
              <div class="row">
                <mat-checkbox
                  id="recognitionAvailable"
                  name="recognitionAvailable"
                  [(ngModel)]="organization.recognitionAvailable"
                  >Anerkennung vorhanden</mat-checkbox
                >
              </div>
              <div class="row">
                <mat-checkbox
                  id="agreementAvailable"
                  name="agreementAvailable"
                  [(ngModel)]="organization.agreementAvailable"
                  >Vereinbarung vorhanden</mat-checkbox
                >
              </div>
            </div>

            <div *ngIf="deptFields.sozialeArbeitFields" class="row">
              <h4 class="mb-2">Spezialfälle</h4>
            </div>

            <div *ngIf="deptFields.dateTrainingConcept" class="col-sm">
              <label for="DateEducationConcept"
                >Datum Ausbildungskonzept:
              </label>
              <input
                class="form-control"
                type="date"
                id="dateTrainingConcept"
                name="dateTrainingConcept"
                [(ngModel)]="organization.dateTrainingConcept"
                [readonly]="false"
                [disabled]="false"
                size="40"
              />
            </div>
            <div *ngIf="deptFields.timeofRecognition" class="col-sm">
              <label for="TimeRecognition">Zeitpunkt Anerkennung: </label>
              <input
                class="form-control"
                type="date"
                id="timeofRecognition"
                name="timeofRecognition"
                [(ngModel)]="organization.timeofRecognition"
                [readonly]="false"
                [disabled]="false"
                size="40"
              />
            </div>

            <div *ngIf="deptFields.sozialeArbeitFields" class="row">
              <div class="col-sm">
                <mat-checkbox
                  id="equivalentRecognition"
                  name="equivalentRecognition"
                  [(ngModel)]="organization.equivalentRecognition"
                  >Äquivalente Anerkennung</mat-checkbox
                >
              </div>
              <div class="col-sm">
                <mat-checkbox
                  id="collectiveRecognition"
                  name="collectiveRecognition"
                  [(ngModel)]="organization.collectiveRecognition"
                  >Kollektivanerkennung</mat-checkbox
                >
              </div>
              <div class="col-sm">
                <mat-checkbox
                  id="provisionallyRecognition"
                  name="provisionallyRecognition"
                  [(ngModel)]="organization.provisionallyRecognition"
                  >Provisorische Anerkennung</mat-checkbox
                >
              </div>
              <div class="col-sm">
                <mat-checkbox
                  id="oneTimeExecution"
                  name="oneTimeExecution"
                  [(ngModel)]="organization.oneTimeExecution"
                  >Einmalige Durchführung</mat-checkbox
                >
              </div>
            </div>

            <div *ngIf="deptFields.equivalentRecognitionInfo" class="col-sm">
              <label for="EquivalentRecognitionInfo"
                >Beschreibung Spezialfälle:
              </label>
              <textarea
                rows="2"
                class="form-control"
                cols="43"
                id="equivalentRecognitionInfo"
                name="equivalentRecognitionInfo"
                [(ngModel)]="organization.equivalentRecognitionInfo"
                [readonly]="false"
                [disabled]="false"
              ></textarea>
            </div>
          </div>

          <div class="row">
            <div *ngIf="deptFields.bemerkungen" class="col-sm">
              <label for="remarks">Bemerkungen: </label>
              <p-editor
                [style]="{
                  height: '150px',
                  'border-radius': '0 0 0.375rem 0.375rem'
                }"
                id="remarks"
                name="remarks"
                [(ngModel)]="organization.remarks"
                [readonly]="false"
                [disabled]="false"
              ></p-editor>
            </div>
            <div *ngIf="deptFields.internebemerkungen" class="col-sm">
              <label for="remarks">Bemerkungen intern: </label
              ><p-editor
                [style]="{
                  height: '150px',
                  'border-radius': '0 0 0.375rem 0.375rem'
                }"
                id="remarks"
                name="remarks"
                [(ngModel)]="organization.remarks"
                [readonly]="false"
                [disabled]="false"
              ></p-editor>
            </div>
            <div
              *ngIf="
                deptFields.pflegeFields ||
                deptFields.ergotherapieFields ||
                deptFields.hebammeFields ||
                deptFields.physioFields
              "
              class="col-sm"
            >
              <section>
                <div *ngFor="let subjectArea of subjectAreas" class="row">
                  <mat-checkbox
                    [name]="subjectArea.subjectArea.name"
                    [(ngModel)]="subjectArea.selected"
                    [checked]="subjectArea.selected"
                  >
                    {{ subjectArea.subjectArea.name }}
                  </mat-checkbox>
                </div>
              </section>
            </div>
          </div>

          <div *ngIf="deptFields.ergotherapieFields" class="row">
            <div class="col-sm">
              <div class="row">
                <mat-checkbox
                  id="directEmployment"
                  name="directEmployment"
                  [(ngModel)]="organization.directEmployment"
                  >Direktanstellung</mat-checkbox
                >
              </div>
              <div class="row">
                <mat-checkbox
                  id="specialContract"
                  name="specialContract"
                  [(ngModel)]="organization.specialContract"
                  >Spezialvertrag</mat-checkbox
                >
              </div>
            </div>
            <div class="col-sm">
              <label for="feedbackOnJob">Rückmeldungen zu Stelle: </label>
              <p-editor
                [style]="{
                  height: '148px',
                  'border-radius': '0 0 0.375rem 0.375rem'
                }"
                id="feedbackOnJob"
                name="feedbackOnJob"
                [(ngModel)]="organization.feedbackOnJob"
                [readonly]="false"
                [disabled]="false"
              ></p-editor>
            </div>
            <div class="col-sm">
              <label for="notesForStudents">Bemerkungen für Studierende:</label>
              <p-editor
                [style]="{
                  height: '150px',
                  'border-radius': '0 0 0.375rem 0.375rem'
                }"
                id="notesForStudents"
                name="notesForStudents"
                [(ngModel)]="organization.notesForStudents"
                [readonly]="false"
                [disabled]="false"
              >
              </p-editor>
            </div>
          </div>
          <div *ngIf="deptFields.ergotherapieFields" class="row">
            <div class="col-sm">
              <label for="cancellations">Absagen: </label>
              <p-editor
                [style]="{
                  height: '150px',
                  'border-radius': '0 0 0.375rem 0.375rem'
                }"
                id="cancellations"
                name="cancellations"
                [(ngModel)]="organization.cancellations"
                [readonly]="false"
                [disabled]="false"
              ></p-editor>
            </div>
            <div class="col-sm">
              <label for="generalInformation">Allgemeine Infos: </label>
              <p-editor
                [style]="{
                  height: '150px',
                  'border-radius': '0 0 0.375rem 0.375rem'
                }"
                id="generalInformation"
                name="generalInformation"
                [(ngModel)]="organization.generalInformation"
                [readonly]="false"
                [disabled]="false"
              ></p-editor>
            </div>
            <div class="col-sm">
              <label for="specialistForum">Fachforum: </label>
              <p-editor
                [style]="{
                  height: '150px',
                  'border-radius': '0 0 0.375rem 0.375rem'
                }"
                id="specialistForum"
                name="specialistForum"
                [(ngModel)]="organization.specialistForum"
                [readonly]="false"
                [disabled]="false"
              ></p-editor>
            </div>
          </div>

          <div *ngIf="deptFields.physioFields" class="row">
            <div class="col-sm">
              <mat-checkbox
                id="specialCase"
                name="specialCase"
                [(ngModel)]="organization.specialCase"
                >Spezialfall</mat-checkbox
              >
            </div>
            <div class="col-sm">
              <label for="specialCaseNotes">Spezialfall Bemerkung: </label>
              <textarea
                rows="2"
                class="form-control"
                cols="43"
                id="specialCaseNotes"
                name="specialCaseNotes"
                [(ngModel)]="organization.specialCaseNotes"
                [readonly]="false"
                [disabled]="false"
              ></textarea>
            </div>
            <div class="row">
              <mat-checkbox id="bsc" name="bsc" [(ngModel)]="organization.bsc"
                >Bsc</mat-checkbox
              >
            </div>
            <div class="row">
              <mat-checkbox id="zMC" name="zMC" [(ngModel)]="organization.zmc"
                >ZMC</mat-checkbox
              >
            </div>
            <mat-radio-group
              name="hospitalType"
              [(ngModel)]="organization.hospitalType"
            >
              <div class="col-sm">
                <mat-radio-button
                  [checked]="
                    hospitalType.dataSourceObjectId ===
                    organization.hospitalType
                  "
                  *ngFor="let hospitalType of hospitalTypes"
                  [value]="hospitalType.dataSourceObjectId"
                >
                  {{ hospitalType.name }}&nbsp; &nbsp;
                </mat-radio-button>
              </div>
            </mat-radio-group>
            <div class="col-sm">
              <label for="hospitalTypeNote">andere: Bemerkung: </label
              ><input
                class="form-control"
                id="hospitalTypeNote"
                name="hospitalTypeNote"
                [(ngModel)]="organization.hospitalTypeNote"
                [readonly]="false"
                [disabled]="false"
                size="40"
              />
            </div>
          </div>

          <div *ngIf="deptFields.leitungErgo" class="form-group row">
            <h4 class="mb-2">Leitung Ergo</h4>
            <div class="col-sm">
              <label for="contactErgoName">Name: </label>
              <input
                class="form-control"
                id="contactErgoName"
                name="contactErgoName"
                [(ngModel)]="organization.contactErgo.name"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="contactErgoTelefon">Telefon: </label>
              <input
                class="form-control"
                id="contactErgoTelefon"
                name="contactErgoTelefon"
                [(ngModel)]="organization.contactErgo.telefon"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="contactErgoEmail">Email: </label>
              <a href="mailto:{{ organization.contactErgo.emailText }}"
                ><input
                  class="form-control"
                  id="contactErgoEmail"
                  name="contactErgoEmail"
                  [(ngModel)]="organization.contactErgo.emailText"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
              /></a>
            </div>
          </div>

          <div *ngIf="deptFields.praxisausbildnerTabs" class="form-group row">
            <h4 class="mb-2">
              Praxisausbildende:r
              <button
                *ngIf="deptFields.ergotherapieFields"
                class="btn"
                (click)="openTrainers()"
              >
                <i class="fas fa-solid fa-plus bluecolor"></i>
              </button>
            </h4>
            <mat-tab-group
              *ngIf="organization.contactMentor[0]?.id !== undefined"
              animationDuration="0ms"
            >
              <mat-tab
                *ngFor="
                  let contactMentor of organization.contactMentor;
                  let i = index
                "
                label="{{ i + 1 }}"
              >
                <div class="form-group row" style="padding-top: 10px">
                  <div class="col-sm">
                    <label for="contactMentorName{{ i }}">Name: </label>
                    <input
                      class="form-control"
                      id="contactMentorName{{ i }}"
                      name="contactMentorName{{ i }}"
                      [(ngModel)]="contactMentor.name"
                      [readonly]="true"
                      [disabled]="true"
                      size="40"
                    />
                  </div>
                  <div class="col-sm">
                    <label for="contactMentorTelefon{{ i }}">Telefon: </label>
                    <input
                      class="form-control"
                      id="contactMentorTelefon{{ i }}"
                      name="contactMentorTelefon{{ i }}"
                      [(ngModel)]="contactMentor.telefon"
                      [readonly]="true"
                      [disabled]="true"
                      size="40"
                    />
                  </div>
                  <div class="col-sm">
                    <label for="contactMentorEmail{{ i }}">Email: </label>
                    <a href="mailto:{{ contactMentor.emailText }}"
                      ><input
                        class="form-control"
                        id="contactMentorEmail{{ i }}"
                        name="contactMentorEmail{{ i }}"
                        [(ngModel)]="contactMentor.emailText"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                    /></a>
                  </div>
                </div>
                <div class="row">
                  <button
                    *ngIf="deptFields.ergotherapieFields"
                    class="btn float-right"
                    style="height: 38px; width: 40px"
                    (click)="deleteTrainer(contactMentor)"
                  >
                    <i class="fas fa-trash bluecolor"></i>
                  </button>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div *ngIf="deptFields.ausbildungsverantwortliche">
            <div class="form-group row">
              <h4 class="mb-2">
                <span>Ausbildungsverantwortliche:r</span>
              </h4>
              <mat-tab-group
                *ngIf="organization.contactEducation[0]?.id !== undefined"
                animationDuration="0ms"
              >
                <mat-tab
                  *ngFor="
                    let contactEducation of organization.contactEducation;
                    let i = index
                  "
                  label="{{ i + 1 }}"
                >
                  <div
                    class="form-group row"
                    style="padding-top: 10px; width: 100% !important"
                  >
                    <div class="col-sm">
                      <label for="contactEducationName{{ i }}">Name: </label>
                      <input
                        class="form-control"
                        id="contactEducationName{{ i }}"
                        name="contactEducationName{{ i }}"
                        [(ngModel)]="contactEducation.name"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactEducationTelefon{{ i }}"
                        >Telefon:
                      </label>
                      <input
                        class="form-control"
                        id="contactEducationTelefon{{ i }}"
                        name="contactEducationTelefon{{ i }}"
                        [(ngModel)]="contactEducation.telefon"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactEducationEmail{{ i }}">Email: </label>
                      <a href="mailto:{{ contactEducation.emailText }}"
                        ><input
                          class="form-control"
                          id="contactEducationEmail{{ i }}"
                          name="contactEducationEmail{{ i }}"
                          [(ngModel)]="contactEducation.emailText"
                          [readonly]="true"
                          [disabled]="true"
                          size="40"
                      /></a>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>

          <div *ngIf="deptFields.zustandigFurVertrag" class="form-group row">
            <h4 class="mb-2">Zuständig für Vertrag</h4>
            <div class="col-sm">
              <label for="contactContractName">Name: </label>
              <input
                class="form-control"
                id="contactContractName"
                name="contactContractName"
                [(ngModel)]="organization.contactContract.name"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="contactContractTelefon">Telefon: </label>
              <input
                class="form-control"
                id="contactContractTelefon"
                name="contactContractTelefon"
                [(ngModel)]="organization.contactContract.telefon"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="contactContractEmail">Email: </label>
              <a href="mailto:{{ organization.contactContract.emailText }}"
                ><input
                  class="form-control"
                  id="contactContractEmail"
                  name="contactContractEmail"
                  [(ngModel)]="organization.contactContract.emailText"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
              /></a>
            </div>
          </div>

          <div
            *ngIf="deptFields.zustandigFurPersonalienblatt"
            class="form-group row"
          >
            <h4 class="mb-2">Zuständig für Personalienblatt</h4>
            <div class="col-sm">
              <label for="contactHrName">Name: </label>
              <input
                class="form-control"
                id="contactHrName"
                name="contactHrName"
                [(ngModel)]="organization.contactHr.name"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="contactHrTelefon">Telefon: </label>
              <input
                class="form-control"
                id="contactHrTelefon"
                name="contactHrTelefon"
                [(ngModel)]="organization.contactHr.telefon"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="contactHrEmail">Email: </label>
              <a href="mailto:{{ organization.contactHr.emailText }}"
                ><input
                  class="form-control"
                  id="contactHrEmail"
                  name="contactHrEmail"
                  [(ngModel)]="organization.contactHr.emailText"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
              /></a>
            </div>
          </div>

          <div *ngIf="deptFields.zustandigFurLohnfragen">
            <div class="form-group row">
              <h4 class="mb-2">Zuständig für Lohnfragen</h4>
              <mat-tab-group
                *ngIf="organization.contactWage[0]?.id !== undefined"
                animationDuration="0ms"
              >
                <mat-tab
                  *ngFor="
                    let contactWage of organization.contactWage;
                    let i = index
                  "
                  label="{{ i + 1 }}"
                >
                  <div class="form-group row" style="padding-top: 10px">
                    <div class="col-sm">
                      <label for="contactWageName{{ i }}">Name: </label>
                      <input
                        class="form-control"
                        id="contactWageName{{ i }}"
                        name="contactWageName{{ i }}"
                        [(ngModel)]="contactWage.name"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactWageTelefon{{ i }}">Telefon: </label>
                      <input
                        class="form-control"
                        id="contactWageTelefon{{ i }}"
                        name="contactWageTelefon{{ i }}"
                        [(ngModel)]="contactWage.telefon"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactWageEmail{{ i }}">Email: </label>
                      <a href="mailto:{{ contactWage.emailText }}"
                        ><input
                          class="form-control"
                          id="contactWageEmail{{ i }}"
                          name="contactWageEmail{{ i }}"
                          [(ngModel)]="contactWage.emailText"
                          [readonly]="true"
                          [disabled]="true"
                          size="40"
                      /></a>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>

          <div *ngIf="deptFields.kontaktperson">
            <div class="form-group row">
              <h4 class="mb-2">
                <span>Kontaktperson</span>
              </h4>
              <mat-tab-group
                *ngIf="organization.contactPerson[0]?.id !== undefined"
                animationDuration="0ms"
              >
                <mat-tab
                  *ngFor="
                    let contactPerson of organization.contactPerson;
                    let i = index
                  "
                  label="{{ i + 1 }}"
                >
                  <div class="form-group row" style="padding-top: 10px">
                    <div class="col-sm">
                      <label for="contactPersonName{{ i }}">Name: </label>
                      <input
                        class="form-control"
                        id="contactPersonName{{ i }}"
                        name="contactPersonName{{ i }}"
                        [(ngModel)]="contactPerson.name"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactPersonTelefon{{ i }}">Telefon: </label>
                      <input
                        class="form-control"
                        id="contactPersonTelefon{{ i }}"
                        name="contactPersonTelefon{{ i }}"
                        [(ngModel)]="contactPerson.telefon"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactPersonEmail{{ i }}">Email: </label>
                      <a href="mailto:{{ contactPerson.emailText }}"
                        ><input
                          class="form-control"
                          id="contactPersonEmail{{ i }}"
                          name="contactPersonEmail{{ i }}"
                          [(ngModel)]="contactPerson.emailText"
                          [readonly]="true"
                          [disabled]="true"
                          size="40"
                      /></a>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>

            <div *ngIf="deptFields.leitendeHebamme" class="form-group row">
              <h4 class="mb-2">Leitende Hebamme</h4>
              <div class="col-sm">
                <label for="contactMidwifeName">Name: </label>
                <input
                  class="form-control"
                  id="contactMidwifeName"
                  name="contactMidwifeName"
                  [(ngModel)]="organization.contactMidwife.name"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactMidwifeTelefon">Telefon: </label>
                <input
                  class="form-control"
                  id="contactMidwifeTelefon"
                  name="contactMidwifeTelefon"
                  [(ngModel)]="organization.contactMidwife.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactMidwifeEmail">Email: </label>
                <a href="mailto:{{ organization.contactMidwife.emailText }}"
                  ><input
                    class="form-control"
                    id="contactMidwifeEmail"
                    name="contactMidwifeEmail"
                    [(ngModel)]="organization.contactMidwife.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
            </div>

            <div *ngIf="deptFields.leitungWochenbett" class="form-group row">
              <h4 class="mb-2">Leitung Wochenbett</h4>
              <div class="col-sm">
                <label for="contactWeekbedName">Name: </label>
                <input
                  class="form-control"
                  id="contactWeekbedName"
                  name="contactWeekbedName"
                  [(ngModel)]="organization.contactWeekbed.name"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactWeekbedTelefon">Telefon: </label>
                <input
                  class="form-control"
                  id="contactWeekbedTelefon"
                  name="contactWeekbedTelefon"
                  [(ngModel)]="organization.contactWeekbed.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactWeekbedEmail">Email: </label>
                <a href="mailto:{{ organization.contactWeekbed.emailText }}"
                  ><input
                    class="form-control"
                    id="contactWeekbedEmail"
                    name="contactWeekbedEmail"
                    [(ngModel)]="organization.contactWeekbed.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
            </div>

            <div *ngIf="deptFields.pflegedienstleitung" class="form-group row">
              <h4 class="mb-2">Pflegedienstleitung</h4>
              <div class="col-sm">
                <label for="contactNursingName">Name: </label>
                <input
                  class="form-control"
                  id="contactNursingName"
                  name="contactNursingName"
                  [(ngModel)]="organization.contactNursing.name"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactNursingTelefon">Telefon: </label>
                <input
                  class="form-control"
                  id="contactNursingTelefon"
                  name="contactNursingTelefon"
                  [(ngModel)]="organization.contactNursing.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactNursingEmail">Email: </label>
                <a href="mailto:{{ organization.contactNursing.emailText }}"
                  ><input
                    class="form-control"
                    id="contactNursingEmail"
                    name="contactNursingEmail"
                    [(ngModel)]="organization.contactNursing.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
            </div>
          </div>

          <div *ngIf="deptFields.pflegeFields">
            <div class="form-group row">
              <h4 class="mb-2">Fachverantwortliche FH</h4>
              <div class="col-sm">
                <label for="contactPraxisTrainerName">Name: </label>
                <input
                  class="form-control"
                  id="contactPraxisTrainerName"
                  name="contactPraxisTrainerName"
                  [(ngModel)]="organization.contactPraxisTrainer.name"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactPraxisTrainerTelefon">Telefon: </label>
                <input
                  class="form-control"
                  id="contactPraxisTrainerTelefon"
                  name="contactPraxisTrainerTelefon"
                  [(ngModel)]="organization.contactPraxisTrainer.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactPraxisTrainerEmail">Email: </label>
                <a
                  href="mailto:{{
                    organization.contactPraxisTrainer.emailText
                  }}"
                  ><input
                    class="form-control"
                    id="contactPraxisTrainerEmail"
                    name="contactPraxisTrainerEmail"
                    [(ngModel)]="organization.contactPraxisTrainer.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
            </div>

            <div class="form-group row">
              <h4 class="mb-2">Kontaktperson Administration</h4>
              <div class="col-sm">
                <label for="contactAdministrationName">Name: </label>
                <input
                  class="form-control"
                  id="contactAdministrationName"
                  name="contactAdministrationName"
                  [(ngModel)]="organization.contactAdministration.name"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactAdministrationTelefon">Telefon: </label>
                <input
                  class="form-control"
                  id="contactAdministrationTelefon"
                  name="contactAdministrationTelefon"
                  [(ngModel)]="organization.contactAdministration.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactAdministrationEmail">Email: </label>
                <a
                  href="mailto:{{
                    organization.contactAdministration.emailText
                  }}"
                  ><input
                    class="form-control"
                    id="contactAdministrationEmail"
                    name="contactAdministrationEmail"
                    [(ngModel)]="organization.contactAdministration.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
            </div>

            <div class="form-group row">
              <h4 class="mb-2">Pflegedirektion</h4>
              <div class="col-sm">
                <label for="contactDirectionName">Name: </label>
                <input
                  class="form-control"
                  id="contactDirectionName"
                  name="contactDirectionName"
                  [(ngModel)]="organization.contactDirection.name"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactDirectionTelefon">Telefon: </label>
                <input
                  class="form-control"
                  id="contactDirectionTelefon"
                  name="contactDirectionTelefon"
                  [(ngModel)]="organization.contactDirection.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactDirectionEmail">Email: </label>
                <a href="mailto:{{ organization.contactDirection.emailText }}"
                  ><input
                    class="form-control"
                    id="contactDirectionEmail"
                    name="contactDirectionEmail"
                    [(ngModel)]="organization.contactDirection.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
            </div>
          </div>

          <div *ngIf="deptFields.physioFields">
            <div class="form-group row">
              <h4 class="mb-2">Ansprechperson Hospitation 1-3. Semester</h4>
              <mat-tab-group
                *ngIf="organization.contactHospitationOne[0]?.id !== undefined"
                animationDuration="0ms"
              >
                <mat-tab
                  *ngFor="
                    let contactHospitationOne of organization.contactHospitationOne;
                    let i = index
                  "
                  label="{{ i + 1 }}"
                >
                  <div class="form-group row" style="padding-top: 10px">
                    <div class="col-sm">
                      <label for="contactHospitationOneName{{ i }}"
                        >Name:
                      </label>
                      <input
                        class="form-control"
                        id="contactHospitationOneName{{ i }}"
                        name="contactHospitationOneName{{ i }}"
                        [(ngModel)]="contactHospitationOne.name"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactHospitationOneTelefon{{ i }}"
                        >Telefon:
                      </label>
                      <input
                        class="form-control"
                        id="contactHospitationOneTelefon{{ i }}"
                        name="contactHospitationOneTelefon{{ i }}"
                        [(ngModel)]="contactHospitationOne.telefon"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactHospitationOneEmail{{ i }}"
                        >Email:
                      </label>
                      <a href="mailto:{{ contactHospitationOne.emailText }}"
                        ><input
                          class="form-control"
                          id="contactHospitationOneEmail{{ i }}"
                          name="contactHospitationOneEmail{{ i }}"
                          [(ngModel)]="contactHospitationOne.emailText"
                          [readonly]="true"
                          [disabled]="true"
                          size="40"
                      /></a>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>

            <div class="form-group row">
              <h4 class="mb-2">Ansprechperson Hospitation 6. Semester</h4>
              <div class="col-sm">
                <label for="contactHospitationTwoName">Name: </label>
                <input
                  class="form-control"
                  id="contactHospitationTwoName"
                  name="contactHospitationTwoName"
                  [(ngModel)]="organization.contactHospitationTwo.name"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactHospitationTwoTelefon">Telefon: </label>
                <input
                  class="form-control"
                  id="contactHospitationTwoTelefon"
                  name="contactHospitationTwoTelefon"
                  [(ngModel)]="organization.contactHospitationTwo.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactHospitationTwoEmail">Email: </label>
                <a
                  href="mailto:{{
                    organization.contactHospitationTwo.emailText
                  }}"
                  ><input
                    class="form-control"
                    id="contactHospitationTwoEmail"
                    name="contactHospitationTwoEmail"
                    [(ngModel)]="organization.contactHospitationTwo.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
            </div>

            <div class="form-group row">
              <h4 class="mb-2">Ansprechperson Bsc</h4>
              <mat-tab-group
                *ngIf="organization.contactBsc[0]?.id !== undefined"
                animationDuration="0ms"
              >
                <mat-tab
                  *ngFor="
                    let contactBsc of organization.contactBsc;
                    let i = index
                  "
                  label="{{ i + 1 }}"
                >
                  <div class="form-group row" style="padding-top: 10px">
                    <div class="col-sm">
                      <label for="contactBscName{{ i }}">Name: </label>
                      <input
                        class="form-control"
                        id="contactBscName{{ i }}"
                        name="contactBscName{{ i }}"
                        [(ngModel)]="contactBsc.name"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactBscTelefon{{ i }}">Telefon: </label>
                      <input
                        class="form-control"
                        id="contactBscTelefon{{ i }}"
                        name="contactBscTelefon{{ i }}"
                        [(ngModel)]="contactBsc.telefon"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactBscEmail{{ i }}">Email: </label>
                      <a href="mailto:{{ contactBsc.emailText }}"
                        ><input
                          class="form-control"
                          id="contactBscEmail{{ i }}"
                          name="contactBscEmail{{ i }}"
                          [(ngModel)]="contactBsc.emailText"
                          [readonly]="true"
                          [disabled]="true"
                          size="40"
                      /></a>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>

            <div class="form-group row">
              <h4 class="mb-2">Ansprechperson ZMC</h4>
              <mat-tab-group
                *ngIf="organization.contactZmc[0]?.id !== undefined"
                animationDuration="0ms"
              >
                <mat-tab
                  *ngFor="
                    let contactZmc of organization.contactZmc;
                    let i = index
                  "
                  label="{{ i + 1 }}"
                >
                  <div class="form-group row" style="padding-top: 10px">
                    <div class="col-sm">
                      <label for="contactZmcName{{ i }}">Name: </label>
                      <input
                        class="form-control"
                        id="contactZmcName{{ i }}"
                        name="contactZmcName{{ i }}"
                        [(ngModel)]="contactZmc.name"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactZmcTelefon{{ i }}">Telefon: </label>
                      <input
                        class="form-control"
                        id="contactZmcTelefon{{ i }}"
                        name="contactZmcTelefon{{ i }}"
                        [(ngModel)]="contactZmc.telefon"
                        [readonly]="true"
                        [disabled]="true"
                        size="40"
                      />
                    </div>
                    <div class="col-sm">
                      <label for="contactZmcEmail{{ i }}">Email: </label>
                      <a href="mailto:{{ contactZmc.emailText }}"
                        ><input
                          class="form-control"
                          id="contactZmcEmail{{ i }}"
                          name="contactZmcEmail{{ i }}"
                          [(ngModel)]="contactZmc.emailText"
                          [readonly]="true"
                          [disabled]="true"
                          size="40"
                      /></a>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>

            <div class="form-group row">
              <h4 class="mb-2">Leitung Physiotherapie</h4>
              <div class="col-sm">
                <label for="contactPhysioName">Name: </label>
                <input
                  class="form-control"
                  id="contactPhysioName"
                  name="contactPhysioName"
                  [(ngModel)]="organization.contactPhysio.name"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactPhysioTelefon">Telefon: </label>
                <input
                  class="form-control"
                  id="contactPhysioTelefon"
                  name="contactPhysioTelefon"
                  [(ngModel)]="organization.contactPhysio.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="contactPhysioEmail">Email: </label>
                <a href="mailto:{{ organization.contactPhysio.emailText }}"
                  ><input
                    class="form-control"
                    id="contactPhysioEmail"
                    name="contactPhysioEmail"
                    [(ngModel)]="organization.contactPhysio.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
            </div>
          </div>

          <div *ngIf="deptFields.ergotherapieFields" class="row">
            <div class="col-sm">
              <mat-accordion>
                <mat-expansion-panel
                  *ngFor="let forSubjectArea of organization.internshipMatrix"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Verfügbarkeit Fachbereich
                      {{ forSubjectArea?.subjectArea?.name }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-tab-group animationDuration="0ms">
                    <mat-tab
                      *ngFor="let forYear of forSubjectArea.items"
                      label="{{ forYear.year }}"
                      ><mat-table #table [dataSource]="forYear.items">
                        <ng-container matColumnDef="internshiptype">
                          <th mat-header-cell *matHeaderCellDef>
                            Praktikumstyp
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.internshiptype.name }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="kw">
                          <th mat-header-cell *matHeaderCellDef>KW</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.kw }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="nextStartDate">
                          <th mat-header-cell *matHeaderCellDef>
                            Nächstes Startdatum
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{
                              this.datepipe.transform(
                                element.nextStartDate,
                                "dd.MM.yyyy"
                              )
                            }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="nextEndDate">
                          <th mat-header-cell *matHeaderCellDef>
                            Nächstes Enddatum
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{
                              this.datepipe.transform(
                                element.nextEndDate,
                                "dd.MM.yyyy"
                              )
                            }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="planned">
                          <th mat-header-cell *matHeaderCellDef>
                            Geplante Plätze
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <input
                              mat-input
                              class="form-control table"
                              name="planned"
                              [value]="element.planned"
                              (change)="plannedChangeFn($event, element)"
                            />
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="offered">
                          <th mat-header-cell *matHeaderCellDef>
                            Verfügbare Plätze
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <input
                              class="form-control table"
                              id="offered"
                              name="offered"
                              [value]="element.offered"
                              (change)="offeredChangeFn($event, element)"
                            />
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="reserved">
                          <th mat-header-cell *matHeaderCellDef>
                            Reservestellen
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <input
                              class="form-control table"
                              id="reserved"
                              name="reserved"
                              [value]="element.reserved"
                              (change)="reservedChangeFn($event, element)"
                            />
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="occupiedPlaces">
                          <th mat-header-cell *matHeaderCellDef>
                            Belegte Plätze
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.occupiedPlaces }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="freePlaces">
                          <th mat-header-cell *matHeaderCellDef>
                            Freie Plätze
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.freePlaces }}
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="displayedColumns"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="let row; columns: displayedColumns"
                        ></tr></mat-table
                    ></mat-tab>
                  </mat-tab-group>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>

          <button class="btn btn-primary float-right" (click)="onSubmit()">
            Speichern
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
