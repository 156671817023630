import { createAction, props } from '@ngrx/store';

export const setStudentsRows = createAction(
  '[Students Page] Students',
  props<{ rows: number }>()
);

export const setMentorsRows = createAction(
  '[Mentors Page] Mentors',
  props<{ rows: number }>()
);

export const setTrainersRows = createAction(
  '[Trainers Page] Trainers',
  props<{ rows: number }>()
);

export const setPracticeOrganizationsRows = createAction(
  '[PracticeOrganizations Page] PracticeOrganizations',
  props<{ rows: number }>()
);

export const setInternshipsRows = createAction(
  '[Internships Page] Internships',
  props<{ rows: number }>()
);

export const setInternshiptypesRows = createAction(
  '[Internshiptypes Page] Internshiptypes',
  props<{ rows: number }>()
);

export const setInternshipperiodsRows = createAction(
  '[Internshipperiods Page] Internshipperiods',
  props<{ rows: number }>()
);

export const setSubjectareasRows = createAction(
  '[Subjectareas Page] Subjectareas',
  props<{ rows: number }>()
);
