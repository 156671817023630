import { Gradingscale } from './gradingscale';

export class Internshiptype {
  public id?: number;
  public orgunitId?: number;
  public gradingScale?: Gradingscale;
  public identifier?: string;
  public name?: string;
  public startcalendarweek?: number;
  public nextstartdate?: string;
  public nextenddate?: string;
  public durationInWeeks: number;
  public deadlineForSubmissionOfTargetInterviewInWeeks: number;
  public internshipTypeIdLookup: number;
  public deadlineForAssessmentFormDeliveryShouldInWeeks: number;
  public workload: number;
  public trainingYear: number;
}
