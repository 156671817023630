import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.angularAppId,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  praxistoolApi: {
    endpoints: [
      `${environment.apiUrl}/api/pt/gradingscale`,
      `${environment.apiUrl}/api/pt/internship`,
      `${environment.apiUrl}/api/pt/internshipperiod`,
      `${environment.apiUrl}/api/pt/internshiptype`,
      `${environment.apiUrl}/api/pt/mentor`,
      `${environment.apiUrl}/api/pt/organizationalunit`,
      `${environment.apiUrl}/api/pt/practiceorganization`,
      `${environment.apiUrl}/api/pt/student`,
      `${environment.apiUrl}/api/pt/subjectarea`,
      `${environment.apiUrl}/api/pt/trainer`,
      `${environment.apiUrl}/api/pt/report`,
    ],
    scopes: [`api://${environment.webApiAppId}/access`],
  },
};
