import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { setMentorsRows } from '../../../state/rows-selection.actions';
import { MentorService } from '../../../service/mentor.service';
import { Mentor, Mentor as Mentors } from '../../../service/model/mentor';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { Table, TableCheckbox, TableLazyLoadEvent } from 'primeng/table';
import { UtilsService } from '../../../service/utils.service';
import { DeptFieldsMentors } from '../mentor-fields';
import { InfoService } from '../../../service/info.service';

@Component({
  selector: 'app-mentors',
  templateUrl: '../mentors/mentors.component.html',
  styleUrls: ['../mentors/mentors.component.css'],
  providers: [MentorService],
})
export class MentorSelectDialogComponent implements OnInit {
  mentors: Mentors[];
  selectedMentors: Mentors[];
  totalRecords: number;
  isForSelection = true;
  rowsSelectionMentors$: Observable<number>;
  @ViewChild('dt') table: Table;
  selectAllChecked = false;
  cols: any[];
  departmentId: BehaviorSubject<string>;
  deptFields: DeptFieldsMentors;
  visibleForRefresh: boolean = true;
  showInactive = false;

  constructor(
    private mentorService: MentorService,
    private dialogRef: MatDialogRef<MentorSelectDialogComponent>,
    private store: Store<{ rowsSelectionMentors: number }>,
    private dialog: MatDialog,
    private utilsService: UtilsService,
    private infoService: InfoService
  ) {
    this.rowsSelectionMentors$ = store.select('rowsSelectionMentors');
    //console.log(this.rowsSelectionMentors$);
  }

  loading: boolean;

  ngOnInit(): void {
    this.loading = true;

    this.departmentId = this.infoService.getDepartmentId();

    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(data, 'mentors');
    });
  }

  filter(event: TableLazyLoadEvent) {}

  loadMentors(event: TableLazyLoadEvent) {
    this.loading = true;

    //console.log(event.rows)
    this.store.dispatch(setMentorsRows({ rows: event.rows }));
    this.rowsSelectionMentors$ = this.store.select('rowsSelectionMentors');

    //console.log(event);
    setTimeout(() => {
      if (this.showInactive) {
        this.mentorService
          .getListWithInactive(event)
          .then((data) => {
            this.mentors = data['records'];
            this.totalRecords = data['count'];

            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message:
                  'Studierendenbegleitende konnten nicht geladen werden.',
              },
            });
          });
      } else {
        this.mentorService
          .getList(event)
          .then((data) => {
            this.mentors = data['records'];
            this.totalRecords = data['count'];

            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message:
                  'Studierendenbegleitende konnten nicht geladen werden.',
              },
            });
          });
      }
    }, 1000);
  }

  selectMentor(mentor: Mentor) {
    this.dialogRef.close(mentor);
  }

  checkboxTable(tableCheckBox: TableCheckbox, event: any): void {}

  selectAll(): void {}

  downloadExport(): void {}

  checkShowInactive() {
    this.selectAllChecked = false;
    this.selectAll();
    this.visibleForRefresh = false;
    setTimeout(() => (this.visibleForRefresh = true), 0);
  }
}
