import { SubjectareaAssignment } from './subjectarea-assignment';
import { Internshipperiod } from './internshipperiod';
import { Internshiptype } from './internshiptype';
import { Mentor } from './mentor';
import { PracticeOrganization } from './practiceorganization';
import { Student } from './student';
import { Uploaddocument } from './uploaddocument';
import { Trainer } from './trainer';

export class Internship {
  id?: string;
  student?: Student;
  practiceOrganization?: PracticeOrganization;
  trainer?: Trainer;
  trainerII?: Trainer;
  mentor?: Mentor;

  studentNameComp?: string;
  organizationNameComp?: string;
  trainerNameComp?: string;
  mentorNameComp?: string;
  internshipType?: string;
  subjectAreasNameComp?: string;

  startdate?: string;
  enddate?: string;

  workingDays?: string;
  workload: number;
  comment?: string;
  internalComments?: string;
  shouldDateAssessmentForm?: string;
  isDateAssessmentForm?: string;
  shouldDateContract?: string;

  isDateContract?: string;
  type?: Internshiptype;
  internshipPeriod?: Internshipperiod;

  state?: string;
  stateId?: string;
  studId?: string;
  studbegId?: string;
  praxisTrainerId?: string;
  orgId?: string;
  internshipTypeId?: string;
  subjectAreas: SubjectareaAssignment[];

  eventId: number;
  feedback?: string;

  goalSettingDocument: Uploaddocument;
  evaluationDocument: Uploaddocument;

  grade?: string;
  gradeDate?: string;
  gradeFixed: boolean;
  planningYear: number;
  specialCase: boolean;
  specialCaseNotes?: string;
  workingHours: number;
  conditionNotes?: string;
  assessmentSheetAssignmentOk?: string;
  voucherDate?: string;
  workflowId: number;
  workflowstateName?: string;
  dataSourceObjectIdWorkflowState?: string;
  registrationDate?: string;
  chir: boolean;
  im: boolean;
  rh: boolean;
  rhAmbi: boolean;
  n: boolean;
  g: boolean;
  gyn: boolean;
  uro: boolean;
  ips: boolean;
  internshipCreated?: string;
  contractShortened?: string;
  contractExtended?: string;
  invoiceAmount?: string;
  directEmployment: boolean;
  dateContractOk?: string;
  assessmentSheetShippingShould?: string;
  assessmentSheetShippingIs?: string;
  assessmentSheetDeliveryOk?: string;
  voucher?: string;
  assessmentFormReceiptShould?: string;
  assessmentFormReceiptIs?: string;
  isAbgeschlossenOderArchiviert: boolean;
  contractShould?: string;
  contractIs?: string;
  internshipConfirmationAndCompulsoryAttendanceShould?: string;
  internshipConfirmationAndCompulsoryAttendanceIs?: string;
  shippmentConfirmation?: string;
  documentsIncomplete: boolean;
  timePlanningNote?: string;
  absences?: string;
  netWorkingHours?: string;
  formativeFinalAssessment?: string;
  registered?: string;
  passedId?: string;
  zmcGradeId?: string;
  workNote?: string;

  practiceOrganizationSpecialistForum?: PracticeOrganization;
  selectedStudyState?: string;
  selectedStudyStateText?: string;

  targetAgreementConceptReceiptIs?: string;
  targetAgreementConceptReceiptShould?: string;

  targetAgreementFinalReceiptIs?: string;
  targetAgreementFinalReceiptShould?: string;

  targetSettingMeetingProposalsReceiptIs?: string;
  targetSettingMeetingProposalsReceiptShould?: string;

  interrupted: boolean;
  cancelled: boolean;

  trainingYear: number;
}
