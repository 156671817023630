import { Component, OnInit, ViewChild } from '@angular/core';
import { PracticeOrganizationService } from '../../../service/practiceorganization.service';
import { PracticeOrganization } from '../../../service/model/practiceorganization';
import { setPracticeOrganizationsRows } from '../../../state/rows-selection.actions';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeptFieldsPracticeOrganizations } from '../practiceorganisation-fields';
import { UtilsService } from '../../../service/utils.service';
import { InfoService } from '../../../service/info.service';
import { Table, TableCheckbox, TableLazyLoadEvent } from 'primeng/table';
import { downloadFile } from '../../../shared-layout/shared.tools';
import { ConfirmDialogComponent } from '../../../shared-layout/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-practice-organizations',
  templateUrl: './practice-organizations.component.html',
  styleUrls: ['./practice-organizations.component.css'],
  providers: [PracticeOrganizationService],
})
export class PracticeOrganizationsComponent implements OnInit {
  PracticeOrganizations: PracticeOrganization[];
  selectedPracticeOrganizations: PracticeOrganization[];
  totalRecords: number;
  loading: boolean;
  isForSelection = false;
  rowsSelectionPracticeOrganizations$: Observable<number>;
  departmentId: BehaviorSubject<string>;
  deptFields: DeptFieldsPracticeOrganizations;
  @ViewChild('dt') table: Table;
  selectAllChecked = false;
  cols: any[];
  filename = 'Praxisorganisationen';
  visibleForRefresh: boolean = true;
  showInactive = false;

  constructor(
    private practiceOrganizationService: PracticeOrganizationService,
    private utilsService: UtilsService,
    private store: Store<{
      rowsSelectionPracticeOrganizations: number;
      dataSourceObjectId: string;
    }>,
    private dialog: MatDialog,
    private infoService: InfoService
  ) {
    this.rowsSelectionPracticeOrganizations$ = store.select(
      'rowsSelectionPracticeOrganizations'
    );
    //console.log(this.rowsSelectionPracticeOrganizations$);
  }

  ngOnInit(): void {
    this.loading = true;

    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(
        data,
        'practiceOrganizations'
      );
      // Nur für Testing alle Felder anzeigen
      //this.deptFields = deptFieldsPracticeOrganizationsShowAll;
    });

    //cols for export
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'addressText', header: 'Adresse' },
      { field: 'cityText', header: 'Stadt' },
      { field: 'emailText', header: 'E-Mail' },
      { field: 'telefon', header: 'Telefon' },
      { field: 'id', header: 'Id' },
    ];
  }

  filter(event: TableLazyLoadEvent) {
    //If search critera changes undo selection
    this.undoSelection();
  }

  loadOrganizations(event: TableLazyLoadEvent) {
    this.loading = true;

    //console.log(event.rows)
    this.store.dispatch(setPracticeOrganizationsRows({ rows: event.rows }));
    this.rowsSelectionPracticeOrganizations$ = this.store.select(
      'rowsSelectionPracticeOrganizations'
    );

    //console.log(event);
    setTimeout(() => {
      if (this.showInactive) {
        this.practiceOrganizationService
          .getListWithInactive(event)
          .then((data) => {
            if (data) {
              this.PracticeOrganizations = data['records'];
              this.totalRecords = data['count'];
            }
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Praxisorganisationen konnten nicht geladen werden.',
              },
            });
          });
      } else {
        this.practiceOrganizationService
          .getList(event)
          .then((data) => {
            if (data) {
              this.PracticeOrganizations = data['records'];
              this.totalRecords = data['count'];
            }
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Praxisorganisationen konnten nicht geladen werden.',
              },
            });
          });
      }
    }, 1000);
  }

  selectPracticeOrganization(practiceOrganization: PracticeOrganization) {}

  checkboxTable(tableCheckBox: TableCheckbox, event: any): void {
    //console.log(event);
    const isChecked = !!event.target.ariaChecked;
    if (event.button === 0 && event.shiftKey) {
      if (isChecked) {
        this.table.selectRange(event, tableCheckBox.index);
      } else {
        this.table.rangeRowIndex = tableCheckBox.index;
        this.table.clearSelectionRange(event);
        this.table.tableService.onSelectionChange();
      }
    }
    this.table.anchorRowIndex = tableCheckBox.index;
  }

  selectAll() {
    if (!this.selectAllChecked) {
      this.selectedPracticeOrganizations = [];
    } else {
      if (this.totalRecords > 1500) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            message:
              'Sollen alle Praxisorganisationen ausgewählt werden? Das kann eventuell mehrere Minuten dauern.',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectAllRequest();
          } else {
            this.selectAllChecked = false;
          }
        });
      } else {
        this.selectAllRequest();
      }
    }
  }

  selectAllRequest(): void {
    this.loading = true;
    const event = this.table.createLazyLoadMetadata() as TableLazyLoadEvent;
    this.practiceOrganizationService
      .getListforExport(event)
      .then((data) => {
        this.selectedPracticeOrganizations = data['records'];
        //console.log(data);
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Die Praxisorganisationen für den Export konnten nicht geladen werden.',
          },
        });
      });
  }

  downloadExport(): void {
    downloadFile(this.selectedPracticeOrganizations, this.cols, this.filename);
  }

  undoSelection(): void {
    this.selectAllChecked = false;
    this.selectedPracticeOrganizations = [];
  }

  checkShowInactive() {
    this.selectAllChecked = false;
    this.selectAll();
    this.visibleForRefresh = false;
    setTimeout(() => (this.visibleForRefresh = true), 0);
  }
}
