import { createReducer, on } from '@ngrx/store';
import {
  setInternshipperiodsRows,
  setInternshipsRows,
  setInternshiptypesRows,
  setMentorsRows,
  setPracticeOrganizationsRows,
  setStudentsRows,
  setSubjectareasRows,
  setTrainersRows,
} from './rows-selection.actions';

export const rowsSelectionStudents = 10;
export const rowsSelectionStudentsReducer = createReducer(
  rowsSelectionStudents,
  on(setStudentsRows, (state, { rows }) => rows)
);

export const rowsSelectionMentors = 10;
export const rowsSelectionMentorsReducer = createReducer(
  rowsSelectionMentors,
  on(setMentorsRows, (state, { rows }) => rows)
);

export const rowsSelectionTrainers = 10;
export const rowsSelectionTrainersReducer = createReducer(
  rowsSelectionTrainers,
  on(setTrainersRows, (state, { rows }) => rows)
);

export const rowsSelectionPracticeOrganizations = 10;
export const rowsSelectionPracticeOrganizationsReducer = createReducer(
  rowsSelectionPracticeOrganizations,
  on(setPracticeOrganizationsRows, (state, { rows }) => rows)
);

export const rowsSelectionInternships = 10;
export const rowsSelectionInternshipsReducer = createReducer(
  rowsSelectionInternships,
  on(setInternshipsRows, (state, { rows }) => rows)
);

export const rowsSelectionInternshiptypes = 10;
export const rowsSelectionInternshiptypesReducer = createReducer(
  rowsSelectionInternshiptypes,
  on(setInternshiptypesRows, (state, { rows }) => rows)
);

export const rowsSelectionInternshipperiods = 10;
export const rowsSelectionInternshipperiodsReducer = createReducer(
  rowsSelectionInternshipperiods,
  on(setInternshipperiodsRows, (state, { rows }) => rows)
);

export const rowsSelectionSubjectareas = 10;
export const rowsSelectionSubjectareasReducer = createReducer(
  rowsSelectionSubjectareas,
  on(setSubjectareasRows, (state, { rows }) => rows)
);
