import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { getErrorMessage } from '../shared-layout/shared.tools';
import { GridOptions } from './model/grid-options';
import { ReportData } from './model/report-data';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../shared-layout/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  readonly extendedUserEndpoint: string = `${environment.apiUrl}/api/pt/report`;
  gridOptions: GridOptions;
  showDialogTime = 2000;

  constructor(private http: HttpClient, public dialog: MatDialog) {
    this.gridOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }

  getInternshipReportPdf(reportData: ReportData) {
    return lastValueFrom(
      this.http
        .post(
          this.extendedUserEndpoint + '/internship',
          JSON.stringify(reportData),
          {
            headers: this.gridOptions.headers,
            responseType: 'blob',
          }
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Reporte für Ausbildungsverhältnisse konnten nicht generiert werden.'
                  )
                )
            )
          )
        )
    );
  }

  getInternshipReportZip(reportData: ReportData) {
    return lastValueFrom(
      this.http
        .post(
          this.extendedUserEndpoint + '/internship',
          JSON.stringify(reportData),
          {
            headers: this.gridOptions.headers,
            responseType: 'arraybuffer',
          }
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Reporte für Ausbildungsverhältnisse konnten nicht generiert werden.'
                  )
                )
            )
          )
        )
    );
  }

  getPersonReportPdf(reportData: ReportData) {
    return lastValueFrom(
      this.http
        .post(
          this.extendedUserEndpoint + '/person',
          JSON.stringify(reportData),
          {
            headers: this.gridOptions.headers,
            responseType: 'blob',
          }
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Reporte für Personen konnten nicht generiert werden.'
                  )
                )
            )
          )
        )
    );
  }

  getPersonReportZip(reportData: ReportData) {
    return lastValueFrom(
      this.http
        .post(
          this.extendedUserEndpoint + '/person',
          JSON.stringify(reportData),
          {
            headers: this.gridOptions.headers,
            responseType: 'arraybuffer',
          }
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Reporte für Personen konnten nicht generiert werden.'
                  )
                )
            )
          )
        )
    );
  }

  getInternshipReport(
    reportGuid: string,
    filename: string,
    ids: number[]
  ): void {
    if (ids.length == 0) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Es sind keine Ausbildungsverhältnisse ausgewählt.',
        },
      });
    }

    if (ids.length == 1) {
      var reportData = new ReportData();
      reportData.ids = ids;
      reportData.reportGuid = reportGuid;
      this.getInternshipReportPdf(reportData)
        .then((data: any) => {
          var blob = new Blob([data], { type: 'application/pdf' });
          this.downloadBlobWithoutDate(blob, filename, '.pdf');
        })
        .catch((e) => {
          console.error(e);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message:
                'Report für Ausbildungsverhältnis konnten nicht generiert werden.',
            },
          });
        });
    }
    if (ids.length > 1) {
      const dialogRef2 = this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Export von Ausbildungsverhältnissen gestartet',
        },
      });
      dialogRef2.afterOpened().subscribe(() => {
        setTimeout(() => {
          dialogRef2.close();
        }, this.showDialogTime);
      });

      const start = 0;
      const batchSize = 100;
      this.getReportsStart(start, batchSize, reportGuid, filename, ids);
    }
  }

  getReportsStart(
    start: number,
    batchSize: number,
    reportGuid: string,
    filename: string,
    ids: number[]
  ) {
    let end: number;
    if (start + batchSize >= ids.length) {
      end = ids.length;
    } else {
      end = start + batchSize;
    }
    const idsPage = ids.slice(start, end);
    if (idsPage.length == 0) {
      return;
    }
    const startplusone = start + 1;

    var reportData = new ReportData();
    reportData.ids = idsPage;
    reportData.reportGuid = reportGuid;

    this.getInternshipReportZip(reportData)
      .then((data: any) => {
        const blob = new Blob([data], {
          type: 'application/zip',
        });

        var filenameI =
          filename +
          '_Nr.' +
          startplusone.toString() +
          '-' +
          end.toString() +
          '_';
        this.downloadBlob(blob, filenameI, '.zip');

        const dialogRef2 = this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Reporte für Ausbildungsverhältnissen Nr ' +
              startplusone.toString() +
              '-' +
              end.toString() +
              ' fertig',
          },
        });
        dialogRef2.afterOpened().subscribe(() => {
          setTimeout(() => {
            dialogRef2.close();
          }, this.showDialogTime);
        });

        if (start + batchSize <= ids.length) {
          const nextStart = start + batchSize;
          this.getReportsStart(nextStart, batchSize, reportGuid, filename, ids);
        } else {
          const dialogRef2 = this.dialog.open(ErrorDialogComponent, {
            data: {
              message: 'Alle Reporte fertig.',
            },
          });
          dialogRef2.afterOpened().subscribe(() => {
            setTimeout(() => {
              dialogRef2.close();
            }, this.showDialogTime);
          });
        }
      })
      .catch((e) => {
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Reporte für Ausbildungsverhältnisse konnten nicht generiert werden.',
          },
        });
      });
  }

  getPersonReport(reportGuid: string, filename: string, ids: number[]): void {
    if (ids.length == 0) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Es sind keine Personen ausgewählt.',
        },
      });
    }
    var reportData = new ReportData();
    reportData.ids = ids;
    reportData.reportGuid = reportGuid;

    if (ids.length == 1) {
      this.getPersonReportPdf(reportData)
        .then((data: any) => {
          var blob = new Blob([data], { type: 'application/pdf' });
          this.downloadBlobWithoutDate(blob, filename, '.pdf');
        })
        .catch((e) => {
          console.error(e);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message: 'Report für Person konnte nicht generiert werden.',
            },
          });
        });
    }
    if (ids.length > 1) {
      this.getPersonReportZip(reportData)
        .then((data: any) => {
          const blob = new Blob([data], {
            type: 'application/zip',
          });
          this.downloadBlob(blob, filename, '.zip');
        })
        .catch((e) => {
          console.error(e);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message: 'Reporte für Personen konnten nicht generiert werden.',
            },
          });
        });
    }
  }

  downloadBlob(blob: Blob, filename: string, fileending: string): void {
    var fileURL = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = fileURL;
    a.target = '_blank';
    var today = new Date();
    a.download =
      filename +
      today.getDate() +
      '.' +
      today.getMonth() +
      '.' +
      today.getFullYear() +
      '_' +
      today.getHours() +
      '.' +
      today.getMinutes() +
      fileending;
    document.body.appendChild(a);
    a.click();
  }

  downloadBlobWithoutDate(
    blob: Blob,
    filename: string,
    fileending: string
  ): void {
    var fileURL = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = fileURL;
    a.target = '_blank';
    a.download = filename + fileending;
    document.body.appendChild(a);
    a.click();
  }
}
