import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Internshiptype } from './model/internshiptype';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { getErrorMessage } from '../shared-layout/shared.tools';
import { GridOptions } from './model/grid-options';
import { RadioGroupEntry } from './model/radiogroupentry';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable({
  providedIn: 'root',
})
export class InternshiptypesService {
  gridOptions: GridOptions;
  apiURL = environment.apiUrl;
  readonly extendedUserEndpoint: string = `${environment.apiUrl}/api/pt/internshiptype`;

  constructor(private http: HttpClient) {
    this.gridOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }

  getDummyData() {
    return lastValueFrom(
      this.http.get<any>('../../assets/internshiptypes.json')
    )
      .then((res) => <Internshiptype[]>res.data)
      .then((data) => {
        return data;
      });
  }

  getList(event: TableLazyLoadEvent) {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint, event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praktikumstypen konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getSingle(id: string) {
    return lastValueFrom(
      this.http
        .get<any>(this.extendedUserEndpoint + '/' + id)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praktikumstypen konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  delete(id: string) {
    return lastValueFrom(
      this.http
        .delete<any>(this.extendedUserEndpoint + '/delete?id=' + id)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praktikumstyp konnte nicht gelöscht werden.'
                  )
                )
            )
          )
        )
    );
  }

  saveInternshiptype(internshiptype: Internshiptype) {
    //console.log(JSON.stringify(internshiptype));
    return lastValueFrom(
      this.http
        .post<Internshiptype>(
          this.extendedUserEndpoint + '/new',
          JSON.stringify(internshiptype),
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praktikatyp konnten nicht gespeichert werden.'
                  )
                )
            )
          )
        )
    );
  }

  encodeParams(params: any): string {
    let body: string = '';

    for (let key in params) {
      if (body.length) {
        body += '&';
      }
      body += key + '=';
      body += encodeURIComponent(params[key]);
    }

    return body;
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  getInternshipTypesLookup() {
    return lastValueFrom(
      this.http
        .get<RadioGroupEntry[]>(
          this.extendedUserEndpoint + '/internshiptypeslookup'
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praktikumstypen Look-up Ids konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }
}
