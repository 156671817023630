<h1 mat-dialog-title>{{ data.message }}</h1>
<div mat-dialog-actions>
  <button
    type="button"
    pButton
    pRipple
    mat-raised-button
    class="btn float-left"
    (click)="onNoClick()"
  >
    Abbrechen
  </button>
  <button
    type="button"
    pButton
    pRipple
    class="btn btn-primary float-right"
    (click)="onConfirmClick()"
    cdkFocusInitial
  >
    Ok
  </button>
</div>
