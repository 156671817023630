<div class="row">
  <div class="col">
    <div>
      <h2 class="mb-3">
        <a
          routerLink="/internships"
          routerLinkActive="active"
          ngbTooltip="zur Übersicht"
          ><i class="pi pi-angle-left bluecolor"></i></a
        >&nbsp;Ausbildungsverhältnis<span *ngIf="!isUpdate"> erfassen</span>
      </h2>
      <div *ngIf="internship != null && isUpdate && deptFields.workflowstatus">
        Workflowstatus: {{ internship.workflowstateName }} &nbsp;
        <button
          *ngIf="
            internship.dataSourceObjectIdWorkflowState ==
            'DC6C7787-26C3-427C-AA92-C94329940F85'
          "
          class="btn btn-secondary"
          style="height: 38px"
          (click)="setPubliziert()"
        >
          Publizieren
        </button>
        <button
          *ngIf="
            internship.dataSourceObjectIdWorkflowState ==
            '02057AC0-2E4F-40BD-8C81-42B7D84EDC9A'
          "
          class="btn btn-secondary"
          style="height: 38px"
          (click)="setInPlanung()"
        >
          Zurück zu In Planung
        </button>
      </div>
      <form *ngIf="internship != null">
        <div class="form-group">
          <div class="row">
            <h4
              class="mb-2"
              [class.alert]="
                submitted &&
                !isUpdate &&
                (internship.studId === undefined || internship.studId === null)
              "
            >
              Studierende:r &nbsp;
              <span *ngIf="internship.student && !internship.student?.isActive"
                >[Inaktiv]</span
              >
              <button *ngIf="!isUpdate" class="btn" (click)="openStudents()">
                <i class="fas fa-edit bluecolor"></i>
              </button>
              <button
                *ngIf="isUpdate"
                class="btn"
                (click)="openStudentDetail()"
              >
                <i class="fa fa-arrow-up-right-from-square bluecolor"></i>
              </button>
              <span
                *ngIf="
                  submitted &&
                  !isUpdate &&
                  (internship.studId === undefined ||
                    internship.studId === null)
                "
                class="alert"
                >*</span
              >
            </h4>
            <div class="col-sm">
              <label for="studentFirstname">Vorname: </label>
              <input
                class="form-control"
                name="studentFirstname"
                [(ngModel)]="student.firstname"
                id="studentFirstname"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="studentLastname">Nachname: </label>
              <input
                class="form-control"
                name="studentLastname"
                [(ngModel)]="student.lastname"
                id="studentLastname"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label for="studentStreet">Strasse: </label>
              <input
                class="form-control"
                id="studentStreet"
                name="studentStreet"
                [(ngModel)]="student.street"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="studentStreetAddition">Adresszusatz: </label>
              <input
                class="form-control"
                name="studentStreetAddition"
                [(ngModel)]="student.addressAddon"
                id="studentStreetAddition"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label for="studentPLZ">PLZ: </label>
              <input
                class="form-control"
                [readonly]="true"
                name="studentPLZ"
                [(ngModel)]="student.plz"
                [disabled]="true"
                id="studentPLZ"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="studentPlace">Ort: </label>
              <input
                class="form-control"
                [readonly]="true"
                name="studentPlace"
                [(ngModel)]="student.city"
                [disabled]="true"
                id="studentPlace"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="studentCountry">Land: </label>
              <input
                class="form-control"
                [readonly]="true"
                name="studentCountry"
                [(ngModel)]="student.country"
                [disabled]="true"
                id="studentCountry"
                size="40"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label for="studentClass"> Studienjahrgang: </label>
              <input
                class="form-control"
                [readonly]="true"
                name="studentClass"
                [(ngModel)]="student.class"
                [disabled]="true"
                id="studentClass"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="studentEmail">E-Mail: </label
              ><a href="mailto:{{ student.emailText }}"
                ><input
                  class="form-control"
                  id="studentEmail"
                  name="studentEmail"
                  [(ngModel)]="student.emailText"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
              /></a>
            </div>
            <div class="col-sm">
              <label for="studentTelefon">Telefon: </label
              ><input
                class="form-control"
                id="studentTelefon"
                name="studentTelefon"
                [(ngModel)]="student.telefon"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>

          <div *ngIf="deptFields.studentSpezialfall" class="row">
            <div class="col-sm">
              <mat-checkbox
                id="specialCase"
                name="specialCase"
                [(ngModel)]="internship.specialCase"
                [disabled]="true"
                >Spezialfall</mat-checkbox
              >
            </div>
            <div class="col-sm">
              <label for="specialCaseNotes">Spezialfall Bemerkung: </label
              ><input
                class="form-control"
                id="specialCaseNotes"
                name="specialCaseNotes"
                [(ngModel)]="internship.specialCaseNotes"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <h4 class="mb-2">
                Praxisorganisation &nbsp;
                <span
                  *ngIf="
                    internship.practiceOrganization &&
                    !internship.practiceOrganization.isActive
                  "
                  >[Inaktiv]</span
                >
                <button class="btn" (click)="openPracticeorganisations()">
                  <i class="fas fa-edit bluecolor"></i>
                </button>
                <button
                  *ngIf="isUpdate"
                  class="btn"
                  ngbTooltip="Öffnen"
                  (click)="openPracticeorganisationDetail()"
                >
                  <i class="fa fa-arrow-up-right-from-square bluecolor"></i>
                </button>
              </h4>
              <div class="col-sm">
                <label for="organizationName">Organisation: </label>
                <input
                  class="form-control"
                  id="organizationName"
                  name="organizationName"
                  [(ngModel)]="practiceOrganization.organisationname"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="organizatioNamenAddition">Firmenname 2: </label>
                <input
                  class="form-control"
                  id="organizatioNamenAddition"
                  name="organizatioNamenAddition"
                  [(ngModel)]="practiceOrganization.addon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <label for="organizationStreet">Strasse: </label>
                <input
                  class="form-control"
                  id="organizationStreet"
                  name="organizationStreet"
                  [(ngModel)]="practiceOrganization.street"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="organizationAddition">Adresszusatz: </label>
                <input
                  class="form-control"
                  id="organizationAddition"
                  name="organizationAddition"
                  [(ngModel)]="practiceOrganization.addressAddon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <label for="organizationPLZ">PLZ: </label>
                <input
                  class="form-control"
                  [readonly]="true"
                  name="organizationPLZ"
                  [(ngModel)]="practiceOrganization.plz"
                  [disabled]="true"
                  id="organizationPLZ"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="organizationPlace">Ort: </label>
                <input
                  class="form-control"
                  [readonly]="true"
                  name="organizationPlace"
                  [(ngModel)]="practiceOrganization.city"
                  [disabled]="true"
                  id="organizationPlace"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="organizationCountry">Land: </label>
                <input
                  class="form-control"
                  [readonly]="true"
                  name="organizationCountry"
                  [(ngModel)]="practiceOrganization.country"
                  [disabled]="true"
                  id="organizationCountry"
                  size="40"
                />
              </div>
              <div *ngIf="deptFields.infoDirektanstellung" class="col-sm">
                <mat-checkbox
                  id="directEmployment"
                  name="directEmployment"
                  [(ngModel)]="internship.directEmployment"
                  >Direktanstellung</mat-checkbox
                >
              </div>
            </div>
            <!-- <div *ngIf="deptFields.orgChirAndCoRadioButtons" class="row">
              <div class="col-sm">
                <div class="row">
                  <mat-checkbox
                    id="chir"
                    name="chir"
                    [(ngModel)]="internship.chir"
                    >Chir</mat-checkbox
                  >
                </div>
                <div class="row">
                  <mat-checkbox id="iM" name="iM" [(ngModel)]="internship.im"
                    >IM</mat-checkbox
                  >
                </div>
                <div class="row">
                  <mat-checkbox id="rh" name="rh" [(ngModel)]="internship.rh"
                    >Rh</mat-checkbox
                  >
                </div>
                <div class="row">
                  <mat-checkbox
                    id="rhAmbi"
                    name="rhAmbi"
                    [(ngModel)]="internship.rhAmbi"
                    >RhAmbi</mat-checkbox
                  >
                </div>
                <div class="row">
                  <mat-checkbox id="n" name="n" [(ngModel)]="internship.n"
                    >N</mat-checkbox
                  >
                </div>
              </div>
              <div class="col-sm">
                <div class="row">
                  <mat-checkbox id="g" name="g" [(ngModel)]="internship.g"
                    >G</mat-checkbox
                  >
                </div>
                <div class="row">
                  <mat-checkbox id="gyn" name="gyn" [(ngModel)]="internship.gyn"
                    >Gyn</mat-checkbox
                  >
                </div>
                <div class="row">
                  <mat-checkbox id="uro" name="uro" [(ngModel)]="internship.uro"
                    >Uro</mat-checkbox
                  >
                </div>
                <div class="row">
                  <mat-checkbox id="iPS" name="iPS" [(ngModel)]="internship.ips"
                    >IPS</mat-checkbox
                  >
                </div>
              </div>
            </div> -->

            <div *ngIf="deptFields.fieldsS" class="col-sm">
              <label for="practiceOrganizationdateTrainingConcept"
                >Datum Ausbildungskonzept:
              </label>
              <input
                class="form-control"
                type="date"
                id="practiceOrganizationdateTrainingConcept"
                name="practiceOrganizationdateTrainingConcept"
                [(ngModel)]="practiceOrganization.dateTrainingConcept"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div *ngIf="deptFields.fieldsS" class="col-sm">
              <label for="practiceOrganizationtimeofRecognition"
                >Zeitpunkt Anerkennung:
              </label>
              <input
                class="form-control"
                type="date"
                id="practiceOrganizationtimeofRecognition"
                name="practiceOrganizationtimeofRecognition"
                [(ngModel)]="practiceOrganization.timeofRecognition"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>

            <div *ngIf="deptFields.subjectArea" class="row">
              <label for="subjectAreas">Fachbereiche: &nbsp; &nbsp;</label>
              <section>
                <div
                  *ngFor="let subjectArea of selectedSubjectAreas"
                  class="row"
                >
                  <mat-checkbox
                    [name]="subjectArea.subjectArea.name"
                    [(ngModel)]="subjectArea.selected"
                    [checked]="subjectArea.selected"
                  >
                    {{ subjectArea.subjectArea.name }}
                  </mat-checkbox>
                </div>
              </section>
            </div>

            <div *ngIf="deptFields.orgWochenbettabteilung" class="row">
              <div class="col-sm">
                <!--  <label for="TODO">Wochenbettabteilung: </label
                ><input
                  class="form-control"
                  id="TODO"
                  name="TODO"
                  [(ngModel)]="trainer.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />-->
              </div>
            </div>

            <div *ngIf="deptFields.orgAkutsomatikRadioButtons" class="row">
              <div class="col-sm">
                <!-- <mat-radio-group [(ngModel)]="this.student.gender">
                  <mat-radio-button
                    [disabled]="true"
                    [checked]="gender === this.student.gender"
                    *ngFor="let gender of genders"
                    [value]="gender"
                  >
                    TODO {{ gender }}&nbsp; &nbsp;
                  </mat-radio-button>
                </mat-radio-group> -->
              </div>
            </div>
          </div>

          <div
            *ngIf="deptFields.trainer || deptFields.trainer1and2"
            class="form-group"
          >
            <div class="row">
              <h4
                class="mb-2"
                [class.alert]="
                  submitted && !isUpdate && trainer.id === undefined
                "
              >
                Praxisausbildende:r &nbsp;
                <span *ngIf="internship.trainer && !internship.trainer.isActive"
                  >[Inaktiv]</span
                >
                <span *ngIf="deptFields.trainer1and2">I</span>&nbsp;
                <button class="btn" (click)="openTrainers()">
                  <i class="fas fa-edit bluecolor"></i>
                </button>
                <button
                  *ngIf="isUpdate"
                  class="btn"
                  ngbTooltip="Öffnen"
                  (click)="openTrainerDetail()"
                >
                  <i class="fa fa-arrow-up-right-from-square bluecolor"></i>
                </button>
                <span
                  *ngIf="submitted && !isUpdate && trainer.id === undefined"
                  class="alert"
                  >*</span
                >
              </h4>
              <div class="col-sm">
                <label for="trainerOneFirstName">Vorname: </label>
                <input
                  class="form-control"
                  id="trainerOneFirstName"
                  name="trainerOneFirstName"
                  [(ngModel)]="trainer.firstname"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="trainerOneLastName">Nachname: </label>
                <input
                  class="form-control"
                  id="trainerOneLastName"
                  name="trainerOneLastName"
                  [(ngModel)]="trainer.lastname"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="trainerOneEmailText">E-Mail: </label
                ><a href="mailto:{{ trainer.emailText }}"
                  ><input
                    class="form-control"
                    id="trainerOneEmailText"
                    name="trainerOneEmailText"
                    [(ngModel)]="trainer.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <label for="trainerOnetelefon">Telefon: </label
                ><input
                  class="form-control"
                  id="trainerOnetelefon"
                  name="trainerOnetelefon"
                  [(ngModel)]="trainer.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="trainerOnemobile">Mobile Telefon: </label
                ><input
                  class="form-control"
                  id="trainerOnemobile"
                  name="trainerOnemobile"
                  [(ngModel)]="trainer.mobile"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="trainerOneOrganisation">Organisation: </label
                ><input
                  class="form-control"
                  id="trainerOneOrganisation"
                  name="trainerOneOrganisation"
                  [(ngModel)]="trainer.organisation"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <label for="trainerOneStreet">Strasse: </label>
                <input
                  class="form-control"
                  id="trainerOneStreet"
                  name="trainerOneStreet"
                  [(ngModel)]="trainer.street"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="trainerOneStreetAddition">Adresszusatz: </label>
                <input
                  class="form-control"
                  id="trainerOneStreetAddition"
                  name="trainerOneStreetAddition"
                  [(ngModel)]="trainer.addressAddon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <label for="trainerOnePLZ">PLZ: </label>
                <input
                  class="form-control"
                  name="trainerOnePLZ"
                  [(ngModel)]="trainer.plz"
                  [readonly]="true"
                  [disabled]="true"
                  id="trainerOnePLZ"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="trainerOnePlace">Ort: </label>
                <input
                  class="form-control"
                  name="trainerOnePlace"
                  [(ngModel)]="trainer.city"
                  [readonly]="true"
                  [disabled]="true"
                  id="trainerOnePlace"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="trainerOneCountry">Land: </label>
                <input
                  class="form-control"
                  name="trainerOneCountry"
                  [(ngModel)]="trainer.country"
                  [readonly]="true"
                  [disabled]="true"
                  id="trainerOneCountry"
                  size="40"
                />
              </div>
            </div>

            <div *ngIf="deptFields.fieldsS" class="row">
              <div class="col-sm">
                <label for="trainerOnetimeofRecognition"
                  >Zeitpunkt Anerkennung:
                </label>
                <input
                  class="form-control"
                  type="date"
                  id="trainerOnetimeofRecognition"
                  name="trainerOnetimeofRecognition"
                  [(ngModel)]="trainer.timeofRecognition"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="trainerOneoneTimeRecognitionDate"
                  >Datum einmalige Anerkennung:
                </label>
                <input
                  class="form-control"
                  type="date"
                  id="trainerOneoneTimeRecognitionDate"
                  name="trainerOneoneTimeRecognitionDate"
                  [(ngModel)]="trainer.oneTimeRecognitionDate"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>
          </div>

          <div *ngIf="deptFields.trainer1and2" class="form-group">
            <div class="row">
              <h4
                class="mb-2"
                [class.alert]="
                  submitted && !isUpdate && trainerII.id === undefined
                "
              >
                Praxisausbildende:r II &nbsp; &nbsp;
                <span
                  *ngIf="internship.trainerII && !internship.trainerII.isActive"
                  >[Inaktiv]</span
                >
                <button class="btn" (click)="openTrainersII()">
                  <i class="fas fa-edit bluecolor"></i>
                </button>
                <button
                  *ngIf="isUpdate"
                  class="btn"
                  ngbTooltip="Öffnen"
                  (click)="openTrainerIIDetail()"
                >
                  <i class="fa fa-arrow-up-right-from-square bluecolor"></i>
                </button>
                <span
                  *ngIf="submitted && !isUpdate && trainerII.id === undefined"
                  class="alert"
                  >*</span
                >
              </h4>
              <div class="col-sm">
                <label for="trainerTwoFirstName">Vorname: </label>
                <input
                  class="form-control"
                  id="trainerTwoFirstName"
                  name="trainerTwoFirstName"
                  [(ngModel)]="trainerII.firstname"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="trainerTwoLastName">Nachname: </label>
                <input
                  class="form-control"
                  id="trainerTwoLastName"
                  name="trainerTwoLastName"
                  [(ngModel)]="trainerII.lastname"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <label for="trainerTwoEmail">E-Mail: </label
                ><a href="mailto:{{ trainerII.emailText }}"
                  ><input
                    class="form-control"
                    id="trainerTwoEmail"
                    name="trainerTwoEmail"
                    [(ngModel)]="trainerII.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
              <div class="col-sm">
                <label for="trainerTwoTelefon">Telefon: </label
                ><input
                  class="form-control"
                  id="trainerTwoTelefon"
                  name="trainerTwoTelefon"
                  [(ngModel)]="trainerII.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>
          </div>

          <div *ngIf="deptFields.studierendenbegleitende" class="form-group">
            <div class="row">
              <h4
                class="mb-2"
                [class.alert]="
                  submitted &&
                  !isUpdate &&
                  !deptFields.fieldsIPH &&
                  (internship.studbegId === undefined ||
                    internship.studbegId === '')
                "
              >
                <span *ngIf="!deptFields.trainer1and2"
                  >Studierendenbegleitende:r &nbsp;<span *ngIf="isIHG">
                    (Ausbildungsverantwortliche:r)</span
                  >
                </span>
                <span *ngIf="deptFields.trainer1and2">Mentor:in</span>
                <span *ngIf="internship.mentor && !internship.mentor.isActive"
                  >&nbsp;[Inaktiv]</span
                >
                <button class="btn" (click)="openMentors()">
                  <i class="fas fa-edit bluecolor"></i>
                </button>
                <button
                  *ngIf="isUpdate"
                  class="btn"
                  ngbTooltip="Öffnen"
                  (click)="openMentorDetail()"
                >
                  <i class="fa fa-arrow-up-right-from-square bluecolor"></i>
                </button>
                <span
                  *ngIf="
                    submitted &&
                    !isUpdate &&
                    !deptFields.fieldsIPH &&
                    (internship.studbegId === undefined ||
                      internship.studbegId === '')
                  "
                  class="alert"
                  >*</span
                >
              </h4>
              <div class="col-sm">
                <label for="mentorFirstName">Vorname: </label>
                <input
                  class="form-control"
                  id="mentorFirstName"
                  name="mentorFirstName"
                  [(ngModel)]="mentor.firstname"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="mentorLastName">Nachname: </label>
                <input
                  class="form-control"
                  id="mentorLastName"
                  name="mentorLastName"
                  [(ngModel)]="mentor.lastname"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <label for="mentorStreet">Strasse: </label>
                <input
                  class="form-control"
                  id="mentorStreet"
                  name="mentorStreet"
                  [(ngModel)]="mentor.street"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="mentorStreetAddition">Adresszusatz: </label>
                <input
                  class="form-control"
                  id="mentorStreetAddition"
                  name="mentorStreetAddition"
                  [(ngModel)]="mentor.addressAddon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <label for="mentorPLZ">PLZ: </label>
                <input
                  class="form-control"
                  name="mentorPLZ"
                  [(ngModel)]="mentor.plz"
                  [readonly]="true"
                  [disabled]="true"
                  id="mentorPLZ"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="mentorPlace">Ort: </label>
                <input
                  class="form-control"
                  name="mentorPlace"
                  [(ngModel)]="mentor.city"
                  [readonly]="true"
                  [disabled]="true"
                  id="mentorPlace"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="mentorCountry">Land: </label>
                <input
                  class="form-control"
                  name="mentorCountry"
                  [(ngModel)]="mentor.country"
                  [readonly]="true"
                  [disabled]="true"
                  id="mentorCountry"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm">
                <label for="mentoremailText">E-Mail: </label
                ><a href="mailto:{{ mentor.emailText }}"
                  ><input
                    class="form-control"
                    id="mentoremailText"
                    name="mentoremailText"
                    [(ngModel)]="mentor.emailText"
                    [readonly]="true"
                    [disabled]="true"
                    size="40"
                /></a>
              </div>
              <div class="col-sm">
                <label for="mentortelefon">Telefon: </label
                ><input
                  class="form-control"
                  id="mentortelefon"
                  name="mentortelefon"
                  [(ngModel)]="mentor.telefon"
                  [readonly]="true"
                  [disabled]="true"
                  size="40"
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <h4 class="mb-2">
                Zeitplanung<button
                  *ngIf="deptFields.zeitplanungButton"
                  ngbTooltip="Zeitplanung berechnen"
                  class="btn"
                  (click)="zeitplanungBerechnen()"
                >
                  <i class="fa-solid fa-gear bluecolor"></i>
                </button>
              </h4>

              <div class="col-sm">
                <label for="internshipType"
                  >Praktikumstyp:
                  <button class="btn" (click)="openInternshiptypes()">
                    <i class="fas fa-edit bluecolor"></i>
                  </button>
                  <button
                    *ngIf="isUpdate"
                    class="btn"
                    ngbTooltip="Öffnen"
                    (click)="openInternshiptypeDetail()"
                  >
                    <i class="fa fa-arrow-up-right-from-square bluecolor"></i>
                  </button>
                </label>
                <input
                  class="form-control"
                  name="internshipType"
                  [(ngModel)]="internship.type.name"
                  [readonly]="true"
                  [disabled]="true"
                  id="InternshipType"
                  size="30"
                  [ngClass]="{
                    'is-invalid':
                      submitted &&
                      !isUpdate &&
                      (internship.type.id === null ||
                        internship.type.id === undefined)
                  }"
                />
              </div>
              <div *ngIf="deptFields.infoPraktikumsperiode" class="col-sm">
                <label for="internshipperiodIdentifier"
                  >Praktikumsperiode:
                  <button class="btn" (click)="openInternshipperiods()">
                    <i class="fas fa-edit bluecolor"></i>
                  </button>
                  <button
                    *ngIf="isUpdate"
                    class="btn"
                    ngbTooltip="Öffnen"
                    (click)="openInternshipperiodDetail()"
                  >
                    <i class="fa fa-arrow-up-right-from-square bluecolor"></i>
                  </button>
                </label>
                <input
                  class="form-control"
                  name="internshipperiodIdentifier"
                  [(ngModel)]="internship.internshipPeriod.identifier"
                  [readonly]="true"
                  [disabled]="true"
                  id="internshipperiodIdentifier"
                  size="30"
                  [ngClass]="{
                    'is-invalid':
                      submitted &&
                      internshipperiodMandatory &&
                      (internship.internshipPeriod.id === undefined ||
                        internship.internshipPeriod.id === '')
                  }"
                />
              </div>
              <div
                *ngIf="deptFields.startUndEndeAusfuellen"
                class="col-sm"
                style="
                  justify-content: flex-start;
                  display: flex;
                  flex-direction: column-reverse;
                "
              >
                <button
                  class="btn btn-secondary"
                  style="height: 38px"
                  (click)="setStartEnd()"
                >
                  Start und Ende ausfüllen
                </button>
              </div>
              <div *ngIf="deptFields.selectedStudyState" class="col-sm">
                <label for="selectedStudyState" style="height: 40.25px"
                  >Studiumstatus:
                </label>
                <p-dropdown
                  #dd2
                  name="selectedStudyState"
                  filterBy="name"
                  scrollHeight="400px"
                  [style]="{ width: '100%' }"
                  [options]="studyStates"
                  [(ngModel)]="selectedStudyState"
                  [filter]="!true"
                  optionLabel="name"
                  [readonly]="false"
                  [editable]="!false"
                >
                  <ng-template let-item pTemplate="studyStates">
                    <span>{{ item.name }}</span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-sm">
                <label *ngIf="deptFields.infoVertragsbeginn" for="startdate"
                  >Vertragsbeginn:</label
                >
                <label
                  *ngIf="deptFields.infoPraktikumsBeginnEnde"
                  for="startdate"
                  >Praktikumsbeginn:</label
                >
                <p-calendar
                  name="startdate"
                  id="startdate"
                  [(ngModel)]="startDate"
                  class="form-control"
                  [showWeek]="true"
                ></p-calendar>
              </div>
              <div class="col-sm">
                <label *ngIf="deptFields.infoVertragsbeginn" for="enddate"
                  >Vertragsende:</label
                >
                <label *ngIf="deptFields.infoPraktikumsBeginnEnde" for="enddate"
                  >Praktikumsende:</label
                >
                <p-calendar
                  class="form-control"
                  name="enddate"
                  [(ngModel)]="endDate"
                  id="internshipend"
                  [showWeek]="true"
                ></p-calendar>
              </div>
              <div *ngIf="deptFields.infoAnzahlArbeitstage" class="col-sm">
                <label for="totalworkdays">Anzahl Arbeitstage (Soll):</label
                ><input
                  class="form-control"
                  name="workingDays"
                  type="number"
                  [(ngModel)]="internship.workingDays"
                  id="totalworkdays"
                  size="40"
                />
              </div>
              <div *ngIf="deptFields.infoAnzahlStunden" class="col-sm">
                <label for="workingHours">Anzahl Stunden:</label
                ><input
                  class="form-control"
                  name="workingHours"
                  type="number"
                  [(ngModel)]="internship.workingHours"
                  [readonly]="false"
                  [disabled]="false"
                  id="workingHours"
                  size="40"
                  (change)="checkWorkingHours()"
                />
              </div>
              <div *ngIf="deptFields.ausbildungsjahr" class="col-sm">
                <label for="trainingYear">Ausbildungsjahr:</label
                ><input
                  class="form-control"
                  type="number"
                  name="trainingYear"
                  [(ngModel)]="internship.trainingYear"
                  [readonly]="false"
                  [disabled]="false"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div *ngIf="deptFields.infoArbeitspensum" class="col-sm">
                <label for="workload">Arbeitspensum:</label>
                <input
                  class="form-control"
                  name="workload"
                  type="number"
                  [(ngModel)]="internship.workload"
                  [readonly]="false"
                  [disabled]="false"
                  id="workload"
                  size="40"
                />
              </div>
              <div *ngIf="deptFields.infoPlanungsjahr" class="col-sm">
                <label for="planningYear">Planungsjahr:</label>
                <input
                  class="form-control"
                  name="planningYear"
                  type="number"
                  [(ngModel)]="internship.planningYear"
                  id="planningYear"
                  size="40"
                />
              </div>
            </div>

            <div *ngIf="deptFields.infoVerhaltnisErfasst" class="row">
              <div *ngIf="deptFields.infoVerhaltnisErfasst" class="col-sm">
                <label for="internshipCreated">Verhältnis erfasst:</label>
                <input
                  class="form-control"
                  name="internshipCreated"
                  type="date"
                  [(ngModel)]="internship.internshipCreated"
                  id="internshipCreated"
                  size="40"
                />
              </div>
              <div *ngIf="deptFields.infoVertragVerkurzt" class="col-sm">
                <label for="contractShortened">Vertrag verkürzt:</label>
                <input
                  class="form-control"
                  name="contractShortened"
                  type="date"
                  [(ngModel)]="internship.contractShortened"
                  id="contractShortened"
                  size="40"
                />
              </div>
              <div *ngIf="deptFields.infoVertragVerlangert" class="col-sm">
                <label for="contractExtended">Vertrag verlängert:</label>
                <input
                  class="form-control"
                  name="contractExtended"
                  type="date"
                  [(ngModel)]="internship.contractExtended"
                  id="contractExtended"
                  size="40"
                />
              </div>
            </div>

            <div
              *ngIf="deptFields.infoAuflagen || deptFields.infoRechnungsbetrag"
              class="row"
            >
              <div *ngIf="deptFields.infoAuflagen" class="col-sm">
                <label for="conditionNotes">Auflagen:</label>
                <input
                  class="form-control"
                  name="conditionNotes"
                  [(ngModel)]="internship.conditionNotes"
                  id="conditionNotes"
                  size="40"
                />
              </div>
              <div *ngIf="deptFields.infoRechnungsbetrag" class="col-sm">
                <label for="invoiceAmount">Rechnungsbetrag:</label>
                <input
                  class="form-control"
                  name="invoiceAmount"
                  [(ngModel)]="internship.invoiceAmount"
                  id="invoiceAmount"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div *ngIf="deptFields.infoworkNote" class="col-sm">
                <label for="workNote">Bemerkungen: </label
                ><p-editor
                  [style]="{
                    height: '150px',
                    'border-radius': '0 0 0.375rem 0.375rem'
                  }"
                  name="workNote"
                  [(ngModel)]="internship.workNote"
                  id="workNote"
                ></p-editor>
              </div>
            </div>

            <div *ngIf="deptFields.zeitplanungEinschreibedatum" class="row">
              <div class="col-sm">
                <label for="registrationDate">Einschreibedatum:</label
                ><input
                  type="date"
                  class="form-control"
                  name="registrationDate"
                  [(ngModel)]="internship.registrationDate"
                  id="registrationDate"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="dateContractOk"
                  >Lehrauftrag Zielvereinbarung OK:</label
                ><input
                  type="date"
                  class="form-control"
                  name="dateContractOk"
                  [(ngModel)]="internship.dateContractOk"
                  id="dateContractOk"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="shouldDateContract"
                  >Abgabe Zielvereinbarung (Soll):</label
                ><input
                  type="date"
                  class="form-control"
                  name="shouldDateContract"
                  [(ngModel)]="internship.shouldDateContract"
                  id="shouldDateContract"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="isDateContract"
                  >Abgabe Zielvereinbarung (Ist):</label
                ><input
                  type="date"
                  class="form-control"
                  name="isDateContract"
                  [(ngModel)]="internship.isDateContract"
                  id="isDateContract"
                  size="40"
                />
              </div>
            </div>

            <div *ngIf="deptFields.zeitplanungEinschreibedatum" class="row">
              <div class="col-sm">
                <label for="assessmentSheetShippingShould"
                  >Versand Quali (Soll):</label
                ><input
                  type="date"
                  class="form-control"
                  name="assessmentSheetShippingShould"
                  [(ngModel)]="internship.assessmentSheetShippingShould"
                  id="assessmentSheetShippingShould"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="assessmentSheetShippingIs"
                  >Versand Quali (Ist):</label
                ><input
                  type="date"
                  class="form-control"
                  name="assessmentSheetShippingIs"
                  [(ngModel)]="internship.assessmentSheetShippingIs"
                  id="assessmentSheetShippingIs"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="assessmentSheetAssignmentOk"
                  >Lehrauftrag Quali OK:</label
                ><input
                  type="date"
                  class="form-control"
                  name="assessmentSheetAssignmentOk"
                  [(ngModel)]="internship.assessmentSheetAssignmentOk"
                  id="assessmentSheetAssignmentOk"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div *ngIf="deptFields.zeitplanungAbgabeQualiSoll" class="col-sm">
                <label for="shouldDateAssessmentForm"
                  >Abgabe Quali (Soll):</label
                ><input
                  type="date"
                  class="form-control"
                  name="shouldDateAssessmentForm"
                  [(ngModel)]="internship.shouldDateAssessmentForm"
                  id="shouldDateAssessmentForm"
                  size="40"
                />
              </div>
              <div *ngIf="deptFields.zeitplanungAbgabeQualiIst" class="col-sm">
                <label for="isDateAssessmentForm">Abgabe Quali (Ist):</label
                ><input
                  type="date"
                  class="form-control"
                  name="isDateAssessmentForm"
                  [(ngModel)]="internship.isDateAssessmentForm"
                  id="isDateAssessmentForm"
                  size="40"
                />
              </div>
              <div *ngIf="deptFields.zeitplanungAbgabeQualiOk" class="col-sm">
                <label for="assessmentSheetDeliveryOk">Abgabe Quali (Ok):</label
                ><input
                  type="date"
                  class="form-control"
                  name="assessmentSheetDeliveryOk"
                  [(ngModel)]="internship.assessmentSheetDeliveryOk"
                  id="assessmentSheetDeliveryOk"
                  size="40"
                />
              </div>
              <div *ngIf="deptFields.zeitplanungGutschein" class="col-sm">
                <label for="voucher">Gutschein:</label
                ><input
                  type="date"
                  class="form-control"
                  name="voucher"
                  [(ngModel)]="internship.voucherDate"
                  id="voucher"
                  size="40"
                />
              </div>
            </div>

            <div class="row">
              <div
                *ngIf="deptFields.zeitplanungErhaltBeurteilungsbogenSoll"
                class="col-sm"
              >
                <label for="assessmentFormReceiptShould"
                  >Erhalt Beurteilungsbogen (Soll):</label
                ><input
                  type="date"
                  class="form-control"
                  name="assessmentFormReceiptShould"
                  [(ngModel)]="internship.assessmentFormReceiptShould"
                  id="assessmentFormReceiptShould"
                  size="40"
                />
              </div>
              <div
                *ngIf="deptFields.zeitplanungErhaltBeurteilungsbogenIst"
                class="col-sm"
              >
                <label for="assessmentFormReceiptIs"
                  >Erhalt Beurteilungsbogen (Ist):</label
                ><input
                  type="date"
                  class="form-control"
                  name="assessmentFormReceiptIs"
                  [(ngModel)]="internship.assessmentFormReceiptIs"
                  id="assessmentFormReceiptIs"
                  size="40"
                  [readonly]="isAbgeschlossenOderArchiviert"
                  [disabled]="isAbgeschlossenOderArchiviert"
                />
              </div>
              <div *ngIf="deptFields.zeitplanungVertragSoll" class="col-sm">
                <label for="contractShould">Vertrag (Soll):</label
                ><input
                  type="date"
                  class="form-control"
                  name="contractShould"
                  [(ngModel)]="internship.contractShould"
                  id="contractShould"
                  size="40"
                />
              </div>
              <div *ngIf="deptFields.zeitplanungVertragIst" class="col-sm">
                <label for="contractIs">Vertrag (Ist):</label
                ><input
                  type="date"
                  class="form-control"
                  name="contractIs"
                  [(ngModel)]="internship.contractIs"
                  id="contractIs"
                  size="40"
                />
              </div>
            </div>

            <div
              *ngIf="deptFields.zeitplanungPraktikumsbestatigungSoll"
              class="row"
            >
              <div class="col-sm">
                <label for="internshipConfirmationAndCompulsoryAttendanceShould"
                  >Praktikumsbestätigung und Anwesenheitspflicht (Soll):</label
                ><input
                  type="date"
                  class="form-control"
                  name="internshipConfirmationAndCompulsoryAttendanceShould"
                  [(ngModel)]="
                    internship.internshipConfirmationAndCompulsoryAttendanceShould
                  "
                  id="internshipConfirmationAndCompulsoryAttendanceShould"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="internshipConfirmationAndCompulsoryAttendanceIs"
                  >Praktikumsbestätigung und Anwesenheitspflicht (Ist):</label
                ><input
                  type="date"
                  class="form-control"
                  name="internshipConfirmationAndCompulsoryAttendanceIs"
                  [(ngModel)]="
                    internship.internshipConfirmationAndCompulsoryAttendanceIs
                  "
                  id="internshipConfirmationAndCompulsoryAttendanceIs"
                  size="40"
                />
              </div>
            </div>

            <div
              *ngIf="deptFields.zeitplanungPraktikumsbestatigungSoll"
              class="row"
            >
              <div class="col-sm">
                <label for="shippmentConfirmation">Versand Bestätigung:</label
                ><input
                  type="date"
                  class="form-control"
                  name="shippmentConfirmation"
                  [(ngModel)]="internship.shippmentConfirmation"
                  id="shippmentConfirmation"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <mat-checkbox
                  name="documentsIncomplete"
                  [(ngModel)]="internship.documentsIncomplete"
                  id="documentsIncomplete"
                  >Unterlagen nicht vollständig</mat-checkbox
                >
              </div>
              <div class="col-sm">
                <label for="timePlanningNote">Bemerkungen:</label
                ><input
                  class="form-control"
                  name="timePlanningNote"
                  [(ngModel)]="internship.timePlanningNote"
                  id="timePlanningNote"
                  size="40"
                />
              </div>
            </div>
          </div>

          <div *ngIf="deptFields.fieldsIPH" class="row">
            <div class="col-sm">
              <label for="internshipConfirmationAndCompulsoryAttendanceShould"
                >Erhalt Bestätigung Arbeits- und Ausbildungsverhältnis
                (Soll)</label
              ><input
                type="date"
                class="form-control"
                name="internshipConfirmationAndCompulsoryAttendanceShould"
                [(ngModel)]="
                  internship.internshipConfirmationAndCompulsoryAttendanceShould
                "
                id="internshipConfirmationAndCompulsoryAttendanceShould"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="internshipConfirmationAndCompulsoryAttendanceIs"
                >Erhalt Bestätigung Arbeits- und Ausbildungsverhältnis
                (Ist)</label
              ><input
                type="date"
                class="form-control"
                name="internshipConfirmationAndCompulsoryAttendanceIs"
                [(ngModel)]="
                  internship.internshipConfirmationAndCompulsoryAttendanceIs
                "
                id="internshipConfirmationAndCompulsoryAttendanceIs"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="targetSettingMeetingProposalsReceiptShould"
                >Erhalt Vorschläge Zielvereinbarungsgespräch (Soll)</label
              ><input
                type="date"
                class="form-control"
                name="targetSettingMeetingProposalsReceiptShould"
                [(ngModel)]="
                  internship.targetSettingMeetingProposalsReceiptShould
                "
                id="targetSettingMeetingProposalsReceiptShould"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="targetSettingMeetingProposalsReceiptIs"
                >Erhalt Vorschläge Zielvereinbarungsgespräch (Ist)</label
              ><input
                type="date"
                class="form-control"
                name="targetSettingMeetingProposalsReceiptIs"
                [(ngModel)]="internship.targetSettingMeetingProposalsReceiptIs"
                id="targetSettingMeetingProposalsReceiptIs"
                size="40"
              />
            </div>
          </div>

          <div *ngIf="deptFields.fieldsIPH" class="row">
            <div class="col-sm">
              <label for="shouldDateContract"
                >Zielvereinbarungsgespräch (Soll)</label
              ><input
                type="date"
                class="form-control"
                name="shouldDateContract"
                [(ngModel)]="internship.shouldDateContract"
                id="shouldDateContract"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="isDateContract">Zielvereinbarungsgespräch (Ist)</label
              ><input
                type="date"
                class="form-control"
                name="isDateContract"
                [(ngModel)]="internship.isDateContract"
                id="isDateContract"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="targetAgreementConceptReceiptShould"
                >Erhalt Entwurf Zielvereinbarung (Soll)</label
              ><input
                type="date"
                class="form-control"
                name="targetAgreementConceptReceiptShould"
                [(ngModel)]="internship.targetAgreementConceptReceiptShould"
                id="targetAgreementConceptReceiptShould"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="targetAgreementConceptReceiptIs"
                >Erhalt Entwurf Zielvereinbarung (Ist)</label
              ><input
                type="date"
                class="form-control"
                name="targetAgreementConceptReceiptIs"
                [(ngModel)]="internship.targetAgreementConceptReceiptIs"
                id="targetAgreementConceptReceiptIs"
                size="40"
              />
            </div>
          </div>

          <div *ngIf="deptFields.fieldsIPH" class="row">
            <div class="col-sm">
              <label for="targetAgreementFinalReceiptShould"
                >Erhalt definitive Zielvereinbarung (Soll)</label
              ><input
                type="date"
                class="form-control"
                name="targetAgreementFinalReceiptShould"
                [(ngModel)]="internship.targetAgreementFinalReceiptShould"
                id="targetAgreementFinalReceiptShould"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="targetAgreementFinalReceiptIs"
                >Erhalt definitive Zielvereinbarung (Ist)</label
              ><input
                type="date"
                class="form-control"
                name="targetAgreementFinalReceiptIs"
                [(ngModel)]="internship.targetAgreementFinalReceiptIs"
                id="targetAgreementFinalReceiptIs"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="shouldDateAssessmentForm">Erhalt Quali (Soll)</label
              ><input
                type="date"
                class="form-control"
                name="shouldDateAssessmentForm"
                [(ngModel)]="internship.shouldDateAssessmentForm"
                id="isDateAssessmentForm"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="isDateAssessmentForm">Erhalt Quali (Ist)</label
              ><input
                type="date"
                class="form-control"
                name="isDateAssessmentForm"
                [(ngModel)]="internship.isDateAssessmentForm"
                id="isDateAssessmentForm"
                size="40"
              />
            </div>
          </div>

          <div *ngIf="deptFields.bewertungUploads" class="form-group row">
            <h4 class="mb-2">Bewertung</h4>
            <div class="col-sm custom-file">
              <label class="custom-file-label">Zielsetzung: &nbsp;&nbsp;</label>
              <label for="headOfGoalSettingDocument" class="btn btn-secondary"
                >Datei wählen</label
              >
              <input
                style="display: none"
                type="file"
                class="custom-file-input"
                id="headOfGoalSettingDocument"
                name="headOfGoalSettingDocument"
                (change)="
                  onChangeDocument($event, this.internship.goalSettingDocument)
                "
                #headOfGoalSettingDocument="ngModel"
                ngModel
                [required]="isUpdate"
              />
              <label class="custom-file-label"
                >&nbsp;&nbsp;{{
                  getHeadOfGoalSettingDocumentLabel()
                }}&nbsp;&nbsp;</label
              >
              <button
                *ngIf="internship.goalSettingDocument.headOfFilename"
                (click)="onDownloadPublishedGoalSetting()"
                class="btn btn-secondary"
              >
                Herunterladen
              </button>
            </div>
            <div class="col-sm custom-file">
              <label class="custom-file-label">Bewertung: &nbsp;&nbsp;</label>
              <label for="evaluationDocument" class="btn btn-secondary"
                >Datei wählen</label
              >
              <input
                style="display: none"
                type="file"
                class="custom-file-input"
                id="evaluationDocument"
                name="evaluationDocument"
                (change)="
                  onChangeDocument($event, this.internship.evaluationDocument)
                "
                #headOfEvaluationDocument="ngModel"
                ngModel
                [required]="isUpdate"
              />
              <label class="custom-file-label"
                >&nbsp;&nbsp;{{
                  getEvaluationDocumentLabel()
                }}&nbsp;&nbsp;</label
              >
              <button
                *ngIf="internship.evaluationDocument?.headOfFilename"
                (click)="onDownloadPublishedEvaluation()"
                class="btn btn-secondary"
              >
                Herunterladen
              </button>
            </div>
          </div>

          <div *ngIf="deptFields.cancelledInterrupted" class="row">
            <div class="col-sm">
              <mat-checkbox
                id="cancelled"
                name="cancelled"
                [(ngModel)]="internship.cancelled"
                [disabled]="false"
                >Abgebrochen</mat-checkbox
              >
            </div>
            <div class="col-sm">
              <mat-checkbox
                id="interrupted"
                name="interrupted"
                [(ngModel)]="internship.interrupted"
                [disabled]="false"
                >Unterbrochen</mat-checkbox
              >
            </div>
          </div>

          <div class="form-group">
            <div
              *ngIf="
                internship.type?.gradingScale?.dataSourceObjectId ==
                  'CDA876CB-1DF1-455D-AFBD-4B5FAB55C4B6' ||
                internship.type?.gradingScale?.dataSourceObjectId ==
                  'CFB7CC88-67D8-4C6C-AE19-B44201994852' ||
                internship.type?.gradingScale?.dataSourceObjectId ==
                  '75A1302F-701E-4414-B496-E72856A2E2D6'
              "
              class="row"
            >
              <div *ngIf="deptFields.bewertungNote" class="col-sm">
                <label *ngIf="isDiskreditierungRight" for="grade">Note:</label>
                <label *ngIf="!isDiskreditierungRight" for="grade"
                  >Note:&nbsp;<span style="color: red"
                    >Nicht passender Schritt zwischen Noten</span
                  ></label
                >
                <input
                  class="form-control"
                  name="grade"
                  [(ngModel)]="internship.grade"
                  id="grade"
                  size="40"
                  (change)="setGradeDateAndDiscretization()"
                />
              </div>
              <div *ngIf="deptFields.bewertungDiskretisierung" class="col-sm">
                <label for="grade">Schritt zwischen Noten:</label
                ><input
                  class="form-control"
                  name="diskreditierung"
                  [value]="getDiscretization()"
                  id="diskreditierung"
                  size="40"
                  readonly="true"
                  disabled="true"
                />
              </div>
              <div *ngIf="deptFields.bewertunggradeDate" class="col-sm">
                <label for="gradeDate">Note gesetzt:</label
                ><input
                  class="form-control"
                  type="date"
                  name="gradeDate"
                  [(ngModel)]="internship.gradeDate"
                  id="gradeDate"
                  disabled="true"
                />
              </div>
              <div *ngIf="deptFields.bewerunggradeFixed" class="col-sm">
                <mat-checkbox
                  id="gradeFixed"
                  name="gradeFixed"
                  [(ngModel)]="internship.gradeFixed"
                  >Note fix</mat-checkbox
                >
              </div>
            </div>

            <div
              *ngIf="
                internship.type?.gradingScale?.dataSourceObjectId ===
                  dataSourceObjectIdErfuellungsstatus &&
                deptFields.bewertungbestandenRadioButtons
              "
              class="row"
            >
              <mat-radio-group
                name="passedStates"
                [(ngModel)]="internship.passedId"
              >
                <div class="col-sm">
                  <mat-radio-button
                    [checked]="
                      passedState.dataSourceObjectId === internship.passedId
                    "
                    *ngFor="let passedState of passedStates"
                    [value]="passedState.dataSourceObjectId"
                  >
                    {{ passedState.name }}&nbsp; &nbsp;
                  </mat-radio-button>
                </div>
              </mat-radio-group>
            </div>

            <div
              *ngIf="
                internship.type?.gradingScale?.dataSourceObjectId ===
                  dataSourceObjectIdZMCNotenskala &&
                deptFields.bewertungbestandenRadioButtons
              "
              class="row"
            >
              <mat-radio-group
                name="zmcNotenskala"
                [(ngModel)]="internship.zmcGradeId"
              >
                <div class="col-sm">
                  <mat-radio-button
                    [checked]="
                      passedState.dataSourceObjectId === internship.zmcGradeId
                    "
                    *ngFor="let passedState of zmcNotenskala"
                    [value]="passedState.dataSourceObjectId"
                  >
                    {{ passedState.name }}&nbsp; &nbsp;
                  </mat-radio-button>
                </div>
              </mat-radio-group>
            </div>

            <div *ngIf="deptFields.bewertungAbsenzen" class="row">
              <div class="col-sm">
                <label for="absences">Absenzen (in Tagen):</label
                ><input
                  class="form-control"
                  name="absences"
                  [(ngModel)]="internship.absences"
                  id="absences"
                  size="40"
                />
              </div>
            </div>

            <div *ngIf="deptFields.bewertungAnzahlStundenEffektiv" class="row">
              <div class="col-sm">
                <label for="netWorkingHours">Anzahl Stunden effektiv:</label
                ><input
                  class="form-control"
                  name="netWorkingHours"
                  [(ngModel)]="internship.netWorkingHours"
                  id="netWorkingHours"
                  size="40"
                  [readonly]="isAbgeschlossenOderArchiviert"
                  [disabled]="isAbgeschlossenOderArchiviert"
                />
              </div>
            </div>

            <div
              *ngIf="deptFields.bewertungFormativeEndbeurteilung"
              class="row"
            >
              <div class="col-sm">
                <label for="formativeFinalAssessment"
                  >Formative Endbeurteilung:</label
                ><input
                  type="date"
                  class="form-control"
                  name="formativeFinalAssessment"
                  [(ngModel)]="internship.formativeFinalAssessment"
                  id="formativeFinalAssessment"
                  size="40"
                />
              </div>
              <div class="col-sm">
                <label for="comment">Bemerkungen:</label
                ><input
                  class="form-control"
                  name="comment"
                  [(ngModel)]="internship.comment"
                  id="comment"
                  size="40"
                />
              </div>
            </div>

            <div *ngIf="deptFields.bewertungRegistered" class="row">
              <div class="col-sm">
                <label for="registered">Eingetragen (mit Datum):</label
                ><input
                  type="date"
                  class="form-control"
                  name="registered"
                  [(ngModel)]="internship.registered"
                  id="registered"
                  size="40"
                />
              </div>
            </div>
          </div>

          <button
            *ngIf="canWriteBehaviorSubject | async"
            class="btn btn-primary float-right"
            (click)="onSubmit()"
          >
            Speichern
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-processing
  [shown]="
    loading ||
    loadingInternship ||
    loadingStudent ||
    loadingPassedStates ||
    loadingStudyStates ||
    loadingZmcGrades
  "
></app-processing>
