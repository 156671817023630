<div class="row">
  <div class="col">
    <div>
      <h2 class="mb-3">
        <a
          routerLink="/trainers"
          routerLinkActive="active"
          ngbTooltip="zur Übersicht"
          ><i class="pi pi-angle-left bluecolor"></i></a
        >&nbsp;Praxisausbildende:r&nbsp;
        <span *ngIf="trainer && !trainer.isActive">[Inaktiv]</span>
      </h2>
      <div *ngIf="trainer" class="form-group">
        <div class="row">
          <div class="col-sm">
            <label for="Prename">Vorname: </label>
            <input
              class="form-control"
              id="firstname"
              name="firstname"
              [(ngModel)]="trainer.firstname"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="Surname">Nachname: </label>
            <input
              class="form-control"
              id="lastname"
              name="lastname"
              [(ngModel)]="trainer.lastname"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="organizationd">Organisation: </label>
            <input
              class="form-control"
              id="organisationname"
              name="organisationname"
              [(ngModel)]="trainer.organisation"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-sm">
            <label for="Email">Email: </label>
            <a href="mailto:{{ trainer.emailText }}"
              ><input
                class="form-control"
                id="emailText"
                name="emailText"
                [(ngModel)]="trainer.emailText"
                [readonly]="true"
                [disabled]="true"
                size="40"
            /></a>
          </div>
          <div class="col-sm">
            <label for="Phone">Telefon: </label>
            <input
              class="form-control"
              id="telefon"
              name="telefon"
              [(ngModel)]="trainer.telefon"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="Mobile">Mobile: </label>
            <input
              class="form-control"
              id="mobile"
              name="mobile"
              [(ngModel)]="trainer.mobile"
              [readonly]="true"
              [disabled]="true"
              size="40"
            />
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <h4 class="mb-2">Adresse</h4>
            <div class="col-sm">
              <label for="Street">Strasse: </label>
              <input
                class="form-control"
                id="street"
                name="street"
                [(ngModel)]="trainer.street"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="addressAddon">Adresszusatz: </label>
              <input
                class="form-control"
                id="addressAddon"
                name="addressAddon"
                [(ngModel)]="trainer.addressAddon"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm">
              <label for="plz">PLZ: </label>
              <input
                class="form-control"
                id="plz"
                name="plz"
                [(ngModel)]="trainer.plz"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="City">Ort: </label>
              <input
                class="form-control"
                id="city"
                name="city"
                [(ngModel)]="trainer.city"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
            <div class="col-sm">
              <label for="Country">Land: </label>
              <input
                class="form-control"
                id="country"
                name="country"
                [(ngModel)]="trainer.country"
                [readonly]="true"
                [disabled]="true"
                size="40"
              />
            </div>
          </div>
        </div>

        <div *ngIf="deptFields.WbmBesucht" class="row">
          <div class="col-sm">
            <mat-checkbox
              id="wBMVisited"
              name="wBMVisited"
              [(ngModel)]="trainer.wbmVisited"
              >WBM besucht</mat-checkbox
            >
          </div>
        </div>

        <div class="row">
          <div *ngIf="deptFields.recognitionStates" class="col-sm">
            <label>Anerkennungsstatus: </label>
            <div>
              <mat-radio-group
                class="vertical-radio-group"
                [(ngModel)]="selectedRecognitionState"
              >
                <mat-radio-button
                  *ngFor="let recognitionState of recognitionStates"
                  [checked]="recognitionState.id === selectedRecognitionState"
                  [value]="recognitionState.id"
                >
                  {{ recognitionState.name }}&nbsp;&nbsp;
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div *ngIf="deptFields.sozialeArbeitFields" class="col-sm">
            <div class="row">
              <label for="timeofRecognition">Zeitpunkt Anerkennung: </label>
              <input
                class="form-control"
                type="date"
                id="timeofRecognition"
                name="timeofRecognition"
                [(ngModel)]="trainer.timeofRecognition"
                [readonly]="false"
                [disabled]="false"
                size="40"
              />
            </div>
            <div class="row">
              <label for="oneTimeRecognitionDate"
                >Datum einmalige Anerkennung:
              </label>
              <input
                class="form-control"
                type="date"
                id="oneTimeRecognitionDate"
                name="oneTimeRecognitionDate"
                [(ngModel)]="trainer.oneTimeRecognitionDate"
                [readonly]="false"
                [disabled]="false"
                size="40"
              />
            </div>
          </div>
          <div *ngIf="deptFields.sozialeArbeitFields" class="col-sm">
            <label for="equivalentrecognitioninfo"
              >Äquivalente Anerkennung Info:
            </label>
            <textarea
              rows="4"
              class="form-control"
              cols="43"
              id="equivalentRecognitionInfo"
              name="equivalentRecognitionInfo"
              [(ngModel)]="trainer.equivalentRecognitionInfo"
              [readonly]="false"
              [disabled]="false"
            ></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-sm">
            <label for="comment">Bemerkungen: </label>
            <p-editor
              [style]="{
                height: '150px',
                'border-radius': '0 0 0.375rem 0.375rem'
              }"
              id="remarks"
              name="remarks"
              [(ngModel)]="trainer.remarks"
              [readonly]="false"
              [disabled]="false"
            ></p-editor>
          </div>
          <div *ngIf="deptFields.physioFields" class="col-sm">
            <div class="row">
              <mat-checkbox id="bsc" name="bsc" [(ngModel)]="trainer.bsc"
                >Bsc</mat-checkbox
              >
            </div>
            <div class="row">
              <mat-checkbox id="zMC" name="zMC" [(ngModel)]="trainer.zmc"
                >ZMC</mat-checkbox
              >
            </div>
            <div class="row">
              <mat-checkbox
                id="hospitation"
                name="hospitation"
                [(ngModel)]="trainer.hospitation"
                >Hospitation</mat-checkbox
              >
            </div>
          </div>
        </div>

        <div *ngIf="deptFields.physioFields" class="row">
          <div class="col-sm">
            <label for="launchDay">Einführungstag: </label>
            <input
              class="form-control"
              id="launchDay"
              name="launchDay"
              type="date"
              [(ngModel)]="trainer.launchDay"
              [readonly]="false"
              [disabled]="false"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="wBKPracticalTraining">WBK Praxisausbildung 1: </label>
            <input
              class="form-control"
              id="wBKPracticalTraining"
              name="wBKPracticalTraining"
              type="date"
              [(ngModel)]="trainer.wbkPracticalTraining"
              [readonly]="false"
              [disabled]="false"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="trainingHealthcareSector"
              >CAS Ausbilden in Gesundheitsberufen:
            </label>
            <input
              class="form-control"
              id="trainingHealthcareSector"
              name="trainingHealthcareSector"
              type="date"
              [(ngModel)]="trainer.trainingHealthcareSector"
              [readonly]="false"
              [disabled]="false"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="otherTraining">andere Ausbildung: </label>
            <input
              class="form-control"
              id="otherTraining"
              name="otherTraining"
              type="date"
              [(ngModel)]="trainer.otherTraining"
              [readonly]="false"
              [disabled]="false"
              size="40"
            />
          </div>
          <div class="col-sm">
            <label for="equivalentrecognitioninfoPhysio"
              >andere Ausbildung: Bemerkung
            </label>
            <textarea
              rows="2"
              class="form-control"
              cols="43"
              id="equivalentrecognitioninfoPhysio"
              name="equivalentrecognitioninfoPhysio"
              [(ngModel)]="trainer.equivalentRecognitionInfo"
              [readonly]="false"
              [disabled]="false"
            ></textarea>
          </div>
        </div>

        <button class="btn btn-primary float-right" (click)="onSubmit()">
          Speichern
        </button>
      </div>
    </div>
  </div>

  <app-processing [shown]="loading"></app-processing>
</div>
