<div class="row">
  <div *ngIf="visibleForRefresh" class="col">
    <h2 class="mb-3">Ausbildungsverhältnisse</h2>

    <div
      *ngIf="deptFields.sozialeArbeit"
      class="row"
      style="padding-bottom: 10px"
    >
      <div class="col">
        <button
          type="button"
          (click)="setStudyStateFilter('Aktiv')"
          ngbTooltip="Studiumstatus auf aktiv setzen."
          pButton
          pRipple
          class="btn btn-primary float-left"
          tooltipPosition="bottom"
          style="background-color: #429b41"
        >
          &nbsp;&nbsp;aktiv&nbsp;
        </button>
        <button
          type="button"
          (click)="setStudyStateFilter('Diplomiert')"
          ngbTooltip="Studiumstatus auf diplomiert setzen."
          pButton
          pRipple
          class="btn btn-primary float-left"
          tooltipPosition="bottom"
          style="background-color: #458cca"
        >
          &nbsp;&nbsp;diplomiert&nbsp;
        </button>
        <button
          type="button"
          (click)="setStudyStateFilter('Unterbrochen')"
          ngbTooltip="Studiumstatus auf unterbrochen setzen."
          pButton
          pRipple
          class="btn btn-primary float-left"
          tooltipPosition="bottom"
          style="background-color: #5abfdd"
        >
          &nbsp;&nbsp;unterbrochen&nbsp;
        </button>
        <button
          type="button"
          (click)="setStudyStateFilter('Abgebrochen')"
          ngbTooltip="Studiumstatus auf abgebrochen setzen."
          pButton
          pRipple
          class="btn btn-primary float-left"
          tooltipPosition="bottom"
          style="background-color: #efac4e"
        >
          &nbsp;&nbsp;abgebrochen&nbsp;
        </button>
        <button
          type="button"
          (click)="setStudyStateFilter('Exmatrikuliert')"
          ngbTooltip="Studiumstatus auf exmatrikuliert setzen."
          pButton
          pRipple
          class="btn btn-primary float-left"
          tooltipPosition="bottom"
          style="background-color: #d8524e"
        >
          &nbsp;&nbsp;exmatrikuliert&nbsp;
        </button>
      </div>
    </div>

    <div *ngIf="!isForSelection" class="row" style="padding-bottom: 10px">
      <div class="col">
        <label *ngIf="deptFields.inkusivArchivierte" for="showArchive"
          >inklusiv Archivierte anzeigen &nbsp;</label
        >
        <mat-checkbox
          *ngIf="deptFields.inkusivArchivierte"
          id="showArchive"
          name="showArchive"
          [(ngModel)]="this.showArchive"
          (change)="checkShowArchive()"
        ></mat-checkbox>

        <button
          type="button"
          (click)="downloadInternships()"
          ngbTooltip="Datensätze können für den Export ausgewählt werden."
          pButton
          pRipple
          icon="pi pi-download"
          class="btn btn-primary float-right"
          tooltipPosition="bottom"
        >
          &nbsp;&nbsp;Exportieren&nbsp;
        </button>

        <button
          type="button"
          [routerLink]="['/internship-detail']"
          ngbTooltip="Neues Ausbildungsverhältnis erstellen."
          pButton
          pRipple
          icon="pi pi-plus-circle mr-2"
          class="btn btn-primary float-right"
          tooltipPosition="bottom"
        >
          &nbsp;&nbsp;Neues Ausbildungsverhältnis&nbsp;
        </button>

        <button
          type="button"
          ngbTooltip="Berichte und Funktionen"
          pButton
          pRipple
          icon="pi pi-ellipsis-v"
          class="btn btn-primary float-right"
          tooltipPosition="bottom"
          [matMenuTriggerFor]="menu"
        ></button>

        <mat-menu #menu="matMenu">
          <button
            (click)="setZielgespraecheOk()"
            mat-menu-item
            *ngIf="deptFields.setZielgespraecheOk"
          >
            <span>Lehrauftrag Zielvereinbarung OK setzen</span>
          </button>

          <button
            mat-menu-item
            *ngIf="deptFields.filterZeitplanungAbgabeQualiSoll"
          >
            <mat-checkbox
              id="abgabeQualiAusstehend"
              name="abgabeQualiAusstehend"
              [(ngModel)]="this.abgabeQualiAusstehend"
              (change)="filterAbgabeQualiAusstehend()"
              >Abgabe Quali ausstehend</mat-checkbox
            >
          </button>

          <button (click)="getPersonendatenblatt()" mat-menu-item>
            <span>Reporte Personendatenblatt</span>
          </button>

          <button
            *ngIf="deptFields.sozialeArbeit"
            (click)="getEinschreibungPraktikum()"
            mat-menu-item
          >
            <span>Reporte Einschreibung Praktikum</span>
          </button>

          <button
            *ngIf="deptFields.getVorlageArbeitsundAusbildungsverhaeltnis"
            (click)="getVorlageArbeitsundAusbildungsverhaeltnis()"
            mat-menu-item
          >
            <span>Vorlage Arbeits- und Ausbildungsverhältnis</span>
          </button>

          <button
            *ngIf="deptFields.publizierenPlanung"
            (click)="publizieren()"
            mat-menu-item
          >
            <span>Publizieren</span>
          </button>

          <button
            *ngIf="deptFields.publizierenPlanung"
            (click)="inPlanungSetzten()"
            mat-menu-item
          >
            <span>In Planung setzten</span>
          </button>

          <button *ngIf="deptFields.sozialeArbeitButtons" mat-menu-item>
            <mat-checkbox
              id="mAiAVersandLAQG1vornehmen"
              name="mAiAVersandLAQG1vornehmen"
              [(ngModel)]="this.mAiAVersandLAQG1vornehmen"
              (change)="filterMAiAVersandLAQG1vornehmen()"
              >MAiA Versand/LA QG1 vornehmen</mat-checkbox
            >
          </button>

          <button *ngIf="deptFields.sozialeArbeitButtons" mat-menu-item>
            <mat-checkbox
              id="mAiAVersandLAQG2vornehmen"
              name="mAiAVersandLAQG2vornehmen"
              [(ngModel)]="this.mAiAVersandLAQG2vornehmen"
              (change)="filterMAiAVersandLAQG2vornehmen()"
              >MAiA Versand/LA QG2 vornehmen</mat-checkbox
            >
          </button>

          <button *ngIf="deptFields.sozialeArbeitButtons" mat-menu-item>
            <span></span>
            <mat-checkbox
              id="offeneLehrauftraegeZG1"
              name="offeneLehrauftraegeZG1"
              [(ngModel)]="this.offeneLehrauftraegeZG1"
              (change)="filterOffeneLehrauftraegeZG1()"
              >Offene Lehraufträge ZG1</mat-checkbox
            >
          </button>

          <button *ngIf="deptFields.sozialeArbeitButtons" mat-menu-item>
            <mat-checkbox
              id="offeneLehrauftraegeZG2"
              name="offeneLehrauftraegeZG2"
              [(ngModel)]="this.offeneLehrauftraegeZG2"
              (change)="filterOffeneLehrauftraegeZG2()"
              >Offene Lehraufträge ZG2</mat-checkbox
            >
          </button>

          <button *ngIf="deptFields.sozialeArbeitButtons" mat-menu-item>
            <mat-checkbox
              id="praktikumVersandLAQG1vornehmen"
              name="praktikumVersandLAQG1vornehmen"
              [(ngModel)]="this.praktikumVersandLAQG1vornehmen"
              (change)="filterPraktikumVersandLAQG1vornehmen()"
              >Praktikum Versand/LA QG1 vornehmen</mat-checkbox
            >
          </button>

          <button *ngIf="deptFields.sozialeArbeitButtons" mat-menu-item>
            <mat-checkbox
              id="praktikumVersandLAQG2vornehmen"
              name="praktikumVersandLAQG2vornehmen"
              [(ngModel)]="this.praktikumVersandLAQG2vornehmen"
              (change)="filterPraktikumVersandLAQG2vornehmen()"
              >Praktikum Versand/LA QG2vornehmen</mat-checkbox
            >
          </button>
        </mat-menu>
      </div>
    </div>

    <p-table
      #dt
      [lazy]="true"
      (onLazyLoad)="loadInternships($event)"
      [totalRecords]="totalRecords"
      [value]="internships"
      [paginator]="true"
      [rows]="rowsSelectionInternships$ | async"
      [value]="internships"
      sortMode="single"
      sortField="startdate"
      [sortOrder]="-1"
      [rowsPerPageOptions]="[10, 50, 100, 200, 500, 1000]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      selectionMode="multiple"
      [(selection)]="selectedInternships"
      [selectionPageOnly]="true"
      [scrollable]="true"
      (onFilter)="filter($event)"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="min-width: 30px; padding-left: 8px">
            <mat-checkbox
              *ngIf="!isForSelection"
              name="selectAll"
              id="selectAll"
              [(ngModel)]="this.selectAllChecked"
              (change)="selectAll()"
              ngbTooltip="alles markieren"
            ></mat-checkbox>
          </th>
          <th *ngIf="deptFields.studentClass" style="min-width: 220px">
            <div pSortableColumn="studentClass">
              Studienjahrgang
              <p-sortIcon field="studentClass"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="studentClass"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="studentNameComp">
              Studierende:r
              <p-sortIcon field="studentNameComp"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="studentNameComp"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th *ngIf="deptFields.sozialeArbeit" style="min-width: 220px">
            <div pSortableColumn="selectedStudyState">
              Studiumstatus
              <p-sortIcon field="selectedStudyState"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="selectedStudyState"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th *ngIf="deptFields.mentorNameComp" style="min-width: 240px">
            <div pSortableColumn="mentorNameComp">
              Studierendenbegleitende:r
              <p-sortIcon field="mentorNameComp"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="mentorNameComp"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th *ngIf="deptFields.trainerNameComp" style="min-width: 220px">
            <div pSortableColumn="trainerNameComp">
              Praxisausbildende:r
              <p-sortIcon field="trainerNameComp"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="trainerNameComp"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 250px">
            <div pSortableColumn="organizationNameComp">
              Praxisorganisation
              <p-sortIcon field="organizationNameComp"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="organizationNameComp"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 160px">
            <div pSortableColumn="internshipType">
              Praktikumstyp
              <p-sortIcon field="internshipType"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="internshipType"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th *ngIf="deptFields.subjectAreaName" style="min-width: 160px">
            <div>
              <!-- pSortableColumn="subjectAreasNameComp"> -->
              Fachbereiche
              <!-- <p-sortIcon field="subjectAreasNameComp"></p-sortIcon> -->
            </div>
            <!-- <p-columnFilter
              type="text"
              field="subjectAreasNameComp"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter> -->
          </th>
          <th *ngIf="deptFields.registrationDate" style="min-width: 180px">
            <div pSortableColumn="registrationDate">
              Einschreibedatum
              <p-sortIcon field="registrationDate"></p-sortIcon>
            </div>
            <p-columnFilter
              type="date"
              field="registrationDate"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th *ngIf="deptFields.workload" style="min-width: 180px">
            <div pSortableColumn="workload">
              Arbeitspensum
              <p-sortIcon field="workload"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="workload"
              matchMode="equals"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 130px">
            <div pSortableColumn="startdate">
              Startdatum
              <p-sortIcon field="startdate"></p-sortIcon>
            </div>
            <p-columnFilter
              type="date"
              field="startdate"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 130px">
            <div pSortableColumn="enddate">
              Enddatum
              <p-sortIcon field="enddate"></p-sortIcon>
            </div>
            <p-columnFilter
              type="date"
              field="enddate"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 60px" alignFrozen="right" pFrozenColumn>
            &nbsp;
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-internship let-rowIndex="rowIndex">
        <tr [pSelectableRow]="internship" [pSelectableRowIndex]="rowIndex">
          <td style="min-width: 30px">
            <p-tableCheckbox
              [value]="internship"
              #checkboxTableP
              [index]="rowIndex"
              (click)="checkboxTable(checkboxTableP, $event)"
            ></p-tableCheckbox>
          </td>
          <td *ngIf="deptFields.studentClass" style="min-width: 220px">
            {{ internship.studentClass }}
          </td>
          <td style="min-width: 220px">
            <a [routerLink]="['/student-detail', internship.studId]">{{
              internship.studentNameComp
            }}</a>
          </td>
          <td *ngIf="deptFields.sozialeArbeit" style="min-width: 220px">
            {{ internship.selectedStudyStateText }}
          </td>
          <td *ngIf="deptFields.mentorNameComp" style="min-width: 240px">
            <a [routerLink]="['/mentor-detail', internship.studbegId]">{{
              internship.mentorNameComp
            }}</a>
          </td>
          <td *ngIf="deptFields.trainerNameComp" style="min-width: 220px">
            <a [routerLink]="['/trainer-detail', internship.praxisTrainerId]">{{
              internship.trainerNameComp
            }}</a>
          </td>
          <td style="min-width: 250px">
            <a [routerLink]="['/practiceorganization', internship.orgId]">{{
              internship.organizationNameComp
            }}</a>
          </td>
          <td style="min-width: 160px">
            <a
              [routerLink]="[
                '/internshiptype-detail',
                internship.internshipTypeId
              ]"
              >{{ internship.internshipType }}</a
            >
          </td>
          <td *ngIf="deptFields.subjectAreaName" style="min-width: 160px">
            {{ internship.subjectAreasNameComp }}
          </td>
          <td *ngIf="deptFields.registrationDate" style="min-width: 160px">
            {{ internship.registrationDate }}
          </td>
          <td *ngIf="deptFields.workload" style="min-width: 160px">
            {{ internship.workload }}
          </td>
          <td style="min-width: 130px">{{ internship.startdate }}</td>
          <td style="min-width: 130px">{{ internship.enddate }}</td>
          <td
            style="min-width: 60px"
            class="functionicons"
            alignFrozen="right"
            pFrozenColumn
          >
            <div *ngIf="isForSelection">
              <button
                class="btn"
                (click)="selectInternship(internship)"
                ngbTooltip="Auswählen"
              >
                <i class="fas fa-solid fa-plus bluecolor"></i>
              </button>
            </div>
            <div *ngIf="!isForSelection">
              <a
                [routerLink]="['/internship-detail', internship.id]"
                ngbTooltip="Bearbeiten"
                ><i class="fas fa-edit bluecolor"></i
              ></a>
              &nbsp;
              <a
                *ngIf="deptFields.inkusivArchivierte"
                (click)="archive(internship.id)"
                ngbTooltip="Archivieren"
                ><i class="fas fa-trash bluecolor"></i
              ></a>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
