import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { getErrorMessage } from '../shared-layout/shared.tools';
import { lastValueFrom, throwError } from 'rxjs';

import { Trainer } from './model/trainer';
import { GridOptions } from './model/grid-options';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable({
  providedIn: 'root',
})
export class TrainerService {
  readonly extendedUserEndpoint: string = `${environment.apiUrl}/api/pt/trainer`;
  gridOptions: GridOptions;

  constructor(private http: HttpClient) {
    this.gridOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }

  getDummyData() {
    return lastValueFrom(this.http.get<any>('../../assets/trainers.json'))
      .then((res) => <Trainer[]>res.data)
      .then((data) => {
        return data;
      });
  }

  getList(event: TableLazyLoadEvent) {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint, event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisausbildende konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getListWithInactive(event: TableLazyLoadEvent) {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint + '/publishedwithinactive', event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisausbildende konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getListforExport(event: TableLazyLoadEvent) {
    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint + '/publishedforexport', event)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisausbildende konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  getSingle(id: string) {
    return lastValueFrom(
      this.http
        .get<any>(this.extendedUserEndpoint + '/trainer?id=' + id)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisausbildende:r konnte nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }

  save(trainer: Trainer) {
    return lastValueFrom(
      this.http
        .post<Trainer>(
          this.extendedUserEndpoint + '/new',
          JSON.stringify(trainer),
          this.gridOptions
        )
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisausbildende:r konnte nicht gespeichert werden.'
                  )
                )
            )
          )
        )
    );
  }

  getRecognitionStates() {
    return lastValueFrom(
      this.http
        .get<any>(this.extendedUserEndpoint + '/recognitionstates')
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Anerkennungsstati konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }
}
