import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { setInternshiptypesRows } from '../../../state/rows-selection.actions';
import { InternshiptypesService } from '../../../service/internshiptypes.service';
import {
  Internshiptype,
  Internshiptype as Internshiptypes,
} from '../../../service/model/internshiptype';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { InfoService } from '../../../service/info.service';
import { UtilsService } from '../../../service/utils.service';
import { DeptFieldsInternshiptypes } from '../internshiptype-fields';
import { DatePipe } from '@angular/common';
import { TableLazyLoadEvent } from 'primeng/table';

@Component({
  selector: 'app-internshiptypes',
  templateUrl: '../internshiptypes/internshiptypes.component.html',
  styleUrls: ['../internshiptypes/internshiptypes.component.css'],
  providers: [InternshiptypesService],
})
export class InternshiptypeSelectDialogComponent implements OnInit {
  internshiptypes: Internshiptypes[];
  totalRecords: number;
  loading: boolean;
  isForSelection = true;
  rowsSelectionInternshiptypes$: Observable<number>;
  deptFields: DeptFieldsInternshiptypes;
  departmentId: BehaviorSubject<string>;

  constructor(
    private internshiptypesService: InternshiptypesService,
    private dialogRef: MatDialogRef<InternshiptypeSelectDialogComponent>,
    private store: Store<{ rowsSelectionInternshiptypes: number }>,
    private dialog: MatDialog,
    private infoService: InfoService,
    private utilsService: UtilsService,
    public datepipe: DatePipe
  ) {
    this.rowsSelectionInternshiptypes$ = store.select(
      'rowsSelectionInternshiptypes'
    );
    //console.log(this.rowsSelectionInternshiptypes$);
  }

  ngOnInit(): void {
    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(
        data,
        'internshiptypes'
      );
      // Nur für Testing alle Felder anzeigen
      // this.deptFields = deptFieldsInternshiptypeShowAll;
    });
  }

  filter(event: TableLazyLoadEvent) {}

  loadInternshiptypes(event: TableLazyLoadEvent) {
    this.loading = true;

    //console.log(event.rows)
    this.store.dispatch(setInternshiptypesRows({ rows: event.rows }));
    this.rowsSelectionInternshiptypes$ = this.store.select(
      'rowsSelectionInternshiptypes'
    );

    //console.log(event);
    setTimeout(() => {
      this.internshiptypesService
        .getList(event)
        .then((data) => {
          this.internshiptypes = data['records'];
          this.totalRecords = data['count'];

          this.internshiptypes = this.internshiptypes.map((internshiptype) => {
            const nextenddate = this.datepipe.transform(
              internshiptype.nextenddate,
              'dd.MM.yyyy'
            );
            const nextstartdate = this.datepipe.transform(
              internshiptype.nextstartdate,
              'dd.MM.yyyy'
            );
            return {
              ...internshiptype,
              nextenddate: nextenddate,
              nextstartdate: nextstartdate,
            };
          });

          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message: 'Die Praktikumstypen konnten nicht geladen werden.',
            },
          });
        });
    }, 1000);
  }

  selectInternshiptype(internshiptype: Internshiptype) {
    this.dialogRef.close(internshiptype);
  }

  delete(id: number) {}
}
