<div class="row">
  <div class="col">
    <h2 class="mb-3">Praktikumstypen</h2>

    <div *ngIf="!isForSelection" class="row" style="padding-bottom: 10px">
      <div class="col">
        <button
          type="button"
          [routerLink]="['/internshiptype-detail']"
          ngbTooltip="Neuen Praktikumstyp erstellen."
          pButton
          pRipple
          icon="pi pi-plus-circle mr-2"
          class="btn btn-primary float-right"
          tooltipPosition="bottom"
        >
          &nbsp;&nbsp;Neuer Praktikumstyp&nbsp;
        </button>
      </div>
    </div>

    <p-table
      styleClass="p-datatable-gridlines"
      [lazy]="true"
      (onLazyLoad)="loadInternshiptypes($event)"
      [totalRecords]="totalRecords"
      [value]="internshiptypes"
      sortMode="single"
      sortField="name"
      [sortOrder]="1"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [paginator]="true"
      [rows]="rowsSelectionInternshiptypes$ | async"
      [rowsPerPageOptions]="[10, 50, 100, 200, 500, 1000]"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 220px">
            <div pSortableColumn="name">
              Name
              <p-sortIcon field="name"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="name"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th *ngIf="deptFields.startcalendarweek" style="min-width: 220px">
            <div pSortableColumn="startcalendarweek">
              Start in KW
              <p-sortIcon field="startcalendarweek"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="startcalendarweek"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th *ngIf="deptFields.nextstartdate" style="min-width: 220px">
            <div pSortableColumn="nextstartdate">
              Startdatum
              <p-sortIcon field="nextstartdate"></p-sortIcon>
            </div>
            <p-columnFilter
              type="date"
              field="nextstartdate"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th *ngIf="deptFields.nextenddate" style="min-width: 220px">
            <div pSortableColumn="nextenddate">
              Enddatum
              <p-sortIcon field="nextenddate"></p-sortIcon>
            </div>
            <p-columnFilter
              type="date"
              field="nextenddate"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th
            *ngIf="deptFields.deadlineForSubmissionOfTargetInterviewInWeeks"
            style="min-width: 220px"
          >
            <div
              pSortableColumn="deadlineForSubmissionOfTargetInterviewInWeeks"
            >
              Frist für Abgabe Zielvereinbarung in Wochen
              <p-sortIcon
                field="deadlineForSubmissionOfTargetInterviewInWeeks"
              ></p-sortIcon>
            </div>
            <p-columnFilter
              type="date"
              field="deadlineForSubmissionOfTargetInterviewInWeeks"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 60px" alignFrozen="right" pFrozenColumn>
            &nbsp;
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-internshiptype>
        <tr>
          <td style="min-width: 220px">{{ internshiptype.name }}</td>
          <td *ngIf="deptFields.startcalendarweek" style="min-width: 220px">
            {{ internshiptype.startcalendarweek }}
          </td>
          <td *ngIf="deptFields.nextstartdate" style="min-width: 220px">
            {{ internshiptype.nextstartdate }}
          </td>
          <td *ngIf="deptFields.nextenddate" style="min-width: 220px">
            {{ internshiptype.nextenddate }}
          </td>
          <td
            *ngIf="deptFields.deadlineForSubmissionOfTargetInterviewInWeeks"
            style="min-width: 220px"
          >
            {{ internshiptype.deadlineForSubmissionOfTargetInterviewInWeeks }}
          </td>
          <td
            class="functionicons"
            style="min-width: 60px"
            alignFrozen="right"
            pFrozenColumn
          >
            <div *ngIf="isForSelection">
              <button
                class="btn"
                (click)="selectInternshiptype(internshiptype)"
                ngbTooltip="Auswählen"
              >
                <i class="fas fa-solid fa-plus bluecolor"></i>
              </button>
            </div>
            <div *ngIf="!isForSelection">
              <a
                [routerLink]="['/internshiptype-detail', internshiptype.id]"
                ngbTooltip="Bearbeiten"
                style="padding-right: 8px"
                ><i class="fas fa-edit bluecolor"></i
              ></a>
              <a (click)="delete(internshiptype.id)" ngbTooltip="Löschen"
                ><i class="fas fa-trash bluecolor"></i
              ></a>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-processing [shown]="loading"></app-processing>
