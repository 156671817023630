import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentService } from '../../../service/student.service';
import { Student } from '../../../service/model/student';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { setStudentsRows } from '../../../state/rows-selection.actions';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeptFieldsStudents } from '../student-fields';
import { UtilsService } from '../../../service/utils.service';
import { Table, TableCheckbox, TableLazyLoadEvent } from 'primeng/table';
import { ReportService } from '../../../service/report.service';
import { InfoService } from '../../../service/info.service';
import { downloadFile } from '../../../shared-layout/shared.tools';
import { ConfirmDialogComponent } from '../../../shared-layout/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css'],
  providers: [StudentService],
})
export class StudentsComponent implements OnInit {
  students: Student[];
  selectedStudents: Student[];
  loading: boolean;
  totalRecords: number;
  isForSelection = false;
  rowsSelectionStudents$: Observable<number>;
  deptFields: DeptFieldsStudents;
  departmentId: BehaviorSubject<string>;
  @ViewChild('dt') table: Table;
  selectAllChecked = false;
  cols: any[];
  filename = 'Studierende';
  visibleForRefresh: boolean = true;
  showInactive = false;

  constructor(
    private studentService: StudentService,
    private utilsService: UtilsService,
    private store: Store<{
      rowsSelectionStudents: number;
      dataSourceObjectId: string;
    }>,
    private dialog: MatDialog,
    private reportService: ReportService,
    private infoService: InfoService
  ) {
    this.rowsSelectionStudents$ = store.select('rowsSelectionStudents');
    //console.log(this.rowsSelectionStudents$);
  }

  ngOnInit() {
    this.loading = true;

    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(data, 'students');
      // Nur für Testing alle Felder anzeigen
      //this.deptFields = deptFieldsStudentsShowAll;
    });

    //cols for export
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'addressText', header: 'Adresse' },
      { field: 'cityText', header: 'Stadt' },
      { field: 'class', header: ' Studienjahrgang' },
      { field: 'emailText', header: 'E-Mail' },
      { field: 'telefon', header: ' Telefon' },
      { field: 'id', header: 'Id' },
    ];
  }

  filter(event: TableLazyLoadEvent) {
    //If search critera changes undo selection
    this.undoSelection();
  }

  loadStudents(event: TableLazyLoadEvent) {
    this.loading = true;
    //console.log(event);
    //console.log(event.rows)
    this.store.dispatch(setStudentsRows({ rows: event.rows }));
    this.rowsSelectionStudents$ = this.store.select('rowsSelectionStudents');

    setTimeout(() => {
      if (this.showInactive) {
        this.studentService
          .getListWithInactive(event)
          .then((data) => {
            if (data) {
              this.students = data['records'];
              this.totalRecords = data['count'];
            }
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Studierende konnten nicht geladen werden.',
              },
            });
          });
      } else {
        this.studentService
          .getList(event)
          .then((data) => {
            if (data) {
              this.students = data['records'];
              this.totalRecords = data['count'];
            }
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message: 'Studierende konnten nicht geladen werden.',
              },
            });
          });
      }
    }, 1000);
  }

  selectStudent(student: Student) {}

  checkboxTable(tableCheckBox: TableCheckbox, event: any): void {
    //console.log(event);
    const isChecked = !!event.target.ariaChecked;
    if (event.button === 0 && event.shiftKey) {
      if (isChecked) {
        this.table.selectRange(event, tableCheckBox.index);
      } else {
        this.table.rangeRowIndex = tableCheckBox.index;
        this.table.clearSelectionRange(event);
        this.table.tableService.onSelectionChange();
      }
    }
    this.table.anchorRowIndex = tableCheckBox.index;
  }

  selectAll() {
    if (!this.selectAllChecked) {
      this.selectedStudents = [];
    } else {
      if (this.totalRecords > 1500) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            message:
              'Sollen alle Studierenden ausgewählt werden? Das kann eventuell mehrere Minuten dauern.',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.selectAllRequest();
          } else {
            this.selectAllChecked = false;
          }
        });
      } else {
        this.selectAllRequest();
      }
    }
  }

  selectAllRequest(): void {
    this.loading = true;
    const event = this.table.createLazyLoadMetadata() as TableLazyLoadEvent;
    this.studentService
      .getListforExport(event)
      .then((data) => {
        this.selectedStudents = data['records'];
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Die Ausbildungsverhältnisse für den Export konnten nicht geladen werden.',
          },
        });
      });
  }

  downloadExport(): void {
    downloadFile(this.selectedStudents, this.cols, this.filename);
  }

  undoSelection(): void {
    this.selectAllChecked = false;
    this.selectedStudents = [];
  }

  checkShowInactive() {
    this.selectAllChecked = false;
    this.selectAll();
    this.visibleForRefresh = false;
    setTimeout(() => (this.visibleForRefresh = true), 0);
  }
}
