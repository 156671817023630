export class DeptFieldsInternshiptypes {
  startcalendarweek: boolean;
  nextstartdate: boolean;
  nextenddate: boolean;
  deadlineForSubmissionOfTargetInterviewInWeeks: boolean;
}

export const deptFieldsInternshiptypesS: DeptFieldsInternshiptypes = {
  startcalendarweek: false,
  nextstartdate: false,
  nextenddate: false,
  deadlineForSubmissionOfTargetInterviewInWeeks: true,
};

export const deptFieldsInternshiptypesDefault: DeptFieldsInternshiptypes = {
  startcalendarweek: true,
  nextstartdate: true,
  nextenddate: true,
  deadlineForSubmissionOfTargetInterviewInWeeks: false,
};

export class DeptFieldsInternshiptypeDetail {
  startcalendarweek: boolean; // Studiengang und Klasse dasselbe?
  durationInWeeks: boolean;
  deadlineForAssessmentFormDeliveryShouldInWeeks: boolean;
  workload: boolean;
  ausbildungsjahr: boolean;
}

export const deptFieldsInternshiptypeDetailS: DeptFieldsInternshiptypeDetail = {
  startcalendarweek: false,
  durationInWeeks: false,
  deadlineForAssessmentFormDeliveryShouldInWeeks: false,
  workload: false,
  ausbildungsjahr: false,
};

export const deptFieldsInternshiptypeDetailIPH: DeptFieldsInternshiptypeDetail =
  {
    startcalendarweek: false,
    durationInWeeks: false,
    deadlineForAssessmentFormDeliveryShouldInWeeks: true,
    workload: true,
    ausbildungsjahr: false,
  };

export const deptFieldsInternshiptypeDetailIPT: DeptFieldsInternshiptypeDetail =
  {
    startcalendarweek: true,
    durationInWeeks: true,
    deadlineForAssessmentFormDeliveryShouldInWeeks: true,
    workload: true,
    ausbildungsjahr: true,
  };

export const deptFieldsInternshiptypeDetailIHB: DeptFieldsInternshiptypeDetail =
  {
    startcalendarweek: true,
    durationInWeeks: true,
    deadlineForAssessmentFormDeliveryShouldInWeeks: true,
    workload: true,
    ausbildungsjahr: true,
  };

export const deptFieldsInternshiptypeDetailDefault: DeptFieldsInternshiptypeDetail =
  {
    startcalendarweek: true,
    durationInWeeks: true,
    deadlineForAssessmentFormDeliveryShouldInWeeks: true,
    workload: true,
    ausbildungsjahr: false,
  };

export const deptFieldsInternshiptypeDetailShowAll: DeptFieldsInternshiptypeDetail =
  {
    startcalendarweek: true,
    durationInWeeks: true,
    deadlineForAssessmentFormDeliveryShouldInWeeks: true,
    workload: true,
    ausbildungsjahr: true,
  };
